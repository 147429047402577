import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";

import {productSearch, setProducts, setSearchProductDialog} from "../../../store/draftOrderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import {ModalFooter} from "react-bootstrap";
import {openNewDialog} from "../../../../manage-product/store/prodcutSlice";
import PreLoader from "../../../../../share-components/ui/preLoader";
import moment from "moment";
import {getInventoryByLocation} from "../../../../manage-product/store/inventorySlice";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import SearchProductTable from "./searchProductTable";
import {dataGroupBy} from "../../../../../utils/common";

const defaultValues = {
    products: [{
        variants: [
            {}
        ]
    }]
};

const schema = yup.object().shape({
    // products: yup.array().of(
    //
    // )
});

const SearchProductModal = (props) => {

    const {
        orderSetValue,
        orderTrigger,
        orderGetValues,
        imageSelectOnClick,
        isAllowOutOfStock = false,
        onChangeDialog = null,
        onAddProduct
    } = props;
    const [timer, setTimer] = useState(null);
    const dispatch = useDispatch();


    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {fields: productFields, append: productAppend, remove: productRemove} = useFieldArray({
        name: 'products',
        control
    })

    const {errors, isDirty} = formState;
    const orderState = useSelector(({order}) => order.draftOrder);
    let variantRef = useRef([]);
    let infiniteScrollRef = useRef();
    const [productData, setProductData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");

    const [onAdd, setOnAdd] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);


    const productSearchFunction = (isConcat, page) => {
        const parentValue = orderState.searchProductDialogProps.data.searchValue;
        if (!searchValue) {
            setSearchValue(orderState.searchProductDialogProps.data.searchValue);
        }
        if (searchValue || (!parentValue)) {
            dispatch(productSearch({name: searchValue, page: page})).then(res => {
                if (!res.error) {
                    dispatch(setProducts(res.payload))
                    if (isConcat) {
                        setProductData(prevArray => [...prevArray, ...res.payload.data])
                    } else {
                        setProductData(res.payload.data);
                    }
                }

            });
        }
    }
    useEffect(() => {
        if (orderState.searchProductDialogProps.props.isOpen) {
            if (infiniteScrollRef.current) {
                infiniteScrollRef.current.actionTriggered = false;

            }
            const orderProducts = dataGroupBy(orderGetValues('ordered_products'), 'product_id');
            const productData = [];
            for (const key in orderProducts) {
                const variantData = [];
                orderProducts[key].map(function (item, key) {
                    variantData.push({
                        id: item.variant,
                    });
                });
                productData.push({
                    'id': orderProducts[key][0].product_id,
                    'variants': variantData
                })
            }
            reset({products: productData});

            productSearchFunction(false, 1);
        }
    }, [orderState.searchProductDialogProps.props]);
    useEffect(() => {
        if (orderState.searchProductDialogProps.props.isOpen) {
            if (infiniteScrollRef.current) {
                infiniteScrollRef.current.actionTriggered = false;

            }
            productSearchFunction(false, 1);
        }
    }, [searchValue]);
    useEffect(() => {
        if (infiniteScrollRef.current) {
            // infiniteScrollRef.current.lastScrollTop=0;
        }

        if (orderState.searchProductDialogProps.props.isOpen) {
            productSearchFunction(true, currentPage);
        }
    }, [currentPage]);


    const closDialog = () => {
        setSearchValue("");
        setProductData([]);
        dispatch(setSearchProductDialog({props: {isOpen: false}}))
        setSelectedProducts([]);
    }


    const productObjectUpdate = (orderProducts, isCheck, product, variant, inventories) => {
        const orderedProductIndex = orderProducts.findIndex(x => x.variant === variant.variant_id);

        const isVat = orderGetValues('customer') ? orderGetValues('customer').is_vat : 0;
        const taxAmount = isVat == 1 ? variant.price * 0.2 : 0;

        if (orderedProductIndex != '-1') {
            if (!isCheck) {
                orderProducts.splice(orderedProductIndex, 1);
            }
        } else {
            const exchangeRate = orderGetValues('currency_rate') ? Number(orderGetValues('currency_rate')) : 1;
            const variantInventory = inventories.filter(function (v, i) {
                return v.inventory_variant_id == variant.inventory_variant_id;
            });
            orderProducts.push({
                    product_id: product.id,
                    commodity: product.commodity,
                    variant_id: variant.id,
                    variant: variant.variant_id,
                    product_name: product.name,
                    image: product.url,
                    original_price: variant.price,
                    price: variant.price * exchangeRate,
                    sku: variant.sku,
                    is_tax: isVat,
                    variant_name: variant.variant_name,
                    quantity: !variant.is_out_of_stock ? 1 : 0,
                    total_quantity: 1,
                    total_price:  variant.price * exchangeRate ,
                    finished_quantity: 0,
                    stock: !variant.is_out_of_stock ? variant.current_stock : 0,
                    back_order_quantity: variant.is_out_of_stock ? 1 : 0,
                    tax_amount:  taxAmount * exchangeRate,
                    gross_amount: (taxAmount + variant.price) * exchangeRate ,
                    inventories: variantInventory,
                    inventory_location: variantInventory.length > 0 ? [{...variantInventory[0], quantity: 1}] : [],
                    weight: product.weight ? product.weight : 0,
                    unit_weight: product.weight ? product.weight : 0,
                }
            )
        }
        orderSetValue('ordered_products',orderProducts);
        return orderProducts;
    }

    const fetchProductData = () => {
        setCurrentPage(orderState.products.pagination.next_page);
    }


    const findProductIndex = (product) => {
        const findIndex = getValues('products').findIndex(item => item.id === product.id);
        return findIndex;
    }
    const onProductSubmit = (data) => {
        if (onChangeDialog) {
            onChangeDialog(true);
        }
        closDialog();
        const orderProduct = [];
        const inventoryVariantIds = [];
        let orderProducts = orderGetValues('ordered_products');
        const existVariantIds=[];
        (data.products).map(function (item, index) {
            if (item.name) {
                orderProduct.push({...item, variants: (item.variants).filter((v) => v.sku)});
                }
                (item.variants).map(function (variantItem, variantIndex) {
                    if (item.name && variantItem.sku) {
                        inventoryVariantIds.push(variantItem.inventory_variant_id);
                    }
                    existVariantIds.push(variantItem.id);
                });

        });
       const removeIndexes=[];

        orderProducts.map(function (item,index){
            if(!existVariantIds.includes(item.variant)){
              removeIndexes.push(index);
            }
        });
        removeIndexes.sort((a, b) => b - a);
        removeIndexes.forEach(index => {
            orderProducts.splice(index, 1);
        });
        orderSetValue('ordered_products',orderProducts);
        dispatch(getInventoryByLocation({inventory_variant_id: inventoryVariantIds})).then((res) => {
            const inventories = res.payload;
           orderProduct.map((product, productIndex) => {
                (product.variants).map((variant, variantIndex) => {
                    productObjectUpdate(orderProducts, true, product, variant, inventories);
                });
            });

            closDialog();
        });
    }
    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...orderState.searchProductDialogProps.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        All Products
                    </ModalHeader>
                    <Form noValidate="" onSubmit={handleSubmit(onProductSubmit)}>
                        <ModalBody>
                            <div className="form-row mb-3">
                                <Col md="12 input-with-icon pr-1">
                                    <div>
                                        <FormGroup className="m-0">
                                            <i className="fa fa-search"></i>
                                            <input name="code" type="text" placeholder="Search Products"
                                                   defaultValue={searchValue}
                                                   onChange={(e) => {
                                                       clearTimeout(timer);

                                                       const newTimer = setTimeout(() => {
                                                           setSearchValue(e.target.value ? e.target.value : "");
                                                       }, 500);

                                                       setTimer(newTimer);

                                                   }}
                                                   className={`form-control`}/>


                                        </FormGroup>
                                    </div>
                                </Col>
                            </div>
                            <div className="form-row mb-3">

                                <InfiniteScroll
                                    ref={(scroll) => {
                                        infiniteScrollRef.current = scroll;
                                    }}
                                    dataLength={productData.length}
                                    next={fetchProductData}
                                    hasMore={orderState.products.pagination.has_more}
                                    refreshFunction={() => {
                                        alert("Test")
                                    }}
                                    pullDownToRefresh={true}
                                    height={600}
                                    loader={<p>Loading...</p>}
                                >
                                    <table className="table" style={{width: '100%', height: 400, overflowY: 'scroll'}}>

                                        <tbody>

                                        {productData.map((item, i) =>
                                            <SearchProductTable
                                                item={item}
                                                key={i}
                                                getValues={getValues}
                                                setValue={setValue}
                                                productAppend={productAppend}
                                                productFields={productFields}
                                                productRemove={productRemove}
                                                control={control}
                                                trigger={trigger}
                                                selectedIndex={findProductIndex(item)}
                                                index={i}
                                            />
                                        )}


                                        </tbody>


                                    </table>
                                </InfiniteScroll>

                            </div>

                        </ModalBody>
                        <ModalFooter>

                            <Row>
                                <Col sm="12">
                                    <div className='float-sm-right '>

                                        <button onClick={handleSubmit(onProductSubmit)}
                                                className="btn  btn-primary"> Add
                                        </button>
                                    </div>
                                </Col>
                            </Row>


                        </ModalFooter>
                    </Form>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.isProductValueChange) == JSON.stringify(nextProps.isProductValueChange);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(SearchProductModal, areEqual);