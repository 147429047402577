import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import salesOrderReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import ReturnInvoice from "./invoice";

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Sales Order Return",
  },
];

const ReurnForm = (props) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Breadcrumb title="Order return" data={dataMenu} />
      <Container fluid={true} className="product-wrapper">
        <ReturnInvoice></ReturnInvoice>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ salesOrder: salesOrderReducer }])(ReurnForm);
