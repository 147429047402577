import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Nav, Row } from "reactstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import productReducer from "../../store";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { serviceItemResponseToTable } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  addServiceItem,
  deleteServiceItem,
  getAllServiceItems,
  updateServiceItem,
  openEditDialog,
  openNewDialog,
  closeEditDialog,
  selectServiceItem,
} from "../../store/serviceItemSlice";
import ServiceItemForm from "./serviceItemForm";

const defaultValues = {
  id: "",
  name: "",
};

const dataMenu = [
  {
    type: 0,
    text: "Products",
  },
  {
    type: 1,
    text: "Product Setting",
    link: "",
  },
  {
    type: 2,
    text: "Service Item",
    link: "",
  },
];

const schema = yup.object().shape({
  name: yup.string().required("You must enter a service item name"),
});

const ServiceItem = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState([]);
  const [loading, setLoading] = useState(true);
  const productTypes = useSelector(selectServiceItem);
  const [buttonDisable, setButtonDisable] = useState(false);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

  useEffect(() => {
    const data = {
      name,
    };
    dispatch(getAllServiceItems({ ...data }, "season")).then((res) => {
      if (!res.error) setLoading(false);
    });
  }, [dispatch, name]);

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmitForm = (dialog, data) => {
    setButtonDisable(true);
    if (dialog.type === "new") {
      dispatch(addServiceItem({ ...data })).then((res) => {
        setButtonDisable(false);
        console.log(res);
        if (!res.error) closeDialog(dialog.type);
      });
    } else {
      dispatch(updateServiceItem({ ...data })).then((res) => {
        setButtonDisable(false);
        if (!res.error) closeDialog(dialog.type);
      });
    }
  };

  const closeDialog = () => {
    dispatch(closeEditDialog());
  };

  const onEdit = (row) => {
    dispatch(openEditDialog(row));
  };

  const onDelete = (row) => {
    dispatch(deleteServiceItem(row.id));
  };

  let btnText = "";
  let saveValue = 0;

  const tableColumns = [
    {
      name: "Service Item",
      selector: (row) => row.name,
      sortable: true,
      left: true,
    },
  ];

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Service Item" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="12">
                    {currentUser.permission.includes("Create Product Types") ? (
                      <Card>
                        <Row>
                          <Col sm="6">
                            <Nav tabs className="border-tab"></Nav>
                          </Col>
                          <Col sm="6">
                            <div className="float-sm-right p-3">
                              <a
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  dispatch(openNewDialog());
                                }}
                              >
                                {" "}
                                <i className="fa fa-plus"></i> Add Service Item
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={serviceItemResponseToTable(productTypes)}
                        pagination
                        onEdit={
                          currentUser.permission.includes(
                            "Update Product Types"
                          )
                            ? onEdit
                            : ""
                        }
                        onDelete={
                          currentUser.permission.includes(
                            "Delete Product Types"
                          )
                            ? onDelete
                            : ""
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalFormSubmit
        defaultValues={defaultValues}
        schema={schema}
        onSubmitForm={onSubmitForm}
        reset={reset}
        formState={formState}
        handleSubmit={handleSubmit}
        title="Service Item"
        selector="product"
        dialog={({ product }) => product.serviceItem.addDialog}
        closeDialog={closeDialog}
        setValue={setValue}
        getValues={getValues}
        btnText={btnText}
        saveValue={saveValue}
        buttonDisable={buttonDisable}
      >
        <ServiceItemForm
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          trigger={trigger}
          getValues={getValues}
        />
      </ModalFormSubmit>
    </Fragment>
  );
};

export default withReducer([
  {
    productType: productReducer,
  },
])(ServiceItem);
