import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { draftOrderFilters } from "../../manage-product/product/edit/content/settings/map";
import {
  addSalesOrderInvoiceApi,
  editSaleOrderInvoiceApi,
  getInvoiceByOrderApi,
  getInvoiceCreateDataApi,
  getInvoicePendingReturnApi,
  getPendingOrderInvoiceAPi,
  getSalesOrderInvoicesApi,
  updateSalesOrderInvoiceApi,
  downloadSalesOrderInvoiceXeroPdfApi,
  downloadSalesOrderInvoiceRMAPdfApi,
} from "../../../services/manage-order/saleOrderInvoiceService";
import { getFulfillmentCreateDataApi } from "../../../services/manage-order/saleOrderFulfillmentService";
import { setFromSettings } from "./saleOrderFulfillmentSlice";

export const getAllSaleOrderInvoices = createAsyncThunk(
  "sales-order/getInvoices",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderInvoicesApi(filters);
    dispatch(setTableRowCount(response.response.count));

    return response.response.data;
  }
);

export const getInvoiceByOrder = createAsyncThunk(
  "sales-order-invoice/by-order",
  async (data, { dispatch }) => {
    const response = await getInvoiceByOrderApi(data);
    return response.response;
  }
);

export const getInvoicePendingReturn = createAsyncThunk(
  "sales-order-invoice/by-order",
  async (id, { dispatch }) => {
    const response = await getInvoicePendingReturnApi(id);
    return response.response;
  }
);

export const getPendingInvoiceByOrder = createAsyncThunk(
  "sales-order-invoice/pending",
  async (id, { dispatch }) => {
    const response = await getInvoiceByOrderApi(id);
    console.log("Slice getPendingInvoiceByOrderApi : ", response.response);
    return response.response;
  }
);

export const getPendingSaleOrderInvoiceItems = createAsyncThunk(
  "sales-order-invoice/show",
  async (id, { dispatch }) => {
    const response = await getPendingOrderInvoiceAPi(id);
    return response.response;
  }
);
export const addSalesOrderInvoice = createAsyncThunk(
  "sales-order-invoice/add",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderInvoiceApi(orderData);
      const data = await response.response;
      toast.success("Sales Order Invoice Created Successfully");

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const editSaleOrderInvoice = createAsyncThunk(
  "sales-order-invoice/create-data",
  async (id, { dispatch }) => {
    const response = await editSaleOrderInvoiceApi(id);
    return response.response;
  }
);

export const updateSalesOrderInvoice = createAsyncThunk(
  "sales-order-invoice/update",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderInvoiceApi(orderData, orderData.id);
    toast.success(`Sales Order Invoice Updated Successfully`);
    return response.response;
  }
);
export const getCreateInvoiceData = createAsyncThunk(
  "sales-order-invoice/create-data",
  async (id, { dispatch }) => {
    const response = await getInvoiceCreateDataApi(id);
    return response.response;
  }
);

export const downloadSalesOrderXeroInvoicePdf = createAsyncThunk(
  "sales-order-invoice/downloadInvoicePdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderInvoiceXeroPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const downloadInvoiceRMAPdf = createAsyncThunk(
  "sales-order-invoice/downloadInvoiceRMAPdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderInvoiceRMAPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

const salesOrderInvoiceAdapter = createEntityAdapter({});

export const {
  selectAll: selectAllSaleInvoice,
  selectById: selectSaleInvoiceById,
} = salesOrderInvoiceAdapter.getSelectors(
  (state) => state.order.salesOrderInvoice
);

const saleOrderInvoiceSlice = createSlice({
  name: "saleOrderInvoice",
  initialState: salesOrderInvoiceAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      countries: [],
      shippings: [],
    },
    formSettings: {
      terms: [],
    },
    orderInvoices: [],
    filter: draftOrderFilters(),
  }),
  reducers: {
    setSalesOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setOrderInvoices: (state, action) => {
      state.orderInvoices = action.payload;
    },
    setInvoiceFromSettings: (state, action) => {
      state.formSettings = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //     builder.addCase(
  //         getSaleOrderInvoices.fulfilled,
  //         salesOrderInvoiceAdapter.setAll
  //     );
  //     // .addCase(getSalesOrderComment.fulfilled, (state, action) => {
  //     //   // Update comments using the comments field in the state
  //     //   state.comments = action.payload;
  //     // });
  // },
  extraReducers: {
    [getAllSaleOrderInvoices.fulfilled]: salesOrderInvoiceAdapter.setAll,

    // [updateStaff.fulfilled]: staffsAdapter.setAll
    // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
  },
});

export const { setTableRowCount, setOrderInvoices, setInvoiceFromSettings } =
  saleOrderInvoiceSlice.actions;

export default saleOrderInvoiceSlice.reducer;
