import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import {
    createSellerProductListData,
    geSellerProductListData,
    getSellerProductListData, getSellerProductSettings, sellerProductSettings
} from "../store/sellerProductLiatDataSlice";
import moment from "moment";
import {toast} from "react-toastify";
import {mapSearchAutoSuggestion} from "../../../utils/mapper";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import AsyncSelect from "react-select/async";
import {useParams} from "react-router-dom";



const dataMenu = [
    {
        type: 0,
        text: 'Seller',
        link: '/seller'
    },
    {
        type: 1,
        text: 'Seller List',
        link: '/seller/seller-list'
    },
    {
        type: 2,
        text: 'Seller Product List',
        link: ''
    },
];

const tableColumns = [
    {
        name: 'Product',
        sortable: true,
        center: false,
        selector: (params) => (
            <div className="p-1">
                <img width={25} className="img-fluid" src={params.image} alt={params.id} />
                <p className="ml-2" style={{ display: 'inline-block' }}>
                    {params.title}
                </p>
            </div>
        ),
    },
    {
        name: 'Product Type',
        selector: (row) => row.product_type,
        sortable: true,
        right: false,
    },
    {
        name: 'Color',
        selector: (row) => row.product_type,
        cell: (row) => (
            <Select isClearable styles={customStyles} options={row.options.filter(option => option.label !== 'Size')} />
        ),
        sortable: true,
        right: false,
    },
    {
        name: 'Size',
        selector: (row) => row.product_type,
        cell: (row) => (
            <Select isClearable styles={customStyles} options={row.options.filter(option => option.label !== 'Colour')} />
        ),
        sortable: true,
        right: false,
    },
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

export const defaultValues = {
    type: '',
    category: '',
    sub_category :'',
    style:'',
    season:''
};

export const schema = yup.object().shape({
    type: yup.object().required('Type is required field').typeError('Please select type'),
    category: yup.object().required('Category is required field').typeError('Please select category'),
    sub_category: yup.object().required('Sub category is required field').typeError('Please select sub category'),
    // style: yup.object().required('Style is required field').typeError('Please select style'),
    country: yup.object().required('Country is required field').typeError('Please select Country'),
    season: yup.object().required('Season is required field').typeError('Please select season'),
});
const SellerProductList = () => {

    const {handleSubmit, formState, reset, register, setValue, getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {id} = useParams();
    const dispatch = useDispatch();
    const [page,setPage]=useState(0);
    const productSettings = useSelector(({product}) => product.product.productSettings);
    const formRef = useRef();
    const {errors} = formState;
    const submitBtnSaveRef = useRef();
    const [actionBtn, setActionBtn] = useState(0);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({idArray: []});
    const [removed, setRemoved] = useState(false);
    const sellerProductListData = useSelector(geSellerProductListData);
    const sellerProductListDataState = useSelector(({seller}) => seller.sellerProductListData);
    const [isChecked, setIsChecked] = useState(null);
    const [styleArr, setStyleArr] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [itemIdArray, setItemIdArray] = useState([]);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 30);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [seller, setSeller] = useState(null);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const [timer, setTimer] = useState(null);
    const [name, setName] = useState(null);



    const onPageSizeChange = (e) => {
        setPageSize(e)
    }
    const toggleRowSelected = (id) => {
        // console.log(id)
    }


    useEffect(() =>{
        dispatch(getSellerProductSettings()).then(res=>{
            if (!res.error) {
                dispatch(sellerProductSettings(res.payload));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getData()
    },[dispatch,page,pageSize,seller,name]);

    const getData = () => {
        const data = {
            page: page,
            seller,
            name,
            seller_id:id,
            pageSize: pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        }

        dispatch(getSellerProductListData(data))
    }

    const pageChange = (e) => {

        setPage(e)
    }
    const onSubmitForm = (data) => {
        const products = removeDuplicateObjects(itemIdArray);
        const formData = {
            type: data.type.id,
            category: data.category.id,
            sub_category: data.sub_category.id,
            country: data.country.id,
            season: data.season.id,
            styles: data.styles,
            products: products
        }

        dispatch(createSellerProductListData(formData)).then(res=>{
            if (!res.error) {
                toast.success("Create Products successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getData();
            }
        });
    }

    let idArray = [];

    const singleItemChecked = (e, id) => {
        const style = [...styleArr];
        let womenCategoryIndex = style.findIndex(aItem => aItem === id);
        if (womenCategoryIndex !== -1) {
            if (!e.target.checked) {
                style.splice(womenCategoryIndex, 1);
            }
        } else {
            style.push(id);
        }
        setStyleArr(style,...styleArr);

        setValue("style", style)
    }
    const handleChange = (state) => {
        if (state.selectedCount>0) {
            setIsChecked(true);
            state.selectedRows.map((item, i) => {
                idArray.push([{id:item.id, option1: item.option1?item.option1:'', option2: item.option2?item.option2:''}]);
                setItemIdArray(idArray);
                setOrderCheckBoxData(prevState => ({
                    idArray:[...prevState.idArray, ...idArray],

                }));
            });

            const dat  = {
                order_id: idArray
            }
        } else {
            setIsChecked(false)
        }
    };

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });


    function removeDuplicateObjects(array) {
        const uniqueObjects = [];
        const stringifiedObjects = [];

        for (const subArray of array) {
            for (const object of subArray) {
                const stringifiedObject = JSON.stringify(object);
                if (!stringifiedObjects.includes(stringifiedObject)) {
                    stringifiedObjects.push(stringifiedObject);
                    uniqueObjects.push(object);
                }
            }
        }

        return uniqueObjects;
    }


    return (
        <Fragment>
            <>
                <Breadcrumb data={dataMenu} title="Seller Product List Page"/>
                <Container fluid={true}>
                    <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm={3}>
                                    {sellerProductListDataState.sellerProductSettings.types && <FormGroup>
                                        <Label className="form-label">Product Type</Label>
                                        <Select
                                            placeholder={<div>Product Type</div>}
                                            {...register("type")}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            styles={customStyles}
                                            options={sellerProductListDataState.sellerProductSettings.types}
                                            onChange={(e) => {
                                                setValue('type',e);
                                                trigger('type')
                                            }}
                                            className={` ${errors.type ? 'is-invalid' : ''} select2-filter`}
                                        />
                                        <div className="invalid-feedback">{errors.type?.message}</div>
                                    </FormGroup>}

                                </Col>
                                <Col sm={3}>
                                    {sellerProductListDataState.sellerProductSettings.types && <FormGroup>
                                        <Label className="form-label">Product Category</Label>
                                        <Select
                                            {...register("category")}
                                            placeholder={<div>Product Category</div>}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            styles={customStyles}

                                            options={sellerProductListDataState.sellerProductSettings.categories}
                                            onChange={(e) => {
                                                setValue('category',e)
                                                trigger('category')
                                            }}
                                            className={` ${errors.category ? 'is-invalid' : ''} select2-filter`}
                                        />
                                        <div className="invalid-feedback">{errors.category?.message}</div>
                                    </FormGroup>}



                                </Col>
                                <Col sm={3}>
                                    {sellerProductListDataState.sellerProductSettings.types && <FormGroup>
                                        <Label className="form-label">Product Sub Category</Label>
                                        <Select
                                            {...register("sub_category")}
                                            placeholder={<div>Product Sub Category</div>}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            styles={customStyles}

                                            options={(sellerProductListDataState.sellerProductSettings.sub_categories).filter(function (value) {
                                                return value.parent_id == getValues('category').id
                                            })}
                                            onChange={(e) => {
                                                setValue('sub_category',e);
                                                trigger('sub_category')
                                            }}
                                            className={` ${errors.sub_category ? 'is-invalid' : ''} select2-filter`}
                                        />
                                        <div className="invalid-feedback">{errors.sub_category?.message}</div>
                                    </FormGroup>}

                                </Col>
                                <Col sm={3}>
                                    {sellerProductListDataState.sellerProductSettings.types &&
                                        <FormGroup>
                                            <Label className="form-label">Country</Label>
                                            <Select
                                                {...register("country")}
                                                placeholder={<div>Country</div>}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                isClearable
                                                styles={customStyles}

                                                options={sellerProductListDataState.sellerProductSettings.countries}
                                                onChange={(e) => {
                                                    setValue('country', e);
                                                    trigger('country')
                                                }}
                                                className={` ${errors.country ? 'is-invalid' : ''} select2-filter`}

                                            />
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </FormGroup>}

                                </Col>
                                <Col sm={3} className={"mt-4"}>
                                    {sellerProductListDataState.sellerProductSettings.types &&
                                        <FormGroup>
                                            <Label className="form-label">Season</Label>
                                            <Select
                                                {...register("season")}
                                                placeholder={<div>Season</div>}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                className="select2-filter"
                                                isClearable
                                                styles={customStyles}

                                                options={sellerProductListDataState.sellerProductSettings.seasons}
                                                onChange={(e) => {
                                                    setValue('season',e)
                                                    trigger('season')
                                                }}
                                                className={` ${errors.season ? 'is-invalid' : ''} select2-filter`}
                                            />
                                            <div className="invalid-feedback">{errors.season?.message}</div>
                                        </FormGroup>}

                                </Col>
                            </Row>
                            {getValues('sub_category') && <Label className="form-label">Style</Label>}
                            <Row className={"mt-3"}>


                                {sellerProductListDataState.sellerProductSettings.types && sellerProductListDataState.sellerProductSettings.styles.filter(function (value, index) {
                                    return (value.category).filter(function (v, i) {
                                        return v.id == getValues('sub_category').id
                                    }).length > 0
                                }).map((item, i) =>
                                    <Col  md="3" key={item.id}>
                                        <div className=" ">
                                            <input id={`inline-${i}`} type="checkbox"
                                                   onChange={(e)=>{
                                                       singleItemChecked(e,item.id);
                                                   }}
                                                />
                                            <Label for={`inline-${i}`} className="ml-2">{item.name}</Label>
                                        </div>
                                    </Col>)}

                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={(e)=>{
                                                    clearTimeout(timer);
                                                    const newTimer = setTimeout(()=>{
                                                        setName(e.target.value);

                                                    },500);
                                                    setTimer(newTimer)
                                                }}
                                                placeholder="Search Product"
                                            />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                {userState.user.role.name!=="Seller" ?<Col md={6}>
                                    <AsyncSelect
                                        className="select2-filter"
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                        defaultOptions
                                        placeholder="select Seller"
                                        // defaultValue={productState.filter.seller}
                                        loadOptions={sellerPromiseOption}
                                        styles={{...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        onClear={(e) => {

                                        }}
                                        onChange={(e) => {
                                            setSeller(e ? e.value : null)
                                            // dispatch(setProductFilter({
                                            //     // ...productState.filter,
                                            //     seller: e ? e : null,
                                            //     currentPage: 0
                                            // }));
                                            // clearSelectAll();
                                            // // setSearchSeller(e ? e.value : null);
                                        }}

                                    />
                                </Col>:''}
                            </Row>
                            <Row>
                                <Col className="mt-4" sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table">
                                                {sellerProductListData.length>0 && <BackInStockTable
                                                    noAction
                                                    tableColumns={tableColumns}
                                                    idArray={orderCheckBoxData}
                                                    remove={removed}
                                                    backStockData={sellerProductListData}
                                                    backInRequestState={sellerProductListDataState}
                                                    paginationTotalRows={sellerProductListDataState.sellerProductListDataTableRow}
                                                    pageChange={pageChange}
                                                    onPageSizeChange={onPageSizeChange}
                                                    toggleRowSelected={toggleRowSelected}
                                                    handleChange={handleChange}
                                                />}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4" sm="5"></Col>
                                <Col className="mt-4 mb-2" sm="2">
                                    <button ref={submitBtnSaveRef} onClick={() => {
                                        formRef.current.submit();
                                        setActionBtn(0)
                                    }} className="btn btn-primary btn-block">Save
                                    </button>
                                </Col>
                                <Col className="mt-4" sm="5"></Col>
                            </Row>
                        </div>
                    </div>
                    </Form>
                </Container>

            </>
        </Fragment>
    );
}

export default SellerProductList;