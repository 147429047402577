import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Button, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import JqxGrid, {IGridProps, jqx} from './../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import {forEach} from "react-bootstrap/ElementChildren";
import {
    packPriceCalculation,
    singleCalculation,
    singlePackCalculation,
    singlePriceCalculation
} from "./settings/calculation";
import * as ReactDOMServer from "react-dom/server";
import MediaModal from "./mediaModal";
import {useDispatch} from "react-redux";
import {setMediaDialog, setNormalSkuDialog, setSkuDialog} from "../../../store/prodcutSlice";
import {Popover, PopoverBody, PopoverHeader} from "react-bootstrap";
import SkuDetails from "./skuDetails";
import NormalSkuDetails from "./normalSkuDetails";
import log from "../../../../log";

// const dataSizeRatio = [
//     {size: 1, ratio: 2},
//     {size: 3, ratio: 4},
//     {size: 4, ratio: 8},
// ];
let currentIndex="";
let currentEditedCell="";
const VariationJsx = (props) => {
    const {setValue, getValues, trigger,productState,modules} = props;
    let gridRef = useRef(null);
    let subGrid = useRef([]);

    let selectRef = useRef(null);
    const [isModalOpen,setIsModalOpen]=useState(false);
    const [gridDAta, setGridData] = useState(getValues('variation_configurations'));
    const [binData, setBinData] = useState([]);
    const [subGridHeight, setSubGridHeight] = useState(180);
    const dispatch = useDispatch();
    const has_single_price=modules.includes('has-single-price');



    //const roleRegister = register("role", { required: true });
    const datafields = [
        {name: 'colour_id', type: 'id'},
        {name: 'is_published', type: 'boolean'},
        {name: 'no_of_total_pack', type: 'number'},
        {name: 'colour', type: 'string'},
        {name: 'image', type: 'string'},
        {name: 'image_id', type: 'number'},
        {name: 'place_in_packs', type: 'number'},
        {name: 'pack_qty', type: 'number'},
        {name: 'pack_for_single', type: 'number'},
        {name: 'no_piece_in_pack', type: 'number'},
        {name: 'variations', type: 'array'},
    ];
    const columns = [
        {datafield: 'colour_id', hidden: true},
        {datafield: 'image_id', hidden: true},
        {datafield: 'no_of_total_pack', hidden: true},
        {
            text: '',
            datafield: 'is_published',
            editable: true,
            cellclassname: 'rowColor',
            columntype: 'checkbox',
            width: 25,
            cellbeginedit(row, datafield, columntype, val) {
                let found=0;
                const rowData = gridRef.getrowdata(row);
                const gridInformation = gridRef.getdatainformation();
                for (let i = 0; i < gridInformation.rowscount; i++) {
                    if (gridRef.getrowdata(i).is_published) {
                        if(row != i) {
                            found++;
                        }
                    }
                };
                const colourData= getValues('color').find(colour => colour.id == rowData.colour_id);
                return  found > 0 && colourData.commited_quantity <1 ;
            },
            cellendedit: function (row, datafield, columntype, oldvalue, value) {
                    let colourData= [];
                    const rowData = gridRef.getrowdata(row);
                    getValues('color').map((rationItem, rationIndex) => {
                        console.log(rationItem,rowData);
                        if(rationItem.id == rowData.colour_id){
                            rationItem.is_published=oldvalue;
                        }
                        colourData.push(rationItem);
                    });
                    setValue('color',colourData);
                    // setValue('color_value_change',!getValues('color_value_change'));
                    // trigger('color_value_change');
            }

        },
        {
            text: 'Colour',
            datafield: 'colour',
            editable: false,
            cellclassname: 'rowColor',

        },
        {
            text: 'image',
            datafield: 'image',
            editable: false,
            cellsalign: 'right',
            cellclassname: 'rowColor',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const html = ReactDOMServer.renderToString(
                    <div style={{ textAlign: 'center', marginTop: 3 }}>
                        <img className="img-fluid" src={value?value:require('../../../../../assets/images/default-medium-product.jpeg')} width="30" height="30" alt="" />
                    </div>
                );
                return html;
            },
            cellclick(row) {
                alert(row);
            },


        },
        {
            text: 'Total Quantity',
            datafield: 'no_piece_in_pack',
            editable: false,
            cellsalign: 'right',
            width: '150',
            cellclassname: 'rowColor'

        },
        {
            text: 'Piece in Packs',
            datafield: 'place_in_packs',
            editable: false,
            cellsalign: 'right',
            width: '150',
            cellclassname: 'rowColor'

        },
        {
            text: 'Pack Qty',
            datafield: 'pack_qty',
            editable: false,
            cellsalign: 'right',
            width: '150',
            cellclassname: 'rowColor'

        },
        {
            text: 'Packs for Single Pcs',
            datafield: 'pack_for_single',
            editable: false,
            columntype: 'text',
            cellsalign: 'right',
            width: '150',
            cellclassname: 'rowColor',
            headerclassname: 'rowColor',
            validation: function (cell, value) {
                const totalPack = gridRef.getcellvalue(cell.row, 'no_of_total_pack');
                if (Number(totalPack) < Number(value)) {
                    toast.error("should be less than or equal no of pack (" + totalPack + ")", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "should be less than no of pack (" + totalPack + ")"}
                } else {
                    return true;

                }
            },
            cellendedit: function (row, datafield, columntype, oldvalue, value) {
                if (value) {
                    singlePackCalculation(null, null, getValues('size'), subGrid, gridRef, row, value);
                    // alert("test");
                    let colourData= [];
                    const rowData = gridRef.getrowdata(row);
                    getValues('color').map((rationItem, rationIndex) => {
                        if(rationItem.id == rowData.colour_id){
                            rationItem.pack_for_single=value;
                        }
                        colourData.push(rationItem);
                    });
                    setValue('color',colourData);
                    // setValue('color_value_change',!getValues('color_value_change'));
                    // trigger('color_value_change');
                }
            }
        }


    ];


    useEffect(() => {
        
        loadVariationData();
    }, [getValues('selling_type'), getValues('color'), getValues('size'),getValues('value_change'),isModalOpen], getValues('bin_locations'));

    const loadVariationData = () => {
        let variationData = [];
        getValues('color').forEach(item => {
            if(item.id) {
                let totalPieceInPack = 0;
                const price=getValues('sale_price')?getValues('sale_price'):getValues('price');
                const isPercentage=has_single_price?getValues('sale_price')?false:true:false;
                getValues('size').map((sizeItem, subIndex) => {
                    totalPieceInPack += Number(sizeItem.ratio);
                });

                let subData = [{type: "total", price: '', total_qty: ""},
                    {type: "pack", price: packPriceCalculation(totalPieceInPack, price), total_qty: ""}];

                if (getValues('selling_type').length > 1) {
                    subData.push({
                        type: "single",
                        price: singlePriceCalculation(Number(price),isPercentage),
                        total_qty: ""
                    })
                } else {
                    subData.push({type: "Broken Pcs", price: '', total_qty: ""})
                }
                if (getValues('selling_type').length < 2 && getValues('selling_type').length > 0) {
                    if (getValues('selling_type').find(x => x.id === 1)) {
                        subData.length = 0;
                        subData.push({type: "total", price: singlePriceCalculation(Number(price),isPercentage), total_qty: ""})
                        setSubGridHeight(70);
                    } else {
                        setSubGridHeight(141)
                    }
                } else {
                    setSubGridHeight(140)
                }
                
                let existMainData = getValues('variation_configurations').find(x => x.colour_id == item.id);
                subData.map((item, index) => {
                    let existSubData = null;
                    let existPackSubData = null;
                    if (existMainData && index == 0) {
                        existSubData = (existMainData.variations).find(x => x.type == 'total');
                    }
                    else if(existPackSubData && index == 1){
                        existPackSubData = (existPackSubData.variations).find(x => x.type == 'pack');
                    }
                    
                    getValues('size').map((sizeItem, subIndex) => {
                        // let qty = 0;
                        // if(getValues('bin_locations')){
                            

                        //     if(index == 1){
                        //         getValues('bin_locations')[1].map((bin) => {
                        //             qty += bin.qty;
                        //         });
                        //     }else{
                        //         getValues('bin_locations')[sizeItem.id].map((bin) => {
                        //             qty += bin.qty;
                        //         });
                        //     }
                        // }

                        item[sizeItem.id] = existSubData ? (existSubData[sizeItem.id] ? existSubData[sizeItem.id] : 0) : 0;
                        // item[sizeItem.id] = qty ? qty : existSubData ? (existSubData[sizeItem.id] ? existSubData[sizeItem.id] : 0) : 0;
                    });

                });


                variationData.push({
                    colour_id: item.id,
                    colour: item.name + "  "+getColourStar(item.id),
                    is_published:item.is_published?1:0,
                    place_in_packs: existMainData ? existMainData.place_in_packs : "",
                    pack_qty: existMainData ? existMainData.pack_qty : "",
                    pack_for_single:  (item.pack_for_single?item.pack_for_single:existMainData ? existMainData.pack_for_single:0),
                    no_piece_in_pack: existMainData ? existMainData.no_piece_in_pack : "",
                    no_of_total_pack: existMainData ? existMainData.no_of_total_pack : "",
                    image: existMainData ? existMainData.image : item.product_image_url,
                    image_id: existMainData ? existMainData.image_id : item.product_image_id,
                    variations: subData
                })
            }
        });
        console.log(variationData);

        setValue('variation_configurations', variationData);
        setGridData(variationData);
    }

    useEffect(() => {

        if (getValues('selling_type').length < 2) {
            if (getValues('selling_type').find(x => x.id === 2)) {
                gridRef.hidecolumn('pack_for_single');
            } else {
                gridRef.hidecolumn('place_in_packs');
                gridRef.hidecolumn('pack_qty');
                gridRef.hidecolumn('pack_for_single');
                gridRef.hidecolumn('no_piece_in_pack');
            }
        } else {
            gridRef.hidecolumn('no_piece_in_pack')

        }

    }, [gridDAta]);

    useEffect(() => {
        setTimeout(
            () => {
                // gridRef.showrowdetails(0);
                // getValues('color').map((create, i) => {
                //     if(i<4) {
                //          gridRef.showrowdetails(i);
                //     }
                //
                // });

            }, 1000);
    }, [gridDAta])


    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;



            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                             gridRef.updatebounddata();
                             getValues('color').map((item, i) => {
                                if(i<5) {
                                   gridRef.showrowdetails(i);
                                }
                            });
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };


    const divRef = useRef(null);
    const cellEdit = event => {
        setTimeout(
            () => {
             setGridDataToHookForm(event.args.rowindex);
        },500)

    };
    const setGridDataToHookForm = (index)=>{

        let variationData = getValues('variation_configurations');
        const myEle =subGrid.current[index]? document.getElementById(subGrid.current[index]._id):"";
        if(myEle) {

                    const subGridInformation = subGrid.current[index].getdatainformation();
                    let variationPackDetails = [];
                    for (let k = 0; k < subGridInformation.rowscount; k++) {
                        variationPackDetails.push(subGrid.current[index].getrowdata(k));
                    }
                    const rowData = gridRef.getrowdata(index);
                    rowData['variations'] = variationPackDetails;
                    variationData[index] = rowData;



        }
    }


    const nestedGrids: any[] = [];
    const initrowdetails = (index: number, parentElement: any, gridElement: any, record: any): void => {
        const id = record.uid.toString();
        const nestedGridContainer = parentElement.children[0];
        nestedGrids[index] = nestedGridContainer;


        // fill the orders depending on the id.
        let suGridDataField = [
            {name: 'type', type: 'string'},
            {name: 'price', type: 'string'}, ,

        ];


        let subGridColumns = [
            {
                text: '<strong>Type</strong>', datafield: 'type', editable: false,
            },
            {text: '<strong>Price</strong>', datafield: 'price', editable: false,  cellsalign: 'right',    align: 'center', cellsformat: 'c2'},

        ];
        const committedQuantities=getValues('committed_quantity')

        getValues('size').forEach(item => {
            suGridDataField.push({name: item.id, type: 'number'});
            let committedText=""
            const committedQuantity =committedQuantities.find(x => (x.colour_id == record.colour_id && x.size_id==item.id));
           if(committedQuantity && committedQuantity.quantity > 0){
               committedText= "<b title='commited Quantity'>("+committedQuantity.quantity+")</b>";
           }
            // if() {
            //     if(committedQuantities[record.colour_id].indexOf(item.id) != -1) {
            //         committedQuantity = committedQuantities[record.colour_id][item.id];
            //     }
            // }

            subGridColumns.push({
                editable: !getValues('is_third_party_seller') && getValues('is_module_permission') ? false : true,
                text: '<strong>' + item.name +committedText+'</strong>', datafield: item.id, width: '100',  cellsalign: 'right',align: 'center',
                cellbeginedit: function (row, datafield, columntype, value) {
                    return row == 0;
                },
                cellvaluechanging: function (row, datafield, columntype, oldvalue, value) {
                    if (value) {
                        if (getValues('selling_type').length > 1 || getValues('selling_type').find(x => x.id === 2)) {
                           singlePackCalculation(value, datafield, getValues('size'), subGrid, gridRef, index)
                        } else {
                         singleCalculation(getValues('size'), value, datafield, index, subGrid);
                            subGrid.current[index].setcellvalue(0,datafield, value);
                        }
                    }
                },

            });
        });
        suGridDataField.push({name: 'total_qty', type: 'number'});
        subGridColumns.push({
            text: '<strong>Total Qty</strong>',
            datafield: 'total_qty',
            width: '100',
            editable: false,
            cellsalign: 'right'
        });


        const ordersSource = {
            datafields: suGridDataField,
            localdata: record.variations
        }
        const nestedGridAdapter = new jqx.dataAdapter(ordersSource);
        if (nestedGridContainer != null) {


            ReactDOM.render(
                <JqxGrid id="jqx_sub" ref={jqxRef => {
                    subGrid.current[index] = jqxRef;
                }} editable={true} theme='material'
                         onCellbeginedit={(event)=>{
                             const rowCell=event.args;
                             currentIndex=index;
                             currentEditedCell=rowCell.datafield;
                         }}
                         onCellendedit={(event)=>{
                             const rowCell=event.args;
                             // subGrid.current[0].setcellvalue(0,rowCell.datafield, rowCell.value);
                             setTimeout(()=>{
                                 setGridDataToHookForm(index);
                             },500);

                         }}
                         width={'100%'} source={nestedGridAdapter} showtoolbar={false}
                         selectionmode='singlecell' columnsheight={5} columns={subGridColumns}/>,
                document.getElementById(nestedGridContainer.id)
            );

            setTimeout(() => {
                const myEle = document.getElementById(subGrid.current[index]._id);
                if(myEle) {
                    if (getValues('selling_type').length > 1 || getValues('selling_type').find(x => x.id === 2)) {
                        const singlePack = record.pack_for_single ? record.pack_for_single : 0;
                        singlePackCalculation(null, null, getValues('size'), subGrid, gridRef, index, singlePack)
                    } else {


                        subGrid.current[index].hidecolumn('type');
                        singleCalculation(getValues('size'), null, null, index, subGrid);
                    }
                    setGridDataToHookForm(index);
                }
            },1000)




        }
    };

const cellClickFunction = (data)=>{


    const boundData=data.row.bounddata;
    if(data.datafield == "image") {
        let obj = {props: {isOpen: true}, data: {rowIndex: boundData.boundindex, image_id: boundData.image_id,colour_id: boundData.colour_id}};
        dispatch(setMediaDialog(obj))
    }else if(data.datafield == "colour"){
         const colourSplit=(boundData.colour).split(" <");
        let obj = {props: {isOpen: true}, data: {rowIndex: boundData.boundindex,colour_id: boundData.colour_id, colour: colourSplit[0]}};

        if (!getValues('is_third_party_seller') && getValues('is_module_permission')) {
            dispatch(setSkuDialog(obj));
        }else{
            dispatch(setNormalSkuDialog(obj));
        }
    }
   // setIsModalOpen(true)
}
    const skuOnClick=(index,colourId,colourName, binLocations)=>{
        gridRef.setcellvalue(Number(index), "colour",colourName+" "+getColourStar(colourId));
        setBinData(binLocations);
        setValue('bin_locations', binLocations);
        trigger('bin_locations');
        setTimeout(
            () => {
                loadVariationData();
                
            }, 1000);

    }

    const normalSkuOnClick = (index,colourId,colourName, binLocations)=>{
        gridRef.setcellvalue(Number(index), "colour",colourName+" "+getColourStar(colourId));
        setTimeout(
            () => {
                loadVariationData();
                
            }, 1000);
    }

    const getColourStar=(colourId)=>{
        let totalSize= getValues('size').length;
        totalSize=getValues('selling_type').find(x => x.id === 2)?totalSize+1:totalSize;




        const binNumbers= getValues('bin_numbers').filter(function (item){
            return (item.colour_id==colourId) && (item.bin_number.length > 0 || item.gtn_number);
        })
        let name="GTN & Bin # not updated"
        let star='fa fa-star-o';
        if(binNumbers.length >0 ){
            let isComplete=true;
            if(binNumbers.length === totalSize) {
                binNumbers.map((item) => {
                    if (item.bin_number.length == 0 || !item.gtn_number) {
                        isComplete = false;
                    }
                })
            }else{
                isComplete = false;
            }
            star=isComplete?"fa fa-star":"fa fa-star-half-empty";
            name=isComplete?"GTN & Bin # updated":"GTN & Bin # not fully updated";

        }
        return "<span><i class='"+star+"' title='"+name+"'></i></span>";
    }
    const imageSelectOnClick= (index,imageId,imagePath)=>{
        const gridData=gridRef.getrowdata(Number(index));
        gridRef.setcellvalue(Number(index), "image", imagePath);
        gridRef.setcellvalue(Number(index), "image_id", imageId);

        let colourData= [];
        getValues('color').map((rationItem, rationIndex) => {
              if(rationItem.id == gridData.colour_id){
                  rationItem.product_image_id=imageId;
              }
            colourData.push(rationItem);
        });

        setValue('color',colourData);
        setGridDataToHookForm(index);
        let obj={props:{isOpen:false},data:""};
        dispatch(setMediaDialog(obj));


    }
    useEffect(() => {
        if( document.getElementById("submit-div")) {
            document.getElementById("submit-div").addEventListener('click', (event) => {
                if(currentEditedCell) {
                    subGrid.current[currentIndex].endcelledit(0, currentEditedCell);
                }

            });
        }
    }, [])

    return (
        <Fragment>

            <div style={{width: '100%'}}>
                <JqxTable
                    datafields={datafields}
                    columns={columns}
                    data={gridDAta}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    isDelete={false}
                    onCellendedit={cellEdit}
                    rowdetails={true}
                    autoheight={true}
                    initrowdetails={initrowdetails}
                    cellClickFunction={cellClickFunction}
                    cellClickDataField={['image','colour']}
                    disableCreateRow
                    rowdetailstemplate={{
                        rowdetails: '<div id="nestedGrid" style="margin: 1px,border: 1px solid black"></div>',
                        rowdetailsheight: subGridHeight,
                        rowdetailshidden: true
                    }}

                />
            </div>
            <MediaModal setValue={setValue}
                        trigger={trigger}
                        productState={productState}
                        isModalOpen={getValues('isModalOpen')}
                        imageSelectOnClick={imageSelectOnClick}
                        getValues={getValues}></MediaModal>
            
            { !getValues('is_third_party_seller') ? <SkuDetails   trigger={trigger} getValues={getValues}  productState={productState} setValue={setValue} skuOnClick={skuOnClick}>

            </SkuDetails> :

            <NormalSkuDetails   trigger={trigger} getValues={getValues}  productState={productState} setValue={setValue} skuOnClick={normalSkuOnClick}>

            </NormalSkuDetails> }

        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.settingData) == JSON.stringify(nextProps.settingData);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(VariationJsx, areEqual);