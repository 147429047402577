import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapOrderResponseToTable, mapSellerRoleResponseToTable} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    addTrackingItems,
    closeNewInvoiceDialog, closeNewTrackingDialog, createTrackingInvoice, downloadOrderInvoice, downloadTrackingInvoice,
    openNewInvoiceDialog, openNewTrackingDialog,
    setOrderData,

    setSettings,
    showOrder, showTrackingInvoice
} from "../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import orderReducer from "../store";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {toast} from "react-toastify";
import {Details} from "./content/details";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import SettingForm from "../../manage-product/product-setting/settingForm";
import RoleForm from "../../manage-user/role/roleForm";
import {openNewDialog} from "../../manage-user/store/roleSlice";
import InvoiceForm from "./invoice/create";
import TrackingForm from "./create";
import PreLoader from "../../../share-components/ui/preLoader";

export const defaultValues = {
    invoiceDate: new Date(),
};

export const schema = yup.object().shape({
    invoiceDate: yup.date().required('Invoice Date is required field'),
});

export const defaultValuesTracking = {
    trackingName: '',
    trackingId: '',
    trackingUrl: '',
};
const urlValidation = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";

export const schemaTracking = yup.object().shape({
    // trackingName: yup.string().required('Tracking Name is required field'),
    trackingId: yup.string().required('Tracking ID is required field'),
    trackingUrl: yup.string().matches(urlValidation,"Entered URL is invalid").required('Tracking URL is required field'),
    trackingName: yup.object().required('Deliver Partner is required field').typeError('Deliver Partner is required field'),
});
const AddTracking = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [productVariants,setProductVariants]=useState([]);
    const [loading, isSetLoading] = useState(true);
    const [btnDisabled,setBtnDisabled]=useState(false);

    const orderState = useSelector(({ order }) => order.order);
    useEffect(() => {
        dispatch(showTrackingInvoice(id)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
                isSetLoading(false);
            }
        });
    }, [dispatch]);

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Add Tracking"
        }
    ];

    const { handleSubmit, formState, reset, register, setValue , getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const onSubmitForm = (dialog,data) => {
        if(productVariants.length!==0){
            setBtnDisabled(true);
            const set= {
                "invoiceDate": `${data.invoiceDate.getFullYear()}-${(data.invoiceDate.getMonth()+1)<2?'0'+(data.invoiceDate.getMonth()+1):(data.invoiceDate.getMonth()+1)}-${data.invoiceDate.getDate()<2?'0'+data.invoiceDate.getDate():data.invoiceDate.getDate()}`,
                "orderId":id,
                "items":productVariants
            }

            dispatch(createTrackingInvoice(set)).then(res => {
                if (!res.error) {
                    setProductVariants([]);
                    onAddInvoiceClose();
                    dispatch(setOrderData(res.payload));
                    setBtnDisabled(false);
                }
            });
        }else{
            toast.error("At least one create is required to be selected", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const { handleSubmit:handleSubmitTracking, formState:formStateTracking, reset:resetTracking, register:registerTracking, setValue:setValues, trigger:triggers} = useForm({
        mode: 'onChange',
        defaultValues:defaultValuesTracking,
        resolver: yupResolver(schemaTracking)
    });
    const { errors:errorsTracking } = formStateTracking;
    const onSubmitFormTracking = (dialog,data) => {
        const set= {
            // "trackingName": data.trackingName,
            "trackingID": data.trackingId,
            "trackingUrl": data.trackingUrl,
            "invoiceID":orderState.selectedInvoiceID,
            "trackingName":data.trackingName.name
        }
        setBtnDisabled(true);
        dispatch(addTrackingItems(set)).then(res => {
            setBtnDisabled(false);
            if (!res.error) {
                onAddTrackingClose();
                dispatch(setOrderData(res.payload));
            }
        });
    }

    const onAddInvoiceClose=()=>{
        reset(defaultValues);
        dispatch(closeNewInvoiceDialog());
    }

    const onAddTrackingOpen=(invoiceID)=>{
        dispatch(openNewTrackingDialog(invoiceID));
    }

    const onAddTrackingClose=()=>{
        resetTracking(defaultValuesTracking);
        dispatch(closeNewTrackingDialog());
    }

    const handleTrackingInvoiceDownload =(id,invoiceNo)=>{
        dispatch(downloadTrackingInvoice(id)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${invoiceNo}_Tracking_Invoice_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    return (
        <Fragment>
            {loading ? <>
                    <PreLoader></PreLoader>
                </>
                : <>
            <Breadcrumb title="Orders" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                {orderState.list?orderState.list.length>0 &&(   <div className="product-grid">
                    <div className="feature-products">
                        <Card>
                            <Row>
                                <Col sm="12">
                                    <div className='float-sm-left p-3'>
                                        <h5>Tracking for {orderState.data?orderState.data.order_name:""}</h5>
                                    </div>
                                    <div className='float-sm-right p-3'>
                                        <a onClick={()=>{dispatch(openNewInvoiceDialog())}} className="btn btn-sm btn-primary"> <i className="fa fa-plus"/> Create Tracking Invoice</a>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>):""}

                    {orderState.trackingDetails?orderState.trackingDetails.length>0 && (
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <Details orderState={orderState} onAddTrackingOpen={onAddTrackingOpen} handleTrackingInvoiceDownload={handleTrackingInvoiceDownload} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ):""}

            </Container>
            </>}
            <ModalFormSubmit
                index={1}
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                btnDisabled={btnDisabled}
                loadingBtn
                size="lg"
                formState={formState}
                handleSubmit={handleSubmit}
                title='Invoice'
                selector='order'
                dialog={({ order }) => order.order.newInvoiceDialog}
                closeDialog={onAddInvoiceClose}
            >
                <InvoiceForm productVariants={productVariants} setProductVariants={setProductVariants} orderState={orderState} errors={errors} setValue={setValue} trigger={trigger}/>
            </ModalFormSubmit>

            <ModalFormSubmit
                index={2}
                defaultValues={defaultValuesTracking}
                schema={schemaTracking}
                onSubmitForm={onSubmitFormTracking}
                reset={resetTracking}
                formState={formStateTracking}
                handleSubmit={handleSubmitTracking}
                btnDisabled={btnDisabled}
                loadingBtn
                title='Tracking'
                selector='order'
                dialog={({ order }) => order.order.addTrackingDialog}
                closeDialog={onAddTrackingClose}
            >
                <TrackingForm orderState={orderState} trigger={triggers} errors={errorsTracking} register={registerTracking} setValue={setValues} />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(AddTracking);