import React, { Fragment, useState } from "react";
import { Col, Table, Popover, PopoverBody } from "reactstrap";
import { setSalesOrderBinLocationDialogProps } from "../../../store/salesOrderSlice";
import { useDispatch } from "react-redux";
import BinLocationModal from "../../view/content/binLocationModal";

const SaleOrderStatusProductJqx = (props) => {
  const { getValues, setValue, trigger, enableDownloadPicking } = props;
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState({});

  const productsResponseToTable = (response) => {
    return response.map((res) => {
      return {
        line_item_id: res.id,
        product_id: res.product_id,
        product_image: res.image,
        variant: res.variant,
        product_name: res.product_name,
        variant_name: res.variant_name,
        sku: res.sku,
        stock: res.stock,
        back_order_quantity: res.back_order_quantity,
        quantity: res.quantity,
        ordered_quantity: res.ordered_quantity,
        location: res.location,
      };
    });
  };

  const togglePopover = (item) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const createBinLocation = (item) => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: {
        id: item.line_item_id,
        sku: item.sku,
        orderedQty: item.quantity,
        location: item.location,
      },
    };

    dispatch(setSalesOrderBinLocationDialogProps(data));
  };

  return (
    <Fragment>
      <Col md={12}>
        <div
          className="table-responsive w-100"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <Table>
            <thead
              className="thead-light"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <tr>
                <th style={{ width: "28%" }}>Product</th>
                <th style={{ textAlign: "center", width: "10%" }}>
                  Order Qty ({getValues("sum_quantity")})
                </th>
                <th style={{ textAlign: "center", width: "10%" }}>
                  Back Qty ({getValues("sum_back_order_quantity")})
                </th>
                <th style={{ textAlign: "center", width: "12%" }}>Bin</th>
              </tr>
            </thead>
            <tbody>
              {productsResponseToTable(getValues("ordered_products")).map(
                (item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            className="img-fluid"
                            src={item.product_image}
                            width="40"
                            alt=""
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <p className={"font-primary"}>
                              {item.product_name}
                            </p>
                          </div>
                          <div className="row">
                            <p>{item.option}</p>
                          </div>
                          <div className="row d-flex">
                            <p>{item.sku}</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>{item.quantity}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>
                        {item.back_order_quantity}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "1rem" }}>
                        {enableDownloadPicking ? (
                          <button
                            onClick={() => createBinLocation(item)}
                            className="ml-auto mr-1"
                            style={{ background: "none" }}
                            title="Create Fulfillment"
                          >
                            <i
                              className="fa fa-external-link"
                              style={{
                                fontSize: 16,
                                color: "rgba(0, 128, 255)",
                              }}
                              disabled
                            ></i>
                          </button>
                        ) : (
                          <>
                            <button
                              style={{
                                background: "none",
                                cursor: "not-allowed",
                              }}
                              className="ml-auto mr-1"
                              title="Change Bin"
                              id={`pickingTicketPopover-${item.line_item_id}`}
                            >
                              <i
                                className="fa fa-external-link"
                                style={{
                                  fontSize: 16,
                                  color: "rgba(0, 128, 255, 0.8)",
                                }}
                              ></i>
                            </button>
                            <Popover
                              placement="bottom"
                              isOpen={popoverOpen[item.line_item_id] || false}
                              target={`pickingTicketPopover-${item.line_item_id}`}
                              toggle={() => togglePopover(item.line_item_id)}
                              trigger="hover"
                            >
                              <PopoverBody>
                                This order has back-order quantity.
                              </PopoverBody>
                            </Popover>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </Col>
      <BinLocationModal
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></BinLocationModal>
    </Fragment>
  );
};

export default SaleOrderStatusProductJqx;
