import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import {
  FileText,
  LogIn,
  Mail,
  User,
  MessageSquare,
  Bell,
  Minimize,
  Search,
  ShoppingCart,
  Minus,
  Plus,
  X,
  Key, LogOut, Activity,
} from 'react-feather';
import Bookmark from "../../layout/bookmark"
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

import {
  English,
  Deutsch,
  Español,
  Français,
  Português,
  简体中文,
  Notification,
  DeliveryProcessing,
  OrderComplete,
  TicketsGenerated,
  DeliveryComplete,
  CheckAllNotification,
  ViewAll,
  MessageBox,
  EricaHughes,
  KoriThomas,
  Admin,
  Account,
  Inbox,
  Taskboard,
  LogOutWord,
  AinChavez,
  CheckOut,
  ShopingBag,
  OrderTotal,
  GoToShopingBag,
  PasswordReset
} from '../../constant'


import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import {connect, useDispatch} from "react-redux";

import {logoutUser} from "../../redux/auth/userSlice";
import {Link} from "react-router-dom";
import DesktopNotification from "./desktopNotification";
import Selleroption from './selleroption';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();

const Rightbar = (props) => {    
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [selected, setSelected] = useState("en")
  const [cartDropdown, setCartDropDown] = useState(false)

  const [chatDropDown, setChatDropDown] = useState(false)
  const dispatch = useDispatch();
  // const history= useHistory();

  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key)
  };

  useEffect(() => {
    if(localStorage.getItem("layout_version") === "dark-only"){
      setMoonlight(true)
    }
  }, []);

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }


  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          {!(props.userData.user.modules).includes('is-hide-cg') && <li className='col-md-4'>
               <Selleroption />
          </li>}
          <li className="onhover-dropdown">
               <DesktopNotification currentUser={props.userData}></DesktopNotification>
          </li>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>

          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={props.userData.photoURL} alt="" />
              <div className="media-body"><span>{props.userData.displayName}</span>
                <p className="mb-0 font-roboto">{props.userData.user.role.name} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              {props.userData.user.role.id==5&& <Link to={`${process.env.PUBLIC_URL}/details`}><li><User /><span>Details</span></li></Link>}
              <Link to={`${process.env.PUBLIC_URL}/user-profile`}><li><User /><span>{Account} </span></li></Link>
              {props.userData.user.role.name==="Super Admin" && <Link to={`${process.env.PUBLIC_URL}/activity-log`}><li><Activity /><span>Activity Log</span></li></Link>}
              {/*<Link to={`${process.env.PUBLIC_URL}/user-password-reset`}><li> <Key /><span style={{textAlign:"center",fontSize:"0.8em"}}>{PasswordReset}</span></li></Link>*/}
              {/*<li><Mail /><span>{Inbox}</span></li>*/}
              {/*<li><FileText /><span>{Taskboard}</span></li>*/}
              <li><a onClick={()=>{dispatch(logoutUser())}}><LogOut /><span>{LogOutWord}</span></a></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
const mapStateToProps = ({authReducer}) => {
  return {
    userData: authReducer.data
  };
}

export default connect(mapStateToProps)(translate(Rightbar));