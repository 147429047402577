import React, {Fragment, useEffect, useRef, useState} from "react";
import {Card, CardBody, Col, FormGroup, Row} from "reactstrap";
import JqxTable from "../../../../../share-components/table/JqxTable";
import * as ReactDOMServer from "react-dom/server";
import {numberWithCurrency} from "../../../../../utils/common";
import {setInventoryDialog} from "../../../store/salesOrderSlice";
import {useDispatch} from "react-redux";
import InventoryLocation from "./inventoryLocation";
import {shippingTaxAmount} from "../../setting/calculation";
import {setOrderItemDiscountDialog} from "../../../store/draftOrderSlice";
import OrderItemDiscount from "../../../draft-order/form/content/itemDiscount";
import {jqx} from "../../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";

const SaleOrderProductJqx = (props) => {
    const {
        setValue,
        trigger,
        getValues,
        orderedProducts,
        id,
        onChangeDialog,
        currencyChange,
        isTax,
    } = props;
    const dispatch = useDispatch();
    let gridRef = useRef(null);
    const [tableKey, setTableKey] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState(getValues("ordered_products"));
    // const [filteredData, setFilteredData] = useState([]);

    const datafields = [
        {name: "id", type: "number"},
        {name: "variant", type: "number"},
        {name: "product_id", type: "number"},
        {name: "product_name", type: "string"},
        {name: "image", type: "string"},
        {name: "location", type: "string"},
        {name: "sku", type: "string"},
        {name: "variant_name", type: "string"},
        {name: "quantity", type: "number"},
        {name: "total_quantity", type: "number"},
        {name: "back_order_quantity", type: "number"},
        {name: "price", type: "number"},
        {name: "total_price", type: "number"},
        {name: "stock", type: "number"},
        {name: "original_price", type: "number"},
        {name: "item_discount", type: "array"},
        {name: "discount", type: "number"},
        {name: "amount", type: "number"},
        {name: "finished_quantity", type: "number"},
        {name: "is_tax", type: "boolean"},
        {name: "tax_amount", type: "number"},
        {name: "gross_amount", type: "number"},
        {name: "inventories", type: "array"},
        {name: "inventory_location", type: "array"},
        {name: "commodity", type: "string"},
        {name: "weight", type: "number"},
        {name: "unit_weight", type: "number"},
    ];

    const columns = [
        {datafield: "id", hidden: true},
        {datafield: "variant", hidden: true},
        {datafield: "sku", hidden: true},
        {datafield: "total_price", hidden: true},
        {datafield: "tax_amount", hidden: true},
        {datafield: "quantity", hidden: true},
        {datafield: "commodity", hidden: true},
        {datafield: "inventory_location", hidden: true},
        {datafield: "back_order_quantity", hidden: true},
        {datafield: "discount", hidden: true},
        {datafield: "weight", hidden: true},
        {datafield: "unit_weight", hidden: true},
        {
            text: "Product",
            datafield: "product_name",
            editable: false,
            columntype: "text",
            width: "33%",
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues("ordered_products")[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: "center", padding: 5}}>
                        {productDetails && (
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="img-fluid"
                                        src={productDetails.image}
                                        width="40"
                                        alt=""
                                    />
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <p className={"font-primary"}>{value}</p>
                                    </div>
                                    <div className="row">
                                        <p
                                            style={{
                                                cursor: "pointer",
                                                color: "blue",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            {productDetails.sku}
                                        </p>
                                    </div>
                                    <div className="row">
                                        <p className="text-right">
                                            Available : {productDetails.stock}{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
                return html;
            },
        },
        {
            text: "Order Qty",
            datafield: "total_quantity",
            editable: true,
            columntype: "text",
            width: "7%",
            cellsalign: "right",
            align: "right",
            cellclassname: function (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) {
                return "editable-column";
            },
            validation: function (cell, value) {
                const finishedQuantity = gridRef.getcellvalue(
                    cell.row,
                    "finished_quantity"
                );
                if (Number(value) < finishedQuantity) {
                    return {
                        result: false,
                        message: "Qty should be greater than " + finishedQuantity,
                    };
                } else {
                    return true;
                }
            },
        },
        {
            text: "Order Details",
            datafield: "stock",
            editable: false,
            columntype: "text",
            width: "14%",
            cellsalign: "right",
            align: "center",
            cellsrenderer(
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) {
                const productDetails = getValues("ordered_products")[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: "center", padding: 5}}>
                        {productDetails && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="col-md-12 p-1">
                                        <div className="row">
                                            <div className="col-4">
                                                <p className="font-primary text-left font-weight-bolder">
                                                    Available Qty
                                                </p>
                                            </div>
                                            <div className="col-8">
                                                <p className="text-right">{rowdata.quantity}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p className="font-primary text-left font-weight-bolder">
                                                    Back Order
                                                </p>
                                            </div>
                                            <div className="col-8">
                                                <p className="text-right">
                                                    {rowdata.back_order_quantity}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
                return html;
            },
        },
        {
            text: "Location",
            datafield: "location",
            editable: false,
            columntype: "text",
            width: "14%",
            cellsalign: "left",
            align: "left",
            cellsrenderer(
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowData
            ) {
                const inventoryLocationLength = rowData.inventory_location.length;
                let inventory =
                    inventoryLocationLength > 0
                        ? rowData.inventory_location[0].name +
                        " : " +
                        rowData.inventory_location[0].quantity
                        : "";
                inventory +=
                    inventoryLocationLength > 1
                        ? "," +
                        rowData.inventory_location[1].name +
                        " : " +
                        rowData.inventory_location[1].quantity
                        : "";
                if (inventoryLocationLength > 2) {
                    inventory += ", ....";
                }
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: "center", padding: 5, marginTop: 10}}>
                        <p style={{whiteSpace: "pre-line"}}>{inventory}</p>
                    </div>
                );
                return html;
            },
        },
        {
            text: "Rate",
            datafield: "price",
            editable: true,
            columntype: "text",
            width: "7%",
            cellsalign: "right",
            cellsformat: "c2",
            align: "right",
            cellclassname: function (
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) {
                return "editable-column";
            },
            validation: function (cell, value) {
                if (Number(value) < 1) {
                    return {result: false, message: "amount should be greater than 0"};
                } else {
                    return true;
                }
            },
        },
        {
            text: "Tax",
            datafield: "is_tax",
            editable: true,
            columntype: "checkbox",
            width: "4%",
            cellsalign: "center",
            cellsformat: "c2",
            align: "center",
        },
        {
            text: "Amount",
            datafield: "gross_amount",
            editable: false,
            columntype: "text",
            width: "16%",
            height: "100px",
            cellsalign: "right",
            align: "right",
            cellsrenderer(
                row,
                columnfield,
                value,
                defaulthtml,
                columnproperties,
                rowdata
            ) {
                const productDetails = getValues("ordered_products")[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: "center", padding: 5}}>
                        {productDetails && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="col-md-12 p-1">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="font-primary text-center font-weight-bolder">
                                                    Gross{" "}
                                                    {rowdata.item_discount ? <>/ Discount / </> : <>/</>}{" "}
                                                    Tax{" "}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-center">
                                                    {numberWithCurrency(
                                                        rowdata.total_price,
                                                        getValues("currency").symbol
                                                    )}{" "}
                                                    {rowdata.item_discount ? (
                                                        <>
                                                            /
                                                            {numberWithCurrency(
                                                                rowdata.discount,
                                                                getValues("currency").symbol
                                                            )}
                                                            /
                                                        </>
                                                    ) : (
                                                        <>{"/"}</>
                                                    )}
                                                    {numberWithCurrency(
                                                        rowdata.tax_amount,
                                                        getValues("currency").symbol
                                                    )}{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="font-primary text-center font-weight-bolder">
                                                    Sub Total :{" "}
                                                    {numberWithCurrency(
                                                        rowdata.gross_amount,
                                                        getValues("currency").symbol
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
                return html;
            },
        },
        {
            text: "",
            width: "5%",
            datafield: "finished_quantity",
            align: "center",
            cellsalign: "center",
            onCellClick(row) {
                // alert("TEst");
            },
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                if (value < 1) {
                    const html = ReactDOMServer.renderToString(
                        <div className="jqx-delete-button-div">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a style={{color: "red"}}>
                                <span className="fa fa-trash"></span>
                            </a>
                        </div>
                    );
                    return html;
                } else {
                    return "";
                }
            },

            editable: false,
        },
    ];

    const getJqxRef = (jqx) => {
        if (jqx) {
            gridRef = jqx;
            if (gridRef && gridRef.removeEventListener) {
                gridRef.removeEventListener("cellvaluechanged", handleCellValueChanged);
            }

            // Add an event listener for the 'cellvaluechanged' event
            if (gridRef && gridRef.addEventListener) {
                gridRef.addEventListener("cellvaluechanged", handleCellValueChanged);
            }

            setTimeout(() => {
                if (gridRef) {
                    const myEle = document.getElementById(gridRef._id);
                    if (myEle) {
                        gridRef.updatebounddata();
                    }
                }
            }, 1000);
        }
    };
    
    const onDelete = (data) => {
        setDataToHookForm(true);
    };

    useEffect(
        function () {
            if (currencyChange) {
                const gridInformation = gridRef.getdatainformation();
                for (let i = 0; i < gridInformation.rowscount; i++) {
                    const rowData = gridRef.getrowdata(i);
                    const originalPrice = rowData.original_price;
                    const quantity = rowData.quantity;
                    const singlePrice = originalPrice * Number(currencyChange);
                    const isTax = rowData.is_tax;
                    setTimeout(
                        function () {
                            gridRef.setcellvalue(i, "price", singlePrice);
                            setTotalAmount(i, quantity, singlePrice, isTax);
                            setDataToHookForm(true);
                        },
                        1000,
                        false
                    );
                }
                setTimeout(() => {
                    const key = tableKey + 1;
                    setTableKey(key);
                }, 1000);
            }
        },
        [currencyChange]
    );

    const handleCellValueChanged = (event) => {
        const row = event.args.rowindex;
        const column = event.args.datafield;

        if (column === "item_discount") {
            setTotalAmount(
                row,
                gridRef.getcellvalue(row, "quantity"),
                gridRef.getcellvalue(row, "price"),
                gridRef.getcellvalue(row, "is_tax")
            );
        }
    };

    useEffect(
        function () {
            if (isTax) {
                const taxString = isTax.split("-");
                const gridInformation = gridRef.getdatainformation();
                for (let i = 0; i < gridInformation.rowscount; i++) {
                    const rowData = gridRef.getrowdata(i);
                    const originalPrice = rowData.original_price;
                    const quantity = rowData.quantity;
                    const singlePrice = rowData.price;
                    const tax = taxString[1] == "1" ? true : false;
                    setTimeout(
                        function () {
                            gridRef.setcellvalue(i, "is_tax", tax);
                            setTotalAmount(i, quantity, singlePrice, tax);
                            setDataToHookForm(true);
                        },
                        1000,
                        false
                    );
                }
            }
        },
        [isTax]
    );

    const updateTableData = (newData) => {
        setTableData(newData);
        setTimeout(() => {
        }, 0);
    };

    // useEffect(() => {
    //     if (gridRef) {
    //         const filteredData = getValues("ordered_products").filter((item) =>
    //             item.sku.toLowerCase().includes(searchValue.toLowerCase())
    //         );
    //         setFilteredData(filteredData);
    //         updateTableData(filteredData);
    //         setTimeout(() => {
    //             gridRef.clear();
    //             gridRef.addrow(null, filteredData);
    //         }, 0);
    //     }
    // }, [searchValue, gridRef, getValues]);

    const cellEdit = (event) => {
        const rowArgs = event.args;
        if (rowArgs.datafield == "total_quantity") {
            const finishedQuantity = gridRef.getcellvalue(rowArgs.rowindex, "finished_quantity");
            if (Number(rowArgs.value) < finishedQuantity) {
                const quantity = gridRef.getcellvalue(rowArgs.rowindex, "total_quantity");
                rowArgs.value = quantity;
            }
            const stock = gridRef.getcellvalue(rowArgs.rowindex, "stock");
            let quantity = Number(rowArgs.value);
            if (Number(stock) < Number(quantity)) {
                gridRef.setcellvalue(
                    rowArgs.rowindex,
                    "back_order_quantity",
                    Number(quantity) - Number(stock)
                );
                gridRef.setcellvalue(rowArgs.rowindex, "quantity", Number(stock));
                quantity = Number(stock);
            } else {
                gridRef.setcellvalue(rowArgs.rowindex, "quantity", rowArgs.value);

                if (Number(rowArgs.value) <= Number(stock)) {
                    gridRef.setcellvalue(rowArgs.rowindex, "back_order_quantity", 0);
                }
            }

            const weightPerUnit = gridRef.getcellvalue(
                rowArgs.rowindex,
                "unit_weight"
            );
            const totalWeight = quantity * weightPerUnit;
            gridRef.setcellvalue(rowArgs.rowindex, "weight", totalWeight);

            setInventoryLocations(
                rowArgs.rowindex,
                quantity,
                rowArgs.row.inventories
            );
            const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "price");
            const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_tax");
            setTotalAmount(rowArgs.rowindex, quantity, singlePrice, isTax);
        }
        if (rowArgs.datafield == "is_tax") {
            const singlePrice = gridRef.getcellvalue(rowArgs.rowindex, "price");
            const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
            setTotalAmount(rowArgs.rowindex, quantity, singlePrice, rowArgs.value);
        }
        if (rowArgs.datafield == "price") {
            let finalPrice = Number(rowArgs.value);
            const quantity = gridRef.getcellvalue(rowArgs.rowindex, "quantity");
            const isTax = gridRef.getcellvalue(rowArgs.rowindex, "is_tax");

            gridRef.setcellvalue(rowArgs.rowindex, "price", finalPrice);
            setTotalAmount(rowArgs.rowindex, quantity, finalPrice, isTax);
        }

        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm(true);
    };

    const setInventoryLocations = (index, quantity, locations) => {
        const inventoryLocation = [];
        if (locations.length > 0) {
            locations.map(function (item, index) {
                const availableItem = Number(item.available);
                const locationQuantity =
                    availableItem - quantity > 0 ? quantity : availableItem;
                if (quantity > 0) {
                    inventoryLocation.push({
                        location_id: item.location_id,
                        name: item.name,
                        quantity: locationQuantity,
                    });
                }
                quantity = quantity - locationQuantity;
            });
        } else {
            quantity = 0;
        }

        gridRef.setcellvalue(index, "inventory_location", inventoryLocation);
    };

    const setTotalAmount = (index, quantity, singlePrice, isVat) => {
        const back_order_quantity = gridRef.getcellvalue(
            index,
            "back_order_quantity"
        );
        quantity = quantity + back_order_quantity;
        const totalPrice = quantity * singlePrice;
        const itemDiscount = gridRef.getcellvalue(index, "item_discount");
        let discountAmount = 0;
        if (itemDiscount) {
            const itemDiscountData = itemDiscount[0];
            const discountValue = itemDiscountData ? itemDiscountData.value : 0;
            if (itemDiscountData?.discount_type == 2) {
                discountAmount = totalPrice * (discountValue / 100);
            } else {
                discountAmount = discountValue;
            }
        }

        const taxAmount = isVat ? (totalPrice - discountAmount) * 0.2 : 0;
        gridRef.setcellvalue(index, "discount", discountAmount);
        gridRef.setcellvalue(index, "total_price", totalPrice);
        gridRef.setcellvalue(index, "tax_amount", taxAmount);
        gridRef.setcellvalue(
            index,
            "gross_amount",
            taxAmount + totalPrice - discountAmount
        );
    };

    const setDataToHookForm = (isTrigger = true) => {
        // Retrieve the current value of 'ordered_products' from the form
        let orderData = [...getValues('ordered_products')];

        // Fetch grid information
        let taxTotal = 0;
        let itemDiscount = 0;

        // Iterate over grid rows to update orderData
        gridRef.getrows().forEach((rowData) => {

            // Find the item index in orderData
            let itemIndex = orderData.findIndex((item) => item.variant == rowData.variant);

            if (itemIndex >= 0) {
                // Update existing item
                orderData[itemIndex] = rowData;
            }else {
                // Add new item
                orderData.push(rowData);
            }
        });

        // Calculate taxTotal and itemDiscount
        orderData.forEach((item) => {
            taxTotal += item.tax_amount;
            if (item.item_discount && item.item_discount.length > 0) {
                itemDiscount += item.item_discount[0].value;
            }
        });

        // Update the form value for 'tax'
        if (taxTotal || getValues("is_vat")) {
            const shippingTax = shippingTaxAmount(getValues);
            setValue("tax", {
                is_charge: true,
                rate_name: "Vat",
                percentage: taxTotal + shippingTax,
            });
        } else {
            setValue("tax", "");
        }

        // Update the form value for 'ordered_products'
        if (isTrigger) {
            setValue("ordered_products", orderData);
        }

        onChangeDialog(false);
    };

    const cellClickFunction = (arg) => {
        if (arg.datafield === "location") {
            const rowData = arg.row.bounddata;
            const data = {
                variant_id: rowData.variant,
                sku: rowData.sku,
                inventories: rowData.inventories,
                inventory_location: rowData.inventory_location,
            };
            dispatch(setInventoryDialog({props: {isOpen: true}, data: data}));
        }

        if (arg.datafield === "product_name") {
            const rowData = arg.row.bounddata;
            const data = {
                variant_id: rowData.variant,
                sku: rowData.sku,
                item_discount: rowData.item_discount,
                total_price: rowData.total_price.toFixed(2),
            };
            dispatch(
                setOrderItemDiscountDialog({props: {isOpen: true}, data: data})
            );
        }
        
        if (arg.datafield === "finished_quantity") {
            const rowData = arg.row.bounddata;
            if (rowData.finished_quantity < 1) {
                gridRef.deleterow(rowData.uid);
                let orderData = getValues('ordered_products').filter((item) => item.variant != rowData.variant);
                setValue('ordered_products', orderData);
                trigger('ordered_products');
                setDataToHookForm(false);
            }
        }
    };

    const searchFilter = (value) => {
        const filtergroup = new jqx.filter();
        const filter_or_operator = 1;
        const filtercondition = "starts_with";
        var filter2 = filtergroup.createfilter(
            "stringfilter",
            value,
            filtercondition
        );
        filtergroup.addfilter(filter_or_operator, filter2);
        gridRef.addfilter("sku", filtergroup);
        gridRef.applyfilters();
    };

    return (
        <Fragment>
            <div style={{width: "100%"}}>
                {getValues("ordered_products").length > 0 && (
                    <Card>
                        <CardBody>
                            <div style={{marginBottom: 10}}>
                                <Row>
                                    <Col md="12 input-with-icon ">
                                        <div className="float-right">
                                            <FormGroup className="m-0">
                                                <i className="fa fa-search"></i>
                                                <input
                                                    name="search"
                                                    type="text"
                                                    placeholder="Search by SKU"
                                                    onChange={(event) => {
                                                        searchFilter(event.target.value);
                                                    }}
                                                    className={`form-control float-right`}
                                                />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <JqxTable
                                key={tableKey}
                                deleteAll
                                rowsheight={80}
                                datafields={datafields}
                                columns={columns}
                                // data={filteredData}
                                // data={tableData}
                                data={getValues("ordered_products")}
                                myGrid={gridRef}
                                getJqxRef={getJqxRef}
                                autoheight={tableData.length < 7}
                                // autoheight={true}
                                isDelete={false}
                                scrollerbar
                                // filterable={true}
                                height="500"
                                onCellendedit={cellEdit}
                                disableCreateRow
                                cellClickDataField={[
                                    "location",
                                    "product_name",
                                    "finished_quantity",
                                ]}
                                onDelete={onDelete}
                                cellClickFunction={cellClickFunction}
                                currency={getValues("currency").symbol}
                                searchValue={searchValue}
                            />
                        </CardBody>
                    </Card>
                )}
            </div>
            <InventoryLocation
                orderGetValues={getValues}
                orderSetValue={setValue}
                orderTrigger={trigger}
                onChangeDialog={onChangeDialog}
            ></InventoryLocation>
            <OrderItemDiscount
                orderGetValues={getValues}
                orderSetValue={setValue}
                orderTrigger={trigger}
                onChangeDialog={onChangeDialog}
            ></OrderItemDiscount>
        </Fragment>
    );
};

function areEqual(prevProps, nextProps) {
    const prev = {
        productChange: prevProps.isProductValueChange,
        currencyChange: prevProps.currencyChange,
        isTax: prevProps.isTax,
    };
    const next = {
        productChange: nextProps.isProductValueChange,
        currencyChange: nextProps.currencyChange,
        isTax: nextProps.isTax,
    };
    return JSON.stringify(prev) == JSON.stringify(next);
}

export default React.memo(SaleOrderProductJqx, areEqual);
