import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import { addBinLocationTypeApi, deleteBinLocationTypeApi, getAllTypeDataApi, retrieveBinLocationTypeApi, updateBinLocationTypeApi } from "../../../services/manage-product/setting/binLocationTypeService";

// export const getBinLocationTypeData = createAsyncThunk('binLocationType/getTypeData', async (filters, {dispatch}) => {
//     const response = await getBinLocationTypeDataApi(filters);
//     return response.response;
// });

export const getAllBinLocationType = createAsyncThunk('binLocationType/getAllTagData', async (filters, {dispatch}) => {
    const response = await getAllTypeDataApi(filters);
    return response.response;
});

export const editBinLocationType = createAsyncThunk('binLocationType/edit', async (id) => {
	const response = await retrieveBinLocationTypeApi(id);
	return response.response;
});

export const addBinLocationType = createAsyncThunk('addBinLocationType', async (item, {dispatch, getState}) => {
    try {
        const formData = new FormData();
        formData.append('myFile', item.image);
        formData.append('title', item.title);
        formData.append('capacity', item.capacity);
        formData.append('width', item.width);
        formData.append('length', item.length);
        formData.append('height', item.height);
       
        const response = await addBinLocationTypeApi(formData);
        const data = await response.response;
        dispatch(getAllBinLocationType());
        if (response.response.errors) {
            toast.error("The title has already been taken!");
        }else{
            toast.success("Create tag setting successfully");
        }
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});



export const updateBinLocationType = createAsyncThunk('updateBinLocationType', async (item, {
    dispatch,
    getStatus
}) => {
    try {

        const formData = new FormData();
        formData.append('myFile', item.image_file?item.image_file:'');
        formData.append('title', item.title);
        formData.append('capacity', item.capacity);
        formData.append('width', item.width);
        formData.append('length', item.length);
        formData.append('height', item.height);
        formData.append('imageURL', item.imageURL);

        const response = await updateBinLocationTypeApi(formData, item.id);
        const data = await response.response;
        dispatch(getAllBinLocationType());
        toast.success("Update tag setting successfully");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteBinLocationType = createAsyncThunk('binLocationType/delete', async (id, {
    dispatch,
    getStatus
}) => {
    try {
        const response = await deleteBinLocationTypeApi(id);
        const data = await response.response;
        dispatch(getAllBinLocationType());
        toast.success("Deleted tag Setting successfully");
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const binLocationTypeAdapter = createEntityAdapter({});

export const {
    selectAll: selectBinLocationType,
    selectById: selectBinLocationTypeById
} = binLocationTypeAdapter.getSelectors(
    state => state.product.binLocationType
);

const binLocationTypeSlice = createSlice({
    name: 'binLocationType',
    initialState: binLocationTypeAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'binLocationType',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        binLocationType:null,
    }),
    reducers: {
        selectProductStyleSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: event => ({payload: event.target.value || ''})
        },
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'binLocationType',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            };
        },

        setBinLocationTypeData: (state, action) => {
            state.binLocationType = action.payload
        },
    },

    extraReducers: {
        [getAllBinLocationType.fulfilled]: binLocationTypeAdapter.setAll
    }
});

export const {
    selectProductStyleSearchText,
    openNewDialog,
    closeNewDialog,
    openEditDialog,
    closeEditDialog,
    setBinLocationTypeData
} = binLocationTypeSlice.actions;

export default binLocationTypeSlice.reducer;