import API from "../../../utils/api";


export const getCloseReasonsApi = async (filters) => {
    const response = await API.get('order/close-reason', { params: filters });
    return { response };
};

export const addCloseReasonApi = async location => {
    const response = await API.post('order/close-reason', location);
    return { response };
}

export const updateCloseReasonApi = async (location, id) => {
    const response = await API.put(`order/close-reason/${id}`, location);
    return { response };
};

export const deleteCloseReasonApi = async (id) => {
    const response = await API.delete(`order/close-reason/${id}`);
    return { response };
};
