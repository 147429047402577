import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getCloseReasonsApi,
  addCloseReasonApi,
  updateCloseReasonApi,
  deleteCloseReasonApi,
} from "../../../../services/manage-order/setting/closeReasonService";
import { toast } from "react-toastify";

export const getOrderCloseReasonSettings = createAsyncThunk(
  "orderSettings/getOrderCloseReasonSettingData",
  async (_, { dispatch }) => {
    const response = await getCloseReasonsApi();
    return response.response.data;
  }
);
export const storeCloseReason = createAsyncThunk(
  "orderSettings/storeCloseReasonData",
  async (data, { dispatch }) => {
    const response = await addCloseReasonApi(data);
    const res = response.response;
    dispatch(getOrderCloseReasonSettings());
    toast.success("Added Close Reason successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const updateCloseReason = createAsyncThunk(
    "orderSettings/updateCloseReasonData",
    async (closeReason, { dispatch, getStatus }) => {
      try {
        const response = await updateCloseReasonApi(closeReason, closeReason.id);
        const data = await response.response;
        dispatch(getOrderCloseReasonSettings());
        toast.success("Close Reason updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return data;
      } catch (e) {
        console.log("Error updating: " + e.message);
        return Promise.reject(e);
      }
    }
  );

export const deleteCloseReason = createAsyncThunk(
  "orderSettings/deleteCloseReasonData",
  async (id, { dispatch }) => {
    const response = await deleteCloseReasonApi(id);
    const res = response.response;
    dispatch(getOrderCloseReasonSettings());
    toast.success("Deleted Close Reason successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

const closeReasonSettingAdapter = createEntityAdapter();
const closeReasonSettingSlice = createSlice({
  name: "closeReasonSetting",
  initialState: closeReasonSettingAdapter.getInitialState({
    addDialog: {
      component: "closeReasonSetting",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "closeReasonSetting",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getOrderCloseReasonSettings.fulfilled]: closeReasonSettingAdapter.setAll,
  },
});
export const { openNewDialog, openEditDialog, closeEditDialog } =
  closeReasonSettingSlice.actions;
export default closeReasonSettingSlice.reducer;
