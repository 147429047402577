import React, {useState} from 'react';
import {Card, CardBody, Col, FormGroup, Label, Row, Table} from "reactstrap";
import AsyncSelect from "react-select/async";
import ReactPaginate from "react-paginate";
import AdjustmentReplace from "./replace";
import AdjustmentTransfer from "./transfer";
import BinTransferModal from "./binTransferModal";


function AdjustmentBinDetails(props) {
    const {
        inventoryState,
        append,
        errors,
        remove,
        register,
        trigger,
        setValue,
        getValues,
        fields,
        getPackCalculation,
        binTypePromiseOption,
        getVariantBinLocationByLocation
    } = props;
    const [timer, setTimer] = useState(null);
    const handleCheckBin = ( item) => {
      const  indexToRemove = findBinIndex(item);
        if(indexToRemove == -1) {
            append({
                bin: {value: item.bin_number, label: item.bin_number},
                quantity:  '',
                old_quantity: item.available,
                bin_transfer: []
            });
            trigger('findIndex');
        }

    }

    const findBinIndex = (bin) => {
        const findIndex = getValues('inventories').findIndex(item => item.bin.label === bin.bin_number);
        return findIndex;
    }
    const pageChange = (e) => {
        getVariantBinLocationByLocation((e.selected + 1))
    }
    return (
        <Card>
            {inventoryState.variant.bin_variants && <CardBody>
                <Row>
                    <Col md={{offset: 8, size: 4}}>
                        <input className='form-control' placeholder="Search bin number"  onChange={(e)=>{
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                                getVariantBinLocationByLocation(1,e.target.value)
                            }, 500)
                            setTimer(newTimer)
                        }}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                   <Col md={12}>
                       <Table>
                           <thead className="thead-light">
                               <tr>
                                   <th style={{width: getValues('type')?.id==3? "50%":"25%"}}>Bin</th>
                                   <th style={{width:  getValues('type')?.id==3?"25%":"15%"}}>Qty</th>
                                   {getValues('type')?.id==3?<th style={{width: "25%"}}>R.P Qty</th>: <>
                                       <th style={{width: "50%"}}>Allocated BIN</th>
                                       <th style={{width: "10%"}}></th>
                                   </>}
                               </tr>
                               </thead>
                           <tbody>
                               {inventoryState.variant.bin_variants.data.map((item, i) => (<tr key={i}>
                                   <td>{item.bin_number}</td>
                                   <td>{item.available}</td>
                                   {getValues('type')?.id==3?   <td><AdjustmentReplace
                                       item={item}
                                       index={findBinIndex(item)}
                                       handleCheckBin={handleCheckBin}
                                       errors={errors}
                                       register={register}
                                       setValue={setValue}
                                       getValues={getValues}
                                       trigger={trigger}
                                       getPackCalculation={getPackCalculation}
                                   /></td> : <AdjustmentTransfer  item={item}    getValues={getValues}  inventoryIndex={findBinIndex(item)} index={i} ></AdjustmentTransfer>}
                               </tr>))}
                           </tbody>
                       </Table>
                   </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{display: "inline-flex", float: "right"}}>
                            <div>
                                <ReactPaginate
                                    className="pagination"
                                    activeClassName="pagination-active"
                                    key={getValues('type')?.id}
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={pageChange}
                                    pageRangeDisplayed={5}
                                    pageCount={inventoryState.variant.bin_variants.pagination.count}
                                    previousLabel="< previous"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>}
            <BinTransferModal
                inventoryState={inventoryState}
                adjustmentAppend={append}
                adjustmentGetValues={getValues}
                adjustmentSetValue={setValue}
                adjustmentTrigger={trigger}
                findBinIndex={findBinIndex}
                binTypePromiseOption={binTypePromiseOption}
            >
            </BinTransferModal>
        </Card>
    );
}

export default AdjustmentBinDetails;