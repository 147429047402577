import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addPaymentMethodApi,
  deletePaymentMethodApi,
  getPaymentMethodsApi,
  updatePaymentMethodApi,
} from "../../../../services/manage-order/setting/paymentMethodService";
import { toast } from "react-toastify";

export const getOrderPaymentMethodSettings = createAsyncThunk(
  "orderSettings/getOrderPaymentMethodSettingData",
  async (_, { dispatch }) => {
    const response = await getPaymentMethodsApi();
    return response.response;
  }
);
export const storePaymentMethod = createAsyncThunk(
  "orderSettings/storePaymentMethodData",
  async (data, { dispatch }) => {
    const response = await addPaymentMethodApi(data);
    const res = response.response;
    dispatch(getOrderPaymentMethodSettings());
    toast.success("Added Payment Method successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

export const updatePaymentMethod = createAsyncThunk(
  "orderSettings/updatePaymentMethodData",
  async (paymentMethod, { dispatch, getStatus }) => {
    try {
      const response = await updatePaymentMethodApi(
        paymentMethod,
        paymentMethod.id
      );
      const data = await response.response;
      dispatch(getOrderPaymentMethodSettings());
      toast.success("Payment Method updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      console.log("Error updating: " + e.message);
      return Promise.reject(e);
    }
  }
);

export const deletePaymentMethod = createAsyncThunk(
  "orderSettings/deletePaymentMethodData",
  async (id, { dispatch }) => {
    const response = await deletePaymentMethodApi(id);
    const res = response.response;
    dispatch(getOrderPaymentMethodSettings());
    toast.success("Deleted Payment Method successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return res;
  }
);

const paymentMethodSettingAdapter = createEntityAdapter();
const paymentMethodSettingSlice = createSlice({
  name: "paymentMethodSetting",
  initialState: paymentMethodSettingAdapter.getInitialState({
    addDialog: {
      component: "paymentMethodSetting",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "paymentMethodSetting",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getOrderPaymentMethodSettings.fulfilled]:
      paymentMethodSettingAdapter.setAll,
  },
});
export const { openNewDialog, openEditDialog, closeEditDialog } =
  paymentMethodSettingSlice.actions;
export default paymentMethodSettingSlice.reducer;
