import React, { Fragment } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

const PaymentMethodSettingForm = (props) => {
  const { errors, register } = props;

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label className="col-form-label">Payment Method Name</Label>
            <input
              type="text"
              {...register("name")}
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.name?.message}</div>
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PaymentMethodSettingForm;
