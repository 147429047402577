import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import sellerListData, {getAllSellerList, getSellerList} from "../store/sellerListSlice";
import {useHistory} from "react-router-dom";


const dataMenu = [
    {
        type: 0,
        text: 'Seller',
        link: '/seller'
    },
    {
        type: 1,
        text: 'Seller List',
        link: ''
    },
];



const SellerList = () =>{


    const tableColumns = [
        {
            name: 'Seller Name',
            selector: row => row.name,
            sortable: true,
            center: false,
        },
        {
            name: 'Shop Name',
            selector: row => row.shop_name,
            sortable: true,
            center: false,
        },
        {
            name: 'Publish',
            selector: row => row.published,
            sortable: true,
            center: false,
        },
        {
            name: 'Not Sent',
            selector: row => row.not_sent,
            sortable: true,
            center: false,
        },
        {
            name: 'Sent',
            selector: row => row.sent,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: (row) => <><a href="#" onClick={() => viewSeller(row)}><i className="fa fa-eye"
                                                                                style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(80,126,83)'}}></i></a></>,
            sortable: true,
            center: false,
        },
    ];


    const sellerList = useSelector(getAllSellerList);
    const sellerListSlice = useSelector(({seller})=>seller.sellerListData);
    let history = useHistory();
    const dispatch = useDispatch();
    const [page,setPage]=useState(0);


    useEffect(() => {
        dispatch(getSellerList())
    },[dispatch]);


    const viewSeller = (row) => {
        history.push(`/seller/seller-product-list/${row.id}`)
    }



    return (
        <Fragment>
            <>
                <Breadcrumb data={dataMenu} title="Seller List"/>
                <Container fluid={true}>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>

                            </Row>
                            <Row>
                                    <Col className="mt-4" sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive product-table">
                                                    <CommonDataTable
                                                        noAction
                                                        headerColumns={tableColumns}
                                                        gridData={sellerList}
                                                        paginationTotalRows={sellerListSlice.sellerListDataTableRow}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        paginationPerPage={10}
                                                        paginationServer
                                                        onChangePage={page => setPage(page)}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                            </Row>
                        </div>
                    </div>
                </Container>

            </>
        </Fragment>
    );
}

export default SellerList;