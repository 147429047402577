import React from "react";

import 'suneditor/dist/css/suneditor.min.css';
import SunEditor ,{ buttonList }from "suneditor-react";





function SunRichEditor(props) {
    const {defaultValue,onChange,height} = props;
    return (

        <SunEditor
            setDefaultStyle="font-family: Arial;font-size:12px;"
            defaultValue={defaultValue}
            onChange={onChange}
            setOptions={{
            height: height,
            buttonList: [['bold','font','fontSize','underline','list', 'align','fontColor','codeView']]
        }} />

    );
}
// function areEqual(prevProps, nextProps) {
//     return JSON.stringify(prevProps) == JSON.stringify(nextProps);
//     /*
//     return true if passing nextProps to render would return
//     the same result as passing prevProps to render,
//     otherwise return false
//     */
// }
export default SunRichEditor;