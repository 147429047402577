import React, {Fragment, useState} from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import {optionDefaultValue} from "../../../../utils/common";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const DraftOrderAddressForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions,
        showHs=false,
        formText,
        countries,
        addressArray,
        add,
        setAddressData,
        type
    } = props;

    const country = getValues('country')?getValues('country'): '';
    const [dialCode, setDialCode] = useState(optionDefaultValue(countries,country)?.dial_code);

    return (
        <Fragment>
            <Row>
                <Col md={4}>
                    {addressArray && <Row style={{marginBottom: '20px',marginTop: '10px'}}>
                        <Col sm={12}>
                            <Select
                                defaultValue={add?add:""}
                                placeholder="Default dresses"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                className="select2-filter"
                                cacheOptions
                                // styles={customStyles}
                                options={addressArray}
                                onChange={(e) => {
                                    setAddressData(e)

                                }}
                                components={{
                                    IndicatorSeparator: () => null
                                }}

                            />
                        </Col>
                    </Row>}
                </Col>
                <Col md={8}>

                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">First Name</Label>
                        <input name="first_name" type="text" {...register('first_name')} className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.first_name?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">Last Name</Label>
                        <input name="last_name" type="text" {...register('last_name')} className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.last_name?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">Company Name</Label>
                        <input name="company" type="text" {...register('company')} className={`form-control ${errors.company ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.company?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">Address Line 1</Label>
                        <input name="address_1" type="text" {...register('address1')} className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.address1?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">Address Line 2</Label>
                        <input name="address_2" type="text" {...register('address2')} className={`form-control ${errors.address2 ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.address2?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label className="col-form-label">City</Label>
                        <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.city?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup className="mt-2">
                        <Label className="form-label">Country</Label>
                        <Select placeholder="Country Name" name="country" getOptionLabel={(option) => option.name}
                                value={optionDefaultValue(countries,getValues('country'))}
                                getOptionValue={(option) => option.id} {...register("country")}
                                onChange={(e) => {
                                    console.log(e)
                                    setValue('country', e.name);
                                    setDialCode(e.dial_code)
                                    trigger('country');
                                }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                options={countries}/>
                        <div className="invalid-feedback">{errors.country?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Province</Label>
                        <input name="province" type="text" {...register('province')} className={`form-control ${errors.province ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.province?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Postal code / Zip</Label>
                        <input name="zip" type="text" {...register('zip')} className={`form-control ${errors.zip ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.zip?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <input name="type" type="hidden" {...register('type')} value={type}/>
                    {/*<FormGroup>*/}
                    {/*    <Label className="col-form-label">Phone Number</Label>*/}
                    {/*    <input name="first_name" type="text" {...register('phone_number')} className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}/>*/}
                    {/*    <div className="invalid-feedback">{errors.phone_number?.message}</div>*/}
                    {/*</FormGroup>*/}
                    <Label className="form-label">Phone Number</Label>
                    <FormGroup className="input-group">
                        <span className="input-group-text">{dialCode}</span>
                        <input name="phone_number" type="text"
                               className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                               placeholder="Phone Number" {...register('phone')}  />
                        <div className="invalid-feedback">{errors.phone?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );

}

export default DraftOrderAddressForm;