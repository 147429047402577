import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import dragula from "react-dragula";
import StatusList from "./task";
import { updateSalesOrderStatus } from "../../../store/salesOrderStatusSlice";

const StatusContainer = ({ statuses }) => {
  const containerRefs = useRef([]);
  const [loading, isSetLoading] = useState(false);
  const { status, ordersByStatus } = statuses;
  const dispatch = useDispatch();

  useEffect(() => {
    const drake = dragula(containerRefs.current);

    drake.on("drop", (el, target, source, sibling) => {
      handleDrop(el, target, source, sibling);
    });

    drake.on("dragend", (el) => {
      const target = el.parentElement;
      const source = drake.containers.find((container) => container !== target);

      if (target && source) {
        handleDrop(el, target, source);
      }
    });

    return () => {
      drake.destroy();
    };
  }, [dispatch]);

  const handleDrop = (el, target, source, sibling) => {
    const orderId = el.getAttribute("data-order-id");
    const newStatusId = target.getAttribute("data-status-id");

    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        console.log("Status updated:", res.payload);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  return (
    <Fragment>
      <Row>
        {status.map(
          (statusItem, index) =>
            index > 0 && (
              <Col sm={2} key={statusItem.id}>
                <Card>
                  <CardHeader style={{ padding: "15px" }}>
                    <h6 className="text-center">
                      {statusItem.name} (
                      {ordersByStatus[statusItem.id]?.length || 0})
                    </h6>
                  </CardHeader>
                  <Scrollbars style={{ height: 600 }}>
                    <CardBody>
                      <div
                        ref={(ref) => (containerRefs.current[index] = ref)}
                        data-status-id={statusItem.id}
                        style={{ minHeight: 500 }}
                      >
                        <StatusList
                          orders={ordersByStatus[statusItem.id]}
                          orderIdKey="id"
                          statusID={statusItem.id}
                        />
                      </div>
                    </CardBody>
                  </Scrollbars>
                </Card>
              </Col>
            )
        )}
      </Row>
    </Fragment>
  );
};

export default StatusContainer;
