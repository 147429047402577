import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table} from "reactstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
    mapMainSearchBinAutoSuggestion,
    mapSearchAutoSuggestion,
    mapSearchBinAutoSuggestion
} from "../../../../../utils/mapper";
import {inventoryBinApi} from "../../../../../services/manage-product/productService";
import {useDispatch} from "react-redux";
import {addProduct, closeNewDialog} from "../../../store/prodcutSlice";
import {
    getSettings, getVariantBinLocations,
    setInventorySettings,
    setVariantData,
    updateInventoryAdjustment
} from "../../../store/inventorySlice";
import {packCalculation} from "../../settings/calculation";
import Switch from "react-switch";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import {useHistory} from "react-router-dom";
import AdjustmentAdd from "./add";
import AdjustmentReplace from "./replace";
import AdjustmentBinDetails from "./adjustmentBinDetails";


function AdjustmentForm(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const [buttonDisable, setButtonDisable] = useState(false);
    const {
        inventoryState,
        errors,
        register,
        append,
        fields,
        handleSubmit,
        trigger,
        setValue,
        getValues,
        remove,
        variantData,
        resetData,
        defaultValues
    } = props;
    const binTypePromiseOption = (inputValue) => {
        return new Promise((resolve) => {
            const result = (getValues('inventories')).map(obj => obj.bin?.label).join(',');
            const data = {
                value: inputValue,
                omit: result,
                location_id:getValues('location_id'),
                inventory_variant:getValues('inventory_variant_id')
            }
            setTimeout(() => {
                resolve(mapMainSearchBinAutoSuggestion(inventoryBinApi(data)))
            });
        });
    }

    const onSubmit = (data) => {
        const submitValue = window.event.submitter.value
        setButtonDisable(true);
        let inventoryVariant = [];
        if ((data.pack_inventory).length > 0) {
            inventoryState.variant.size.map((item, i) => {
                inventoryVariant.push(data.pack_inventory[2][item.id]);
            });
            inventoryVariant.push({
                variant_id: inventoryState.variant.pack.variant_id,
                quantity: getValues('pack_quantity')
            });

        }

        const adjustmentData = {
            variant: data.variant?.id,
            type: data.type?.id,
            reason: data.reason?.id,
            no_of_single_pack:data.no_of_single_pack,
            location_id: data.location_id,
            inventories: data.is_bin_update ? data.inventories.filter(function (item){
                return item.quantity !=='' && item.quantity!=item.old_quantity;
            }).map(obj => {
                return {bin: obj.bin.label, quantity: obj.quantity,bin_transfer:obj.bin_transfer?obj.bin_transfer.map(suObj => {
                        return {bin: suObj.bin.label, quantity: suObj.quantity};

                    }):[]};

            }) : [],
            inventory_variants: inventoryVariant,
            conditions: data.condition ? data.condition.map(obj => obj.id) : [],
            no_of_single_pack: data.no_of_single_pack
        }
        dispatch(updateInventoryAdjustment({...adjustmentData})).then(res => {
            setButtonDisable(false);
            if (submitValue == 1) {
                resetData(defaultValues,'','')
                // history.push({
                //     pathname: '/product/adjustment-Inventory',
                // })
            } else {
                const defaultData = {
                    no_of_single_pack: getValues('no_of_single_pack'),
                    is_bin_update: getValues('is_bin_update')
                };
                variantData(defaultData);
            }
        });
    }
    const getPackCalculation = (isSingle,setDefault=false) => {

        if (inventoryState.variant.pack) {

            const isPack= inventoryState.variant.selling_type==2?true:false;
            if(setDefault){
                setValue('no_of_single_pack', isPack?0:2);
            }
            const enteredQuantity = getValues('inventories').reduce((sum, product) => sum + (Number(product.quantity?product.quantity:product.old_quantity) - Number(product.old_quantity)), 0);
            const passValues=!isSingle?getValues('pack_quantity'):(getValues('no_of_single_pack')??0);
            const data = packCalculation((inventoryState.variant.available_quantity + enteredQuantity), getValues('size_id'), inventoryState.variant.size, passValues, isPack,isSingle);
            setValue('pack_inventory', data.variants);
            setValue('pack_quantity', data.pack);
            setValue('total_pack', data.totalPack);
            setValue('no_of_single_pack', data.noOfSingle)
            trigger('pack_inventory');
        }
    }

    useEffect(() => {
        getPackCalculation(false);
    }, [inventoryState.variant]);

    const typeHandelChange = (e) => {
        if(e) {
            if (e.id == 2) {
                setValue('inventories', [{bin: '', quantity: '', old_quantity: 0}]);
            }
            if (e.id == 3 || e.id == 1) {
                setValue('inventories', []);
                getVariantBinLocationByLocation();
            }
        }
        setValue('type', e);
        trigger('type');
    }

    const getVariantBinLocationByLocation = (pageNumber=1,name="") => {
        const paramsData = {
            'inventory_variant_id': getValues('inventory_variant_id'),
            'location_id': getValues('location_id'),
            'page': pageNumber,
            'name':name
        }
        dispatch(getVariantBinLocations(paramsData)).then(res => {
            dispatch(setVariantData({...inventoryState.variant, bin_variants: res.payload}));
        });
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardBody>
                    {!inventoryState.settings.is_stock_count_mode && <>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label">Stock Type</Label>
                                    <Select
                                        placeholder="Select Stock Type"
                                        className={`select2-filter ${errors.type ? 'is-invalid' : ''}`}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={getValues('type')}
                                        onChange={typeHandelChange}
                                        options={inventoryState.settings.types}
                                        isClearable
                                    />
                                    <div className="invalid-feedback">{errors.type?.message}</div>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="form-label">Reason</Label>
                                    <Select
                                        placeholder="Select Stock Reason"
                                        className={`select2-filter ${errors.reason ? 'is-invalid' : ''}`}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={getValues('reason')}
                                        onChange={(e) => {
                                            setValue('reason', e);
                                            trigger('reason');
                                        }}
                                        options={inventoryState.settings.reasons}
                                    />
                                    <div className="invalid-feedback">{errors.reason?.message}</div>
                                </FormGroup>
                            </Col>


                        </Row>
                    </>}
                    {getValues('type') && <Row>
                        <Col md={12}>
                            {(getValues('type')?.id == 2) ? <AdjustmentAdd
                                append={append}
                                fields={fields}
                                getValues={getValues}
                                errors={errors}
                                setValue={setValue}
                                trigger={trigger}
                                register={register}
                                binTypePromiseOption={binTypePromiseOption}
                                getPackCalculation={getPackCalculation}
                                remove={remove}
                            /> : <AdjustmentBinDetails
                                inventoryState={inventoryState}
                                append={append}
                                fields={fields}
                                getValues={getValues}
                                errors={errors}
                                setValue={setValue}
                                trigger={trigger}
                                register={register}
                                binTypePromiseOption={binTypePromiseOption}
                                getPackCalculation={getPackCalculation}
                                getVariantBinLocationByLocation={getVariantBinLocationByLocation}
                                remove={remove}
                            />}
                        </Col>
                    </Row>}
                    {inventoryState.settings.is_stock_count_mode && <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="form-label">Product Condition</Label>
                                <Select
                                    isMulti
                                    placeholder="Select Stock condition"
                                    className={`select2-filter ${errors.condition ? 'is-invalid' : ''}`}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={getValues('condition')}
                                    onChange={(e) => {
                                        setValue('condition', e);
                                        trigger('condition');
                                    }}
                                    options={inventoryState.settings.conditions}
                                />
                                <div className="invalid-feedback">{errors.reason?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col md={12}>

                            {(inventoryState.variant && inventoryState.variant.pack && !inventoryState.settings.is_stock_count_mode) && <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={4}>
                                            <div>
                                                <FormGroup>
                                                    <Label className="form-label">SKU</Label>
                                                    <div>{inventoryState.variant.pack.sku}</div>
                                                </FormGroup>

                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div>
                                                <div>
                                                    <FormGroup>
                                                        <Label className="form-label">Pack</Label>
                                                        <div>{getValues('pack_quantity')}</div>
                                                    </FormGroup>
                                                </div>

                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Pack For Single Pcs</Label>
                                                {inventoryState.variant.selling_type==1 ?   <>
                                                    <input
                                                        value={getValues('no_of_single_pack')}
                                                        onChange={(e) => {

                                                            setValue(`no_of_single_pack`, e.target.value);
                                                            trigger('no_of_single_pack');
                                                            getPackCalculation( true);


                                                        }}
                                                        type="number"
                                                        className={`form-control w-50  ${errors.no_of_single_pack ? 'is-invalid' : ''}`}
                                                        placeholder='qty'/>
                                                    <div className="invalid-feedback"
                                                    >{errors.inventories?.no_of_single_pack?.message}</div>
                                                </>:<><div>{getValues('no_of_single_pack')}</div></>}

                                            </FormGroup>


                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <div className="table-responsive w-100">
                                                <Table>
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th>Type</th>
                                                        {inventoryState.variant.size.map((item, i) => (
                                                            <th key={i}>{item.name}</th>
                                                        ))}
                                                        <th>Total</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {getValues('pack_inventory').map((item, i) => (<tr key={i}>
                                                        <td>{item.type}</td>
                                                        {inventoryState.variant.size.map((sizeItem, sizeIndex) => (
                                                            <td key={sizeIndex}>{item[sizeItem.id].quantity}</td>
                                                        ))}
                                                        <td>{item.total}</td>

                                                    </tr>))}

                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>


                </CardBody>
                <CardBody>
                    {(!getValues('type') || getValues('type')?.id==2 || (getValues('inventories').filter(function (item){
                        return item.quantity !=="" && item.quantity!=item.old_quantity;
                    })).length>0) && <div className="form-row">
                        <Col md={12}>
                            <div className="float-right">
                                <ButtonLoader color="success" value="0" className="mr-2"
                                              type="submit" disabled={buttonDisable}
                                              btntext="Save"></ButtonLoader>
                                <ButtonLoader color="primary" value="1" className="mr-2"
                                              type="submit" disabled={buttonDisable}
                                              btntext="Save & Reset"></ButtonLoader>
                            </div>
                        </Col>
                    </div>}
                </CardBody>
            </Card>

        </Form>
    );
}

export default AdjustmentForm;