import API from "../../utils/api";

export const getSalesOrderInventoryApi = async (filters) => {
  const response = await API.get("order/sales-order/inventory", {
    params: filters,
  });
  return { response };
};

export const downloadSalesOrderInvoicePdfApi = async (filters) => {
  const response = await API.get(`order/sales-order/inventory-download`, {
    "content-type": "application/pdf",
    responseType: "blob",
    params: filters,
  });
  return { response };
};
