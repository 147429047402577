import API from "../utils/api";

export const getCountryListApi = async (sellerId) => {
    const response = await API.get(`shipping-zone/country-list/${sellerId}`);
    return { response };
}

export const getSelectedShippingZoneDataApi = async (id, sellerId) => {
    const response = await API.get(`shipping-zone/shipping-zone/${id}/${sellerId}`);
    return { response };
}

export const addShippingZoneDataApi = async (data, sellerId) => {
    const response = await API.post(`shipping-zone/shipping-zone/${sellerId}`, data);
    return { response };
}

export const updateShippingZoneDataApi = async (data,id) => {
    const response = await API.put(`shipping-zone/shipping-zone/${id}`, data);
    return { response };
}

export const getShippingZoneDataApi = async (data) => {
    const response = await API.get(`shipping-zone/shipping-zone`, {params:data});
    return { response };
}

export const deleteShippingZoneApi = async (id) => {
    const response = await API.delete(`shipping-zone/shipping-zone/${id}`);
    return { response };
}

export const getCurrentSellerIdApi = async (id) => {
    const response = await API.get(`shipping-zone/get-current-seller-id/${id}`);
    return { response };
}
export const getCurrentSellerIdForSellerVatApi = async (id) => {
    const response = await API.get(`seller-vat/get-current-seller-id-seller-vat/${id}`);
    return { response };
}

export const getAllSellersApi = async () => {
    const response = await API.get(`shipping-zone/get-all-sellers`);
    return { response };
}

export const getAllSyncShippingZoneApi = async () => {
    const response = await API.get(`shipping-zone/get-shopify-shipping-zone`);
    return { response };
}