import React, { Fragment, useState } from "react";
import { Row, Col, ListGroup, ListGroupItem, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PreLoader from "../../../share-components/ui/preLoader";

export const SurveyDetails = (props) => {
  const { sellerLeadState, id, VerticleTab, setVerticleTab, formData, buyer } =
    props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let history = useHistory();

  return (
    
    <Fragment>
      {console.log('formData', formData)}
      <h4 className="mb-3">Customer Feedback Survey Details</h4>
      {formData && formData.survey_details ? (
        <>
          <Row>
            <Col xs="6" md="3"><strong>Wanted to Participate in Survey?</strong></Col>
            <Col xs="6" md="3">: {formData.survey_details.is_participate}</Col>
          </Row>
          {formData.survey_details.is_participate == "Yes" ?
            <>
              <Row>
                <Col xs="6" md="3"><strong>Survey Status</strong></Col>
                <Col xs="6" md="3">: {formData.survey_details.survey_status}</Col>
              </Row>
              <Row>
                <Col xs="6" md="3"><strong>Discount Code</strong></Col>
                <Col xs="6" md="3">: {formData.survey_details.discount_code}</Col>
              </Row>
              <Row>
                <Col xs="6" md="3"><strong>Discount Mail Sent At</strong></Col>
                <Col xs="6" md="3">: {formData.survey_details.mail_sent_at}</Col>
              </Row>
            </>
           : null}
           <hr className="mb-4"/>
        </>
      ) : null}
      {formData && formData.survey_answers && formData.survey_answers.length === 0 ? (
        <Row>
          <Col>
            <h5>No data found</h5>
          </Col>
        </Row>
      ) : (
        formData && formData.survey_answers && formData.survey_answers.map((question, index) => (
          <Row key={index} className="mb-4">
            <Col xs="12">
              <h6><strong>{question.question}</strong></h6>
              {question.type === 'checkbox' && (
                <ListGroup>
                  {JSON.parse(question.answers).map((answer, idx) => (
                    <ListGroupItem key={idx} style={{border: 'none', padding: '5px 0'}}>
                      {`${idx + 1}. ${answer}`}
                    </ListGroupItem>
                  ))}
                  {JSON.parse(question.answers).includes('Other') && question.other_answer && (
                    <Row className="mt-2">
                      <Col>
                        <p><strong>Other Answer:</strong> {question.other_answer}</p>
                      </Col>
                    </Row>
                  )}
                </ListGroup>
              )}

              {question.type === 'radio' && (
                <p>{question.answers}</p>
              )}

              {question.type === 'textarea' && (
                <p>{question.answers}</p>
              )}
            </Col>
          </Row>
        ))
      )}
    </Fragment>
  );
};
