import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {
    addBannerImageApi,
    addSellerApi,
    deleteBannerApi,
    deleteSellerBrandsApi,
    downloadAdminCommissionPdfExcelApi,
    emailAdminCommissionExportApi,
    getCommissionsApi,
    getSellerApi,
    getSellerBrandsApi,
    getSellersApi,
    getSellerSettingsApi,
    showSellerApi,
    updateSellerApi,
    updateSellerBrandApi,
    updateSellerImageApi, UpdateSellerDataApi, changeStatusApi
} from "../../../services/seller";
import {newPasswordCredentailsApi} from "../../../services/manage-user/user";

export const getSellers = createAsyncThunk('sellers/getAllSellers', async (data,{dispatch,}) => {
    const response = await getSellersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const getSellerSettings = createAsyncThunk('sellers/getSellerSettings', async () => {
    const response = await getSellerSettingsApi();
    return response.response;
});

export const addSeller = createAsyncThunk('sellers/addSeller', async (seller, { dispatch, getState }) => {
    try {
        const response = await addSellerApi(seller);
        const data = await response.response;
        // dispatch(getUsers());
        toast.success("Seller added successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
export const getSeller = createAsyncThunk('sellers/editSeller', async (id,{dispatch})=>{
    const response = await getSellerApi(id);
    return response.response;
});

export const updateSellerImg = createAsyncThunk('seller/updateImage', async (data,{dispatch}) => {
    const response = await updateSellerImageApi(data,data.get('id'));
    toast.success(`${data.get('type_name')} updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});


export const updateSellerBrand = createAsyncThunk('seller/updateBrand', async (data,{dispatch}) => {
    const response = await updateSellerBrandApi(data,data.get('id'));
    toast.success(`Brand updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const getSellerBrands = createAsyncThunk('sellers/getBrands', async (id,{dispatch})=>{
    const response = await getSellerBrandsApi(id);
    dispatch(setSellerBrands(response.response));
});

export const updateSeller = createAsyncThunk('seller/updateSeller', async (data,{dispatch}) => {
    const response = await updateSellerApi(data.results,data.id);
    toast.success(`Seller updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const deleteSellerBrands = createAsyncThunk('sellers/getBrands', async (data,{dispatch})=>{
    const response = await deleteSellerBrandsApi(data.id,data.brand_id);
    dispatch(setSellerBrands(response.response));
});

export const getCommissions = createAsyncThunk('sellers/getCommissions', async (data,{dispatch,}) => {
    const response = await getCommissionsApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const downloadAdminCommissionExport = createAsyncThunk('sellers/downloadAdminCommissionPdf', async (data,{dispatch,}) => {
    const response = await downloadAdminCommissionPdfExcelApi(data);
    return response.response;
});

export const EmailAdminCommissionExport = createAsyncThunk('sellers/emailAdminCommissionPdf', async (data,{dispatch,}) => {

    try{
        const response = await emailAdminCommissionExportApi(data);
        const callback = await response.response;
        toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return callback;
    }catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const addBannerImage = createAsyncThunk('sellers/addBannerImage', async (imageData, { dispatch, getState }) => {
    try {
        const formData = new FormData();
        (imageData.image).map((item, i) => {
            formData.append(
                'bannerImage[' + i + ']',
                item
            );
        });

        const response = await addBannerImageApi(imageData.id,formData);
        const data = await response.response;
        toast.success("Banner uploaded successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const deleteBannerImage = createAsyncThunk('sellers/deleteBannerImage', async (imageData,{dispatch,getState})=>{
    try{
        const response = await deleteBannerApi(imageData.id,imageData.banner_image_id);
        const data = await response.response;
        toast.success("Banner deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    }catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }

});

export const showSeller = createAsyncThunk('sellers/getSeller', async (id,{dispatch})=>{
    const response = await showSellerApi(id);
    return response.response;
});

export const emailNewPassword = createAsyncThunk('sellers/emailNewPassword', async (id,{dispatch})=>{
    try {
        const response = await newPasswordCredentailsApi(id);
        toast.success("Password reset done successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    }
    catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const UpdateSellerData = createAsyncThunk('seller/UpdateSellerData', async (order, { dispatch, getState }) => {
    try {
        const response = await UpdateSellerDataApi(order);
        const data = await response.response;
        toast.success("Add Seller Successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const changeStatus = createAsyncThunk('sellers/changeStatus', async (id,{dispatch})=>{
    try {
        const response = await changeStatusApi(id);
        toast.success("Status Change done successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return response.response;
    }
    catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

const sellersAdapter = createEntityAdapter({});

export const { selectAll: selectSeller, selectById: selectSellerById } = sellersAdapter.getSelectors(
    state => state.seller.seller
);

const sellerSlice = createSlice({
    name: 'sellers',
    initialState: sellersAdapter.getInitialState({
        id:null,
        sellerTableRow:0,
        searchText: '',
        settings:{
          countries:[]
        },
        sellerBrands:null,
        sellerAgents:null,
        sellerBanners:null,
        data:null,
        commissionData:null,
        brandDialog: {
            component: 'Brand',
            type: 'new',
            props: {
                open: false
            },
            data: {
                brand:null,
                imageFile:null,
                oldId:null
            }
        },
        sellerAllocateDialog: {
            component: 'Seller Allocate',
            type: 'new',
            props: {
                open: false
            },
            data: null
        }
    }),
    reducers: {
        openBrandDialog: (state, action) => {
            state.brandDialog = {
                component: 'Brand',
                type: action.payload?'edit':'new',
                props: {
                    isOpen: true
                },
                data: {
                    brand:action.payload?action.payload.brand:null,
                    collectionId:action.payload?action.payload.collectionId:null,
                    email:action.payload?action.payload.email:null,
                    country:action.payload?action.payload.country:null,
                    city:action.payload?action.payload.city:null,
                    mobileNo:action.payload?action.payload.mobileNo:null,
                    imageFileLogo:action.payload?action.payload.logoImagePath:null,
                    imageFile:action.payload?action.payload.image_path:null,
                    oldId:action.payload?action.payload.id:null
                }
            };
        },
        openSellerAllocateDialog: (state, action) => {
            state.sellerAllocateDialog = {
                component: 'Seller Allocate',
                type:'update',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeBrandDialog: (state, action) => {
            state.brandDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        closeSellerAllocateDialog: (state, action) => {
            state.sellerAllocateDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setSettings: (state, action) => {
            state.settings = action.payload.settings;
            state.id = action.payload.id
        },
        setSellerImages: (state, action) => {
            state.settings.imgTypes = action.payload;
        },
        setSellerBrands: (state, action) => {
            console.log("PayloadTEST",action.payload);
            state.sellerBrands = action.payload;
        },
        setSellerAgents: (state, action) => {
            state.sellerAgents = action.payload;
        },
        setSellerBanners: (state, action) => {
            state.sellerBanners = action.payload;
        },
        setDialCodeValue: (state, action) => {
            state.dialCode = action.payload;
        },
        setTableRowCount: (state, action) => {
            state.sellerTableRow = action.payload;
        },
        setTableDataCommission: (state, action) => {
            state.commissionData = action.payload;
        },
    },
    extraReducers: {
        [getSellers.fulfilled]: sellersAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {openSellerAllocateDialog, closeSellerAllocateDialog, openBrandDialog, closeBrandDialog, setDialCodeValue, setSettings,setSellerImages,setSellerBanners,setSellerBrands,setTableRowCount,setTableDataCommission } =
    sellerSlice.actions;

export default sellerSlice.reducer;
