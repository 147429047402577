import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSalesOrderStatusApi } from "../../../services/manage-order/salesOrderStatusService";
import { updateSalesOrderStatusApi } from "../../../services/manage-order/saleOrderService";

export const getSalesOrderStatus = createAsyncThunk(
  "sales-order/getStatus",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderStatusApi(filters);
    return response.response;
  }
);

export const updateSalesOrderStatus = createAsyncThunk(
  "sales-order/updateStatus",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderStatusApi(orderData, orderData.id);
    toast.success(`Sales Order Status Udated Successfully`);
    return response.response;
  }
);

const salesOrderStatusAdapter = createEntityAdapter({});

export const { selectAll: selectAllStatus, selectById: selectStatusById } =
  salesOrderStatusAdapter.getSelectors((state) => state.order.salesOrderStatus);

const saleOrderStatusSlice = createSlice({
  name: "saleOrderStatus",
  initialState: salesOrderStatusAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      return_reasons: [],
    },
    salesDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesInvoiceDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesFulfillmentDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    salesBillingDialogProps: {
      props: {
        isOpen: false,
      },
      data: {},
    },
    filter: [],
    status: [],
    ordersByStatus: {},
  }),
  reducers: {
    setSalesOrderStatusFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setStatusSettings: (state, action) => {
      state.settings = action.payload;
    },
    setSalesDialog: (state, action) => {
      state.salesDialogProps = action.payload;
    },
    setSalesInvoiceDialog: (state, action) => {
      state.salesInvoiceDialogProps = action.payload;
    },
    setSalesFulfillmentDialog: (state, action) => {
      state.salesFulfillmentDialogProps = action.payload;
    },
    setSalesBillingDialog: (state, action) => {
      state.salesBillingDialogProps = action.payload;
    },
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.selectedOrder = action.payload;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.selectedOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesOrderStatus.fulfilled, (state, action) => {
      state.status = action.payload.status;
      state.ordersByStatus = action.payload.ordersByStatus;
    });
  },
});

export const {
  setTableRowCount,
  setOrderStatus,
  setFromSettings,
  setStatusSettings,
  setSalesDialog,
  setSalesInvoiceDialog,
  setSalesFulfillmentDialog,
  setSalesBillingDialog,
  openModal,
  closeModal,
} = saleOrderStatusSlice.actions;

export default saleOrderStatusSlice.reducer;
