import API from "../../utils/api";


export const getInventoryProductsApi = async (data) => {
    const response = await API.get(`product/inventory`,{
        params: data,
    });
    return { response };
};
export const updateInventoryApi = async (data,id) => {
    const response = await API.put(`product/inventory/`+id,data);
    return { response };
};

export const getInventoryByLocationAPi = async (data) => {
    const response = await API.post(`product/inventory/by-location`,data);
    return { response };
};