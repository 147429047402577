import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Popover,
  PopoverBody,
  Card,
  Label,
  CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setSalesInvoiceDialog,
  getSalesOrderStatus,
} from "../../../store/salesOrderStatusSlice";
import { downloadSalesOrderXeroInvoicePdf } from "../../../store/saleOrderInvoiceSlice";
import {
  downloadSalesOrderInvoicePdf,
  downloadSalesOrderPickingTickeBinPdf,
  downloadSalesOrderPickingTicketPdf,
  showSalesOrder,
} from "../../../store/salesOrderSlice";
import SaleOrderDiscussion from "../../view/content/saleOrderDiscussion";

const SalesInvoiceModal = () => {
  const dispatch = useDispatch();
  const [dropdownStatusOpenArray, setDropdownStatusOpenArray] = useState([]);
  const [statusName, setStatusName] = useState("");
  const [loading, isSetLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [enableDownloadPicking, setEnableDownloadPicking] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const [discussionSection, setDiscussionSection] = useState(false);
  const salesInvoiceDialogProps = useSelector(
    (state) => state.order.saleOrderStatus.salesInvoiceDialogProps
  );

  const order_id = salesInvoiceDialogProps.data.id;

  const handleStatusNameUpdate = (name) => {
    setStatusName(name);
  };

  const processPDFDownload = (name, isCommercial, id, invoice_no) => {
    const data = {
      id: id,
      params: {
        is_commercial: isCommercial,
      },
    };
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processXeroPDFDownload = (name, order_id, id, invoice_no) => {
    const orderId = order_id;
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderXeroInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleModal = () => {
    closeDialog();
    const data = {
      search: search,
    };
    dispatch(getSalesOrderStatus(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  const toggleStatusDropdown = (index) => {
    const newDropdownStatusOpenArray = [...dropdownStatusOpenArray];
    newDropdownStatusOpenArray[index] = !newDropdownStatusOpenArray[index];
    setDropdownStatusOpenArray(newDropdownStatusOpenArray);
  };

  const downloadInvoice = (name, isCommercial, id, invoice_no) => {
    processPDFDownload(name, isCommercial, id, invoice_no);
  };

  const downloadXeroInvoice = (order_id, id, invoice_no) => {
    processXeroPDFDownload("Xero Invoice", order_id, id, invoice_no);
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesInvoiceDialog(obj));
  };

  const processPickigPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesInvoiceDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processPickingBinPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTickeBinPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${salesInvoiceDialogProps.data.order_no}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const downloadPicking = () => {
    processPickigPDFDownload("Picking Ticket");
  };

  const downloadBinPicking = () => {
    processPickingBinPDFDownload("Picking Ticket By Bin");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const togglePopover = (order_id) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [order_id]: !prevState[order_id],
    }));
  };

  useEffect(() => {
    isSetLoading(true);
    const orderId = order_id;
    if (orderId) {
      dispatch(showSalesOrder({ id: orderId })).then((res) => {
        if (!res.error) {
          isSetLoading(false);
          setEnableDownloadPicking(res.payload.sum_back_order_quantity == 0);
        }
      });
    } else {
      isSetLoading(false);
    }
  }, [dispatch, order_id]);

  useEffect(() => {
    if (salesInvoiceDialogProps.data) {
      setStatusName(salesInvoiceDialogProps.data.status || "");
      setDiscussionSection(true);
    }
  }, [salesInvoiceDialogProps.data]);

  return (
    <Modal
      isOpen={salesInvoiceDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      <ModalHeader toggle={toggleModal}>
        <p
          style={{ fontSize: "20px" }}
        >{`Sales Order #${salesInvoiceDialogProps.data.order_no}`}</p>
        <span
          style={{
            fontWeight: "lighter",
            color: "rgb(115, 115, 115)",
            fontSize: "16px",
          }}
        >
          Status :{" "}
          <span
            style={{
              textTransform: "uppercase",
            }}
          >
            {statusName}
          </span>
        </span>
      </ModalHeader>
      <ModalBody>
        {salesInvoiceDialogProps.data && (
          <>
            <Container fluid={true}>
              <div className="d-flex mb-2 px-2">
                {enableDownloadPicking ? (
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={downloadPicking}>
                        Picking Ticket by Style
                      </DropdownItem>
                      <DropdownItem onClick={downloadBinPicking}>
                        Picking Ticket by Bin
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <>
                    <Button
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                      id={`pickingTicketPopover-${order_id}`}
                      disabled
                    >
                      Picking Ticket{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </Button>
                    <Popover
                      placement="bottom"
                      isOpen={popoverOpen[order_id] || false}
                      target={`pickingTicketPopover-${order_id}`}
                      toggle={() => togglePopover(order_id)}
                      trigger="hover"
                    >
                      <PopoverBody>
                        This order has back-order quantity.
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </div>
              <div className="pb-2">
                <span style={{ fontSize: "18px", fontWeight: "600" }}>
                  Download Invoices
                </span>
              </div>
              <div className="d-flex mb-2 px-2">
                {salesInvoiceDialogProps.data.invoices ? (
                  <>
                    {salesInvoiceDialogProps.data.invoices.map(
                      (invoice, index) => (
                        <Dropdown
                          key={invoice.id}
                          isOpen={dropdownStatusOpenArray[index] || false}
                          toggle={() => toggleStatusDropdown(index)}
                        >
                          <DropdownToggle
                            color="success"
                            className="btn btn-sm btn-success"
                            style={{ height: 38, marginRight: 8 }}
                          >
                            {invoice.invoice_no + "  "}
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                downloadInvoice(
                                  "Invoice",
                                  0,
                                  invoice.id,
                                  invoice.invoice_no
                                );
                              }}
                            >
                              Local Invoice
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                downloadInvoice(
                                  "Commercial Invoice",
                                  1,
                                  invoice.id,
                                  invoice.invoice_no
                                );
                              }}
                            >
                              Commercial Invoice
                            </DropdownItem>
                            {invoice.xero_id && (
                              <DropdownItem
                                onClick={() => {
                                  downloadXeroInvoice(
                                    salesInvoiceDialogProps.data.id,
                                    invoice.id,
                                    invoice.invoice_no
                                  );
                                }}
                              >
                                Xero Invoice
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      )
                    )}
                  </>
                ) : (
                  <span>No Invoices Available</span>
                )}
              </div>
              <Row>
                <Col sm="12" className="p-2">
                  <Card
                    style={{
                      border: "1px solid rgb(230, 230, 230)",
                      borderRadius: "8px",
                    }}
                  >
                    <CardBody
                      style={{ paddingTop: "24px", paddingBottom: "24px" }}
                    >
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Discussion
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <SaleOrderDiscussion
                            salesOrderId={salesInvoiceDialogProps.data.id}
                            salesOrderComments={
                              salesInvoiceDialogProps.data.comments
                            }
                            isInputShow={
                              salesInvoiceDialogProps.data.status_id == 2
                                ? true
                                : false
                            }
                            salesOrderStatus={
                              salesInvoiceDialogProps.data.status_id
                            }
                            setStatusName={handleStatusNameUpdate}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SalesInvoiceModal;
