import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Col, Table, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody, Row, Form
} from "reactstrap";
import {handelBinTransferDialog} from "../../../store/inventorySlice";
import * as yup from "yup";
import {findDuplicates, findIndexesByProperty} from "../../../../../utils/common";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AdjustmentAdd from "./add";
import {mapSearchAutoSuggestion, mapSearchBinAutoSuggestion} from "../../../../../utils/mapper";
import {getVariantBinLocationApi, inventoryBinApi} from "../../../../../services/manage-product/productService";
import AsyncSelect from "react-select/async";

const defaultValues = {
    inventories: [{bin: '', quantity: ''}],
    quantity: 0,
    current_bin: '',
    committed_inventories: []

};
yup.addMethod(yup.mixed, "lessQty", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError, parent} = this;
        let isValid = true;
        const foundObject = (parent.committed_inventories).find(obj => obj.bin_number === parent.current_bin?.label);
        const committedQuantity=foundObject?foundObject.quantity:0;
        const   quantity = parent.quantity;


        const totalQty = value.reduce((sum, product) => sum + (product.quantity ? (Number(product.quantity)) : 0), 0);
        if (totalQty > Number(quantity)) {
            isValid = false
        }
        return (
            (isValid) ||
            createError({path, message: errorMessage})
        );
    });
});
const schema = yup.object().shape({
    inventories: yup.array().of(yup.object().shape({
            quantity: yup.number().typeError('this field is required').integer('should be integer').required('this field is required'),
            bin: yup.object().shape({id: yup.string()}).nullable().required('this field is required')
        })
    ).lessQty("should be less than origin or committed qty").test(
        'is-unique',
        'Name must be unique',
        (values, context) => {
            const bins = context.parent.inventories.map(item => (item.bin ? item.bin.label : "")); // Get an array of all bin IDs
            const duplicateBins = findDuplicates(bins);
            let errors = [];
            if (duplicateBins.length > 0) {
                duplicateBins.map((item, index) => {
                    const findIndexes = findIndexesByProperty(bins, item);
                    findIndexes.map((i, ind) => {
                        const errorMessage = `Duplicate bin "${item}" found`;
                        errors.push(new yup.ValidationError(errorMessage, values, `inventories.[${i}].bin`));

                    });
                });
                if (errors.length > 0) {
                    throw new yup.ValidationError(errors);
                }
            }
            return true; // Validation passed
        }
    )

});


function BinTransferModal(props) {
    const dispatch = useDispatch();
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {fields, append, remove} = useFieldArray({name: 'inventories', control})
    const {errors, isDirty} = formState;
    const [bin, setBin] = useState('');


    const {
        size = 'lg',
        adjustmentAppend,
        inventoryState,
        adjustmentGetValues,
        adjustmentSetValue,
        adjustmentTrigger,
        findBinIndex
    } = props;

    const closeModalDialog = () => {
        dispatch(handelBinTransferDialog({props: {isOpen: false}, data: null}));
    }
    const onSubmit = (data) => {
        console.log(data);
        const totalQty = (data.inventories).reduce((sum, product) => sum + (product.quantity ? (Number(product.quantity)) : 0), 0);

        const finIndex=findBinIndex({bin_number:data.current_bin?.label});
        console.log(finIndex,data.current_bin,"bin--data");

        if(finIndex != -1){

            adjustmentSetValue(`inventories.${finIndex}.quantity`,data.quantity - totalQty);
            adjustmentSetValue(`inventories.${finIndex}.old_quantity`,data.quantity);
            adjustmentSetValue(`inventories.${finIndex}.bin_transfer`,data.inventories);
        }else {
            adjustmentAppend({
                bin: data.current_bin,
                quantity: data.quantity - totalQty,
                old_quantity: data.quantity,
                bin_transfer: data.inventories
            })

        }

        adjustmentTrigger('inventories');
        closeModalDialog();
    }

    const adjustmentData = inventoryState.binTransferDialogProps.data;
    const binTypePromiseOption = (inputValue) => {
        return new Promise((resolve) => {
            const result = getValues('current_bin')?.label;
            const data = {
                value: inputValue,
                omit: result
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(inventoryBinApi(data)))
            });
        });
    }

    const mainBinTypePromiseOption = (inputValue) => {
        return new Promise((resolve) => {
            const result = adjustmentData.item.bin_number;
            const paramsData = {
                'inventory_variant_id': adjustmentGetValues('inventory_variant_id'),
                'location_id': adjustmentGetValues('location_id'),
                'page': 1,
                'name': inputValue
            }
            setTimeout(() => {
                resolve(mapSearchBinAutoSuggestion(getVariantBinLocationApi(paramsData)))
            });
        });
    }
    useEffect(() => {
        if (inventoryState.binTransferDialogProps.props.isOpen) {
            const indexData = findBinIndex(adjustmentData.item);
            const resetData = {
                inventories: indexData != -1 ? adjustmentGetValues(`inventories.${indexData}.bin_transfer`) : [{
                    bin: '',
                    quantity: ''
                }],
                quantity: adjustmentData.item.available,
                current_bin: {value: adjustmentData.item.id, label: adjustmentData.item.bin_number},
                committed_inventories: adjustmentGetValues('committed_inventories')
            }
            reset(resetData);
        }
    }, [inventoryState.binTransferDialogProps.props])
    const handelChange = (e) => {

        const indexData = findBinIndex({bin_number: e.value});
        setValue('inventories', indexData != -1 ? adjustmentGetValues(`inventories.${indexData}.bin_transfer`) : [{
            bin: '',
            quantity: ''
        }]);
        setValue('quantity', e.available);
        setValue('current_bin', e);
        trigger('current_bin');
    }
    return (

        <div className="flex flex-col modal-lg">
            <Modal size={size} {...inventoryState.binTransferDialogProps.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    <strong>Bin Transfer</strong>

                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {adjustmentData && <ModalBody>
                        <Row>
                            <Col md={6}>
                                <p className='fw-bold'>{getValues('current_bin')?.label} : {getValues('quantity')}  </p>
                            </Col>
                            <Col md={6}>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions
                                    placeholder="Bin"
                                    className={`select2-filter`}
                                    value={getValues('current_bin')}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    loadOptions={mainBinTypePromiseOption}
                                    onChange={handelChange}

                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <AdjustmentAdd
                                    binKey={getValues('current_bin')?.value}
                                    append={append}
                                    fields={fields}
                                    getValues={getValues}
                                    errors={errors}
                                    setValue={setValue}
                                    trigger={trigger}
                                    register={register}
                                    binTypePromiseOption={binTypePromiseOption}
                                    isPackCalculation={false}
                                    remove={remove}
                                />
                                <div style={{color: "red", textAlign: 'right'}}>{errors?.inventories?.message}</div>
                            </Col>
                        </Row>
                    </ModalBody>}
                    <ModalFooter>
                        <button onClick={handleSubmit(onSubmit)} type="button" className="btn btn-primary">Save</button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}

export default BinTransferModal;