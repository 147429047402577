import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  ModalFooter,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../../../../../share-components/ui/preLoader";
import {
  setSalesOrderBinLocationDialogProps,
  showSalesOrderItemLocation,
  updateSalesOrderItemLocation,
} from "../../../store/salesOrderSlice";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    width: 250,
    border: "none",
  }),
};

const defaultValues = {
  bin_locations: [],
};

const schema = yup.object().shape({
  bin_locations: yup.array().of(
    yup.object().shape({
      quantity: yup
        .number()
        .integer()
        .when("is_selected", {
          is: true,
          then: yup
            .number()
            // .required("This field is required")
            .typeError("Please enter a valid number")
            .min(0, "Quantity should be greater than or equal to zero")
            .test(
              "less-than-or-equal",
              "Quantity should be less than or equal to the available quantity",
              function (value) {
                return value <= this.parent.available;
              }
            ),
          otherwise: yup.number(),
        }),
    })
  ),
});

const BinLocationModal = (props) => {
  const { orderSetValue, orderTrigger, orderGetValues, onChangeDialog } = props;
  const dispatch = useDispatch();
  const binInputRef = useRef({});
  let inventoryInputRef = useRef([]);
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    handleChange,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "bin_locations",
    control,
  });
  const { errors, isDirty } = formState;
  const [loading, isSetLoading] = useState(false);
  const [locations, setLocations] = useState(false);
  const [locationInventory, setLocationInventory] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [bins, setBins] = useState(null);
  const [packSize, setPackSize] = useState(null);
  const [locationId, setLocationId] = useState(0);
  const [locationQuantity, setLocationQuantity] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [isPack, setIsPack] = useState(false);
  const [productVariantID, setProductVariantID] = useState(null);
  const [ratioName, setRatioName] = useState("");
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesDialogProps = useSelector(
    (state) => state.order.salesOrder.salesOrderBinLocationDialogProps
  );
  const lineItemId = salesDialogProps.data.id;

  const toggleModal = () => {
    setBins(null);
    setLocationInventory(null);
    reset(defaultValues);
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesOrderBinLocationDialogProps(obj));
  };

  const selectedQuantities = getValues("bin_locations")
    .filter((location) => location.is_selected)
    .map((location) => Number(location.quantity));

  const totalSelectedQuantity = selectedQuantities.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  let remainingQuantity = locationQuantity - totalSelectedQuantity;

  const onChildSubmit = (data) => {
    const binLocation = [];

    data.bin_locations.map(function (item, index) {
      if (item.quantity > 0) {
        binLocation.push({
          quantity: Number(item.quantity),
          bin_number: item.bin_number,
        });
      }
    });

    const bin_locations = binLocation;
    const selectedLocation = locations.find(
      (location) => location.id === locationInventory
    );

    const selectedLocationId = selectedLocation
      ? selectedLocation.location_id
      : "";

    if (isPack) {
      const selectedPackVariant = packSize ? packSize[activeTab] : null;
      const packVariantId = selectedPackVariant
        ? selectedPackVariant.product_variant_id
        : null;

      dispatch(
        updateSalesOrderItemLocation({
          id: lineItemId,
          location_id: selectedLocationId ? selectedLocationId : locationId,
          bin_locations: bin_locations,
          product_variant_id: packVariantId,
          is_pack: isPack,
        })
      ).then((res) => {
        if (!res.error) {
          toggleModal();
        }
      });
    } else {
      const selectedProuctVariant = bins ? bins : null;
      const productVariantId = selectedProuctVariant
        ? selectedProuctVariant[0]?.product_variant_id
        : null;

      dispatch(
        updateSalesOrderItemLocation({
          id: lineItemId,
          location_id: selectedLocationId ? selectedLocationId : locationId,
          bin_locations: bin_locations,
          product_variant_id: productVariantId,
          is_pack: isPack,
        })
      ).then((res) => {
        if (!res.error) {
          toggleModal();
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (lineItemId) {
        isSetLoading(true);

        const locationID = salesDialogProps.data.location[0];
        setLocationId(locationID?.location_id);

        try {
          const indexRes = await dispatch(
            showSalesOrderItemLocation(lineItemId)
          );

          if (!indexRes.error) {
            setLocations(indexRes.payload.location);

            const selectedLocation = indexRes.payload.location[0];
            const locationInventory = selectedLocation.location_inventory;
            const packVariants = selectedLocation.pack_variants;
            const inventoryData = [];

            setRatioName(selectedLocation.ratio_name);
            setIsPack(selectedLocation.is_pack);

            setPackSize(packVariants);
            setBins(locationInventory);

            if (isPack) {
              const selectedPackVariant = selectedLocation.pack_variants
                ? selectedLocation.pack_variants[activeTab]
                : null;
              const defaultPackBinLocations =
                selectedPackVariant.location_inventory.map((item) => ({
                  is_selected: true,
                  quantity: item.quantity,
                  bin_number: item.bin_number,
                  available: item.available,
                }));

              selectedPackVariant.location_inventory.map(function (
                item,
                index
              ) {
                inventoryData.push({
                  is_selected: true,
                  bin_number: item.bin_number,
                  product_variant_id: item.product_variant_id,
                  available: item.available,
                  quantity: item.quantity > 0 ? item.quantity : 0,
                });
              });
              const data = {
                bin_locations: inventoryData,
              };
              reset(data);

              setLocationQuantity(
                selectedPackVariant ? selectedPackVariant.quantity : null
              );

              const defaultCheckboxStates = {};
              const defaultInputValues = {};

              defaultPackBinLocations.forEach((item, i) => {
                defaultCheckboxStates[i] = item.is_selected;
                defaultInputValues[i] = item.quantity;
              });

              setCheckboxStates(defaultCheckboxStates);
              setInputValues(defaultInputValues);
              reset({
                bin_locations: selectedPackVariant.location_inventory.map(
                  (item, i) => ({
                    is_selected: defaultCheckboxStates[i],
                    quantity: defaultInputValues[i] || 0,
                    bin_number: item.bin_number,
                    available: item.available,
                  })
                ),
              });
            } else {
              locationInventory.map(function (item, index) {
                inventoryData.push({
                  is_selected: true,
                  bin_number: item.bin_number,
                  product_variant_id: item.product_variant_id,
                  available: item.available,
                  quantity: item.quantity > 0 ? item.quantity : 0,
                });
              });

              const data = {
                bin_locations: inventoryData,
              };
              reset(data);

              setLocationQuantity(selectedLocation.quantity);
              const defaultBinLocations = locationInventory.map((item) => ({
                is_selected: true,
                quantity: item.quantity > 0 ? item.quantity : 0,
                bin_number: item.bin_number,
                available: item.available,
              }));
              setProductVariantID(inventoryData[0].product_variant_id);
              const defaultCheckboxStates = {};
              const defaultInputValues = {};

              defaultBinLocations.forEach((item, i) => {
                defaultCheckboxStates[i] = item.is_selected;
                defaultInputValues[i] = item.quantity;
              });

              setCheckboxStates(defaultCheckboxStates);
              setInputValues(defaultInputValues);

              reset({
                bin_locations: defaultBinLocations.map((item, i) => ({
                  ...item,
                  quantity: defaultInputValues[i] || 0,
                })),
              });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          isSetLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, lineItemId, reset, activeTab, isPack]);

  const enableButton = () => {
    if (salesDialogProps.props.isOpen) {
      const total = getValues("bin_locations")
        .filter(function (v, i) {
          return v.is_selected;
        })
        .reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.quantity),
          0
        );

      return total == locationQuantity;
    } else {
      return false;
    }
  };

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size={isPack ? "lg" : "md"}
    >
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggleModal}>
            <p style={{ fontSize: "20px" }}>Update Bin Number</p>

            <span
              style={{
                fontWeight: "lighter",
                color: "rgb(115, 115, 115)",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {salesDialogProps.data.sku}
              </span>
            </span>
            <p>Ordered Qty: {salesDialogProps.data.orderedQty}</p>
          </ModalHeader>
          <Form noValidate="" onSubmit={handleSubmit(onChildSubmit)}>
            <ModalBody>
              {salesDialogProps.data && (
                <>
                  <Container fluid={true}>
                    <Row>
                      <Col md={12}>
                        {isPack ? (
                          <Row>
                            {ratioName && (
                              <div className="pull-left mb-3 pt-3">
                                <span>
                                  <b>Ratio</b> : {ratioName}
                                </span>
                              </div>
                            )}
                          </Row>
                        ) : null}
                        <Row>
                          <Col className="px-0">
                            <div className="pull-left mb-3 pt-3">
                              <span>
                                <b>Location Quantity</b> : {locationQuantity}
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <div className="pull-right mb-3 pt-3">
                              <span>
                                <b>Remaining Quantity</b> :{" "}
                                {remainingQuantity > 0 ? remainingQuantity : 0}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <div className="pull-right mb-3">
                          {locations.length > 1 && (
                            <Select
                              className="select2-filter border"
                              isClearable
                              cacheOptions
                              styles={customStyles}
                              placeholder="Select Location"
                              defaultValue={
                                locations
                                  ? locations.find(
                                      (loc) => loc.location_id === locationId
                                    )
                                  : null
                              }
                              getOptionLabel={(option) => option.location_name}
                              getOptionValue={(option) => option.location_id}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                const selectedLocation = e
                                  ? locations.find(
                                      (location) => location.id === e.id
                                    )
                                  : null;
                                setIsPack(selectedLocation.is_pack);
                                const inventoryData = [];

                                (isPack
                                  ? selectedLocation.pack_variants[0]
                                      .location_inventory
                                  : selectedLocation?.location_inventory
                                ).map(function (item, index) {
                                  inventoryData.push({
                                    // is_selected: item.quantity > 0,
                                    is_selected: true,
                                    bin_number: item.bin_number,
                                    product_variant_id: item.product_variant_id,
                                    available: item.available,
                                    quantity:
                                      item.quantity > 0 ? item.quantity : 0,
                                  });
                                });
                                const data = {
                                  bin_locations: inventoryData,
                                };
                                reset(data);
                                setBins(
                                  selectedLocation
                                    ? selectedLocation.location_inventory
                                    : null
                                );
                                setLocationInventory(
                                  selectedLocation
                                    ? selectedLocation.location_id
                                    : null
                                );
                                setLocationQuantity(
                                  selectedLocation
                                    ? selectedLocation.quantity
                                    : null
                                );
                                setLocationId(e.location_id);

                                const defaultCheckboxStates = {};
                                const defaultInputValues = {};

                                if (selectedLocation) {
                                  selectedLocation.bin_location.forEach(
                                    (item, i) => {
                                      defaultCheckboxStates[i] = true;
                                      defaultInputValues[i] = item.quantity;
                                    }
                                  );
                                }

                                setCheckboxStates(defaultCheckboxStates);
                                setInputValues(defaultInputValues);
                              }}
                              options={locations ? locations : null}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div>
                          {isPack ? (
                            <>
                              <Nav tabs>
                                {packSize &&
                                  packSize.map((variant, index) => (
                                    <NavItem key={index}>
                                      <NavLink
                                        className={
                                          activeTab === index ? "active" : ""
                                        }
                                        onClick={() => setActiveTab(index)}
                                      >
                                        Size {variant.size}
                                      </NavLink>
                                    </NavItem>
                                  ))}
                              </Nav>
                              <TabContent activeTab={activeTab}>
                                {packSize &&
                                  packSize.map((variant, index) => (
                                    <TabPane key={index} tabId={index}>
                                      <Row>
                                        <Col sm="12">
                                          {variant.location_inventory ? (
                                            <table className="table">
                                              <tbody>
                                                {fields.map((item, i) => (
                                                  <tr key={i}>
                                                    <td className="pt-3">
                                                      <span className="pt-1">
                                                        {item.bin_number}{" "}
                                                        (Available{" "}
                                                        {item.available})
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <input
                                                        ref={(ref) => {
                                                          inventoryInputRef.current[
                                                            item.id
                                                          ] = ref;
                                                        }}
                                                        value={getValues(
                                                          `bin_locations.${i}.quantity`
                                                        )}
                                                        type="number"
                                                        onChange={(e) => {
                                                          setValue(
                                                            `bin_locations.${i}.quantity`,
                                                            e.target.value
                                                          );
                                                          trigger(
                                                            `bin_locations.${i}.quantity`
                                                          );
                                                        }}
                                                        className="form-control w-50"
                                                      />
                                                      <div
                                                        style={{
                                                          color: "red",
                                                          float: "right",
                                                        }}
                                                      >
                                                        {
                                                          errors
                                                            .bin_locations?.[i]
                                                            ?.quantity?.message
                                                        }
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          ) : (
                                            <p>
                                              No location inventory available
                                              for this size.
                                            </p>
                                          )}
                                        </Col>
                                      </Row>
                                    </TabPane>
                                  ))}
                              </TabContent>
                            </>
                          ) : (
                            <>
                              {bins && (
                                <table className="table">
                                  <tbody>
                                    {fields.map((item, i) => (
                                      <tr key={i}>
                                        <td className="pt-3">
                                          <span className="pt-1">
                                            {item.bin_number} (Available{" "}
                                            {item.available})
                                          </span>
                                        </td>

                                        <td>
                                          <input
                                            ref={(ref) => {
                                              inventoryInputRef.current[
                                                item.id
                                              ] = ref;
                                            }}
                                            value={getValues(
                                              `bin_locations.${i}.quantity`
                                            )}
                                            type="number"
                                            onChange={(e) => {
                                              setValue(
                                                `bin_locations.${i}.quantity`,
                                                e.target.value
                                              );
                                              trigger(
                                                `bin_locations.${i}.quantity`
                                              );
                                            }}
                                            className="form-control w-50"
                                          />
                                          <div
                                            style={{
                                              color: "red",
                                              float: "right",
                                            }}
                                          >
                                            {
                                              errors.bin_locations?.[i]
                                                ?.quantity?.message
                                            }
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {salesDialogProps.props.isOpen ? (
                <>
                  {enableButton() ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleSubmit(onChildSubmit)}
                    >
                      Save
                    </button>
                  ) : (
                    <span style={{ color: "red", paddingRight: 30 }}>
                      Total quantity across bins should be equal to the location
                      quantity.
                    </span>
                  )}
                </>
              ) : (
                ""
              )}
            </ModalFooter>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default BinLocationModal;
