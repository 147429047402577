import API from "../../../utils/api";

export const getServiceItemsApi = async (filters) => {
  const response = await API.get("order/settings/service-item", {
    params: filters,
  });
  return { response };
};

export const addServiceItemApi = async (location) => {
  const response = await API.post("order/settings/service-item", location);
  return { response };
};

export const updateServiceItemApi = async (location, id) => {
  const response = await API.put(`order/settings/service-item/${id}`, location);
  return { response };
};

export const deleteServiceItemApi = async (id) => {
  const response = await API.delete(`order/settings/service-item/${id}`);
  return { response };
};
