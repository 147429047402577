import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
} from "reactstrap";
import salesOrderReturnItemReducer from "../../store";
import RefundModal from "./content/refundModal";
import {
  mapSearchCustomerAutoSuggestion,
  salesOrderReturnResponseToTable,
} from "../../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../../services/order";
import withReducer from "../../../../store/withReducer";

import {
  getReturnItems,
  selectAllReturnItems,
} from "../../store/saleOrderReturnItemSlice";
import { setSettings, getSalesOrderFilters } from "../../store/salesOrderSlice";
import {
  setRefundModal,
  sendReturnItemUpdate,
  downloadSalesOrderCreditNotePdf,
} from "../../store/saleOrderReturnItemSlice";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "../../../../share-components/ui/DateRangePicker";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { DateRangePicker } from "rsuite";

const { afterToday } = DateRangePicker;

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Return List",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const SalesOrderReturnItem = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [invoiceXeroNumber, setInvoiceXeroNumber] = useState(null);
  const [xeroCreditNoteNumber, setXeroCreditNoteNumber] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [name, setName] = useState([]);
  const [modal, setModal] = useState(false);
  let history = useHistory();
  const [loading, isSetLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const orderState = useSelector(({ order }) => order.saleOrderReturnItem);
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const saleOrderReturnItemState = useSelector(
    ({ order }) => order.saleOrderReturnItem
  );
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const futureSevenDays = new Date().setDate(TodayDate.getDate() + 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [approvedDateRange, setApprovedDateRange] = useState([
    sevenDays,
    TodayDate,
  ]);
  const [returnDateRange, setReturnDateRange] = useState([
    sevenDays,
    futureSevenDays,
  ]);
  const [refundDateRange, setRefundDateRange] = useState(["", ""]);
  const [startDate, endDate] = dateRange;
  const [approvedStartDate, approvedEndDate] = approvedDateRange;
  const [returnStartDate, returnEndDate] = returnDateRange;
  const [refundStartDate, refundEndDate] = refundDateRange;

  console.log("orderState ", orderState);

  const returnItems = useSelector(selectAllReturnItems);
  console.log("returnItems ", returnItems);
  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

  useEffect(() => {
    getData();
  }, [
    page,
    status,
    customer,
    dateRange,
    startDate,
    endDate,
    invoiceXeroNumber,
    xeroCreditNoteNumber,
    orderNumber,
    approvedDateRange,
    returnDateRange,
    refundDateRange,
  ]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      status: status,
      customer: customer,
      invoice_xero_number: invoiceXeroNumber,
      credit_xero_number: xeroCreditNoteNumber,
      order_number: orderNumber,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      return_start_date: moment(returnStartDate).format("YYYY-MM-DD"),
      return_end_date: returnEndDate
        ? moment(returnEndDate).format("YYYY-MM-DD")
        : "",
      refund_start_date: refundStartDate
        ? moment(refundStartDate).format("YYYY-MM-DD")
        : "",
      refund_end_date: refundEndDate
        ? moment(refundEndDate).format("YYYY-MM-DD")
        : "",
      approved_start_date: moment(approvedStartDate).format("YYYY-MM-DD"),
      approved_end_date: moment(approvedEndDate).format("YYYY-MM-DD"),
    };
    dispatch(getReturnItems(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  const onAction = (row) => {
    dispatch(setRefundModal({ props: { isOpen: true }, data: row }));
  };

  const onDownload = (row) => {
    processPDFDownload("Credit Note", row.id);
  };

  const processPDFDownload = (name, orderId) => {
    const data = {
      id: orderId,
      params: {
        name: name,
        // seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderCreditNotePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${res.data ? res.payload.order_no : "-"}_${name.replace(
          /\s+/g,
          "-"
        )}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const onRefund = (data) => {
    setBtnDisable(true);
    const postData = {
      id: data.data.id,
      is_penalty: data.isPenalty,
      is_declined: data.isDecline,
    };
    dispatch(sendReturnItemUpdate(postData)).then((res) => {
      if (!res.error) {
        getData();
        dispatch(setRefundModal({ props: { isOpen: false }, data: null }));
        // history.push(
        //   data.data.sales_order_id + "/billing/invoice/" + data.data.id
        // );
      }
      setBtnDisable(false);
    });
  };

  const tableColumns = [
    {
      name: "Order #",
      selector: (row) => row.order_no,
      sortable: true,
      center: false,
    },
    {
      name: "Xero Invoice ID",
      selector: (row) => row.xero_id,
      sortable: true,
      center: false,
    },
    {
      name: "Request Date",
      selector: (row) => row.request_date,
      sortable: true,
      center: false,
    },
    {
      name: "Approved Date",

      selector: (row) => row.warehouse_approve_date,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: false,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <div>
            <a
              onClick={() => {
                onEdit(row);
              }}
            >
              <i
                className="fa fa-undo"
                style={{
                  width: 25,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(255, 187, 51)",
                }}
              ></i>
            </a>
          </div>
        </>
      ),
      sortable: false,
      center: true,
    },
  ];

  const onEdit = (row) => {
    history.push("/order/sale/return/" + row.id + "/edit");
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data))
        );
      });
    });

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption ? selectedOption.value : null);
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Returns" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <Label>Customer Return Date</Label>
                          <DatePicker
                            dateRange={returnDateRange}
                            dateRangeChange={(update) => {
                              setReturnDateRange(update);
                            }}
                            // disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <Label>Warehouse Approved Date</Label>
                          <DatePicker
                            dateRange={approvedDateRange}
                            dateRangeChange={(update) => {
                              setApprovedDateRange(update);
                            }}
                            // disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <Label>Refund Date</Label>
                          <DatePicker
                            dateRange={refundDateRange}
                            dateRangeChange={(update) => {
                              setRefundDateRange(update);
                            }}
                            // disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  {/* <Col md={3}>
                    <Label></Label>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Customer"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setCustomer(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.customers
                          : null
                      }
                    />
                  </Col> */}

                  <Col md={3}>
                    <Label></Label>
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      styles={customStyles}
                      loadOptions={typePromiseOption}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                    />
                  </Col>

                  <Col md={3} className="pb-3">
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Status"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setStatus(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.return_status
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setInvoiceXeroNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Invoice Xero No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setXeroCreditNoteNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Xero Credit Note No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        // gridData={returnItems}
                        gridData={salesOrderReturnResponseToTable(returnItems)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderReturnItemState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <RefundModal
          orderState={orderState}
          onRefund={onRefund}
          btnDisable={btnDisable}
        ></RefundModal>
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    SalesOrderReturnItem: salesOrderReturnItemReducer,
  },
])(SalesOrderReturnItem);
