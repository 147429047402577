import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {setInventoryDialog} from "../../../store/salesOrderSlice";
import {ModalFooter} from "react-bootstrap";
import * as yup from "yup";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

const defaultValues = {
    inventories: []
};
const schema = yup.object().shape({
    inventories: yup.array().of(
        yup.object().shape({
            quantity: yup.string().when('is_selected', {
                is: true, // Change this to the specific value triggering the condition
                then: yup.string().required('this Field is required').test('greater-than-or-equal', 'should be lee than aviable', function (value) {
                    // You can access other field values using this.parent
                    return value <= this.parent.available;
                }),
                otherwise: yup.string(),
            })
        })
    )
});


const InventoryLocation = (props) => {
    const {orderSetValue,orderTrigger, orderGetValues,onChangeDialog} = props;
    const dispatch = useDispatch();
    let inventoryCheckRef = useRef([]);
    let inventoryInputRef = useRef([]);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {fields, append, remove} = useFieldArray({name: 'inventories', control})
    const orderState = useSelector(({order}) => order.salesOrder.inventoryDialogProps);
    const {errors, isDirty} = formState;

    const closDialog = () => {
        dispatch(setInventoryDialog({props: {isOpen: false}}))
    }


    useEffect(() => {
        if (orderState.props.isOpen) {
            const inventoryData = [];
            (orderState.data.inventories).map(function (item, index) {
                const selectedData = findObjectByLocation(orderState.data.inventory_location, item.location_id);
                console.log(orderState.data.inventory_location);
                inventoryData.push({
                    is_selected: !!selectedData,
                    name: item.name,
                    location_id:item.location_id,
                    available: item.available,
                    quantity:selectedData?selectedData.quantity:""
                });
            });
            const data = {
                inventories: inventoryData
            }
            reset(data);
        }

    }, [orderState.props.isOpen])

    const onChildSubmit=(data)=>{
        const inventoryLocation=[];
        (data.inventories).map(function (item, index) {
            if(item.is_selected){
                inventoryLocation.push({
                   location_id:item.location_id,
                    name: item.name,
                    quantity:Number(item.quantity)
                });
            }
        });
         const orderProducts=orderGetValues('ordered_products');
         const orderIndex=  orderProducts.findIndex(x => x.variant == orderState.data.variant_id);
        const orderData=  orderProducts.find(obj => obj.variant === orderState.data.variant_id);
         orderProducts[orderIndex]={
             ...orderData,
             inventory_location:inventoryLocation
         }
         orderSetValue('ordered_products',orderProducts);
         onChangeDialog(true);
         closDialog();
    }
    const findObjectByLocation = (array, locationId) => {
        return array.find(obj => obj.location_id === locationId);
    }
    const enableButton = ()=>{
        if (orderState.props.isOpen) {
            const total = getValues("inventories").filter(function (v, i) {
                return v.is_selected;
            }).reduce((accumulator, currentValue) => accumulator + Number(currentValue.quantity), 0);
            const orderData = orderGetValues('ordered_products').find(obj => obj.variant === orderState.data.variant_id);
            return total == orderData?.quantity;
        }else{
            return false;
        }
    }
    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="md"    {...orderState.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        {orderState.data?.sku}
                    </ModalHeader>
                    <Form noValidate="" onSubmit={handleSubmit(onChildSubmit)}>
                    <ModalBody>
                        {orderState.props.isOpen && <table className="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Location</th>
                                <th>Available</th>
                                <th>Quantity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fields.map((item, i) => (
                                <tr key={i}>
                                    <td><input type={"checkbox"}
                                               checked={getValues(`inventories.${i}.is_selected`)}

                                               onChange={(e) => {
                                                   setValue(`inventories.${i}.is_selected`, e.target.checked);
                                                   setValue(`inventories.${i}.quantity`, e.target.checked?1:"");
                                                   // inventoryInputRef.current[item.id].disabled=!e.target.checked;
                                                   // inventoryInputRef.current[item.id].value=e.target.checked?1:"";
                                                   trigger(`inventories.${i}.is_selected`);
                                               }}/></td>
                                    <td>{item.name}</td>
                                    <td>{item.available}</td>
                                    <td><input ref={(ref) => {
                                        inventoryInputRef.current[item.id] = ref;
                                    }} disabled={!getValues(`inventories.${i}.is_selected`)} value={getValues(`inventories.${i}.quantity`)} type="text"
                                               onChange={(e) => {
                                                   setValue(`inventories.${i}.quantity`, e.target.value);
                                                   trigger(`inventories.${i}.quantity`);
                                               }} className="form-control w-50"/>
                                        <div style={{color: 'red', float: "right"}}>{errors.inventories?.[i]?.quantity?.message}</div>

                                    </td>
                                </tr>))}
                            </tbody>


                        </table>}

                    </ModalBody>
                    <ModalFooter>
                        {enableButton() &&    <button className="btn btn-primary" type="button" onClick={handleSubmit(onChildSubmit)}>Save</button>}
                    </ModalFooter>
                    </Form>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
}

export default InventoryLocation;