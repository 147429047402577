import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/async";

import React from "react";
import {useDispatch} from "react-redux";
import {mapSearchCustomerAutoSuggestion} from "../../../../../../utils/mapper";
import {invoiceAutoSuggestionApi} from "../../../../../../services/manage-order/saleOrderInvoiceService";


const PickUpDetail = (props) => {
    const {setValue, getValues, errors, trigger, id, register} = props;
    const dispatch = useDispatch();
    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
                invoice_id: id,
                unit_type: getValues("net_unit_type"),
            };
            setTimeout(() => {
                resolve(
                    mapSearchCustomerAutoSuggestion(invoiceAutoSuggestionApi(data))
                );
            });
        });
    return (
        <Card>
            <CardBody>
                <h5>Pick Up Details</h5>
                <Row className="mt-1">
                    <Col md={6}>
                        <FormGroup>
                            <Label className="col-form-label">
                                Total No Of Boxes
                            </Label>
                            <input
                                type="number"
                                className="form-control"
                                {...register("total_no_of_boxes")}
                                onKeyUp={(e) => {
                                    if (e.target.value) {
                                        const totalNetWeight = Number(getValues('total_net_weight'));
                                        const totalBoxWeight = Number(e.target.value) * 0.85;
                                        setValue('total_gross_weight', totalNetWeight + totalBoxWeight);
                                    }
                                }}
                            />
                            <div style={{color: "red"}}>
                                {errors.total_no_of_boxes?.message}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="col-form-label">
                                Unit Type
                            </Label>
                            <Input type="select" name="select" onChange={(e) => {
                                if (e.target.value == 1) {
                                    setValue('total_gross_weight', getValues('total_gross_weight') / 1000);
                                    setValue('total_net_weight', getValues('total_net_weight') / 1000);
                                } else {
                                    setValue('total_gross_weight', getValues('total_gross_weight') * 1000);
                                    setValue('total_net_weight', getValues('total_net_weight') * 1000);
                                }

                                setValue('net_unit_type', e.target.value);
                                setValue('gross_unit_type', e.target.value);
                                setValue('merge_invoices', []);
                                trigger('merge_invoices');
                                trigger('net_unit_type');
                            }} className="form-control digits" value={getValues('net_unit_type')?getValues('net_unit_type'):1}>
                                <option value="1">Kg</option>
                                <option value="2">Grams</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-0">
                    <Col md={12}>
                        <FormGroup>
                            <Label className="col-form-label">
                                Total Net Weight
                            </Label>
                            <input
                                type="number"
                                onKeyUp={(e) => {
                                    if (e.target.value) {
                                        const totalNetWeight = Number(e.target.value);
                                        const totalBoxWeight = Number(getValues('total_no_of_boxes')) * 0.85;
                                        setValue('total_gross_weight', totalNetWeight + totalBoxWeight);
                                        trigger('total_gross_weight');
                                        trigger('total_net_weight');
                                    }
                                }}
                                step="any"
                                className="form-control"
                                {...register("total_net_weight")}
                            />

                            <div style={{color: "red"}}>
                                {errors.total_net_weight?.message}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-0">
                    <Col md={12}>
                        <FormGroup>
                            <Label className="col-form-label">
                                Total Gross Weight
                            </Label>

                            <input
                                type="number"
                                step="any"
                                className="form-control"
                                {...register("total_gross_weight")}
                            />

                            <div style={{color: "red"}}>
                                {errors.total_gross_weight?.message}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mt-0">
                    <Col md={12}>
                        <FormGroup>
                            <Label className="col-form-label w-100">
                                Merge Invoice{" "}
                                <input
                                    checked={getValues("is_merge")}
                                    type={"checkbox"}
                                    className=" pull-right"
                                    onChange={(e) => {
                                        setValue("is_merge", e.target.checked);
                                        if (!e.target.checked) {
                                            setValue("merge_invoices", []);
                                            trigger("merge_invoices");
                                        }
                                        trigger("is_merge");
                                    }}
                                />
                            </Label>
                            {getValues("is_merge") && (
                                <AsyncSelect
                                    key={id + "_" + getValues("net_unit_type")}
                                    className={` ${
                                        errors.merge_invoices ? "is-invalid" : ""
                                    }`}
                                    isClearable
                                    isMulti={true}
                                    cacheOptions
                                    defaultOptions
                                    value={getValues("merge_invoices")}
                                    loadOptions={typePromiseOption}
                                    onChange={(e) => {
                                        setValue("merge_invoices", e);
                                        trigger("merge_invoices");
                                    }}
                                />
                            )}
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default PickUpDetail;