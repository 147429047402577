import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from "reactstrap";
import withReducer from "../../../../store/withReducer";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PreLoader from "../../../../share-components/ui/preLoader";
import SaleOrderProduct from "./content/saleOrderProduct";
import SaleOrderDiscussion from "./content/saleOrderDiscussion";
import draftOrderReducer from "../../store";
import { salesOrderStatus } from "../../../../utils/common";
import { orderCommodities } from "../setting/calculation";
import { useHistory } from "react-router-dom";
import {
  showSalesOrder,
  updateSalesOrderStatus,
  downloadSalesOrderPdf,
  setSalesCloseDialog,
  closeCurrentSalesOrder,
  downloadSalesOrderPickingTicketPdf,
  downloadSalesOrderPickingTickeBinPdf,
} from "../../store/salesOrderSlice";
import CloseSalesOrderModal from "./content/closeSalesOrderModal";
import { toast } from "react-toastify";

const defaultValues = {
  id: "",
  ordered_products: [],
  date: "",
  tags: [],
  notes: "",
  discount: "",
  shipping: "",
  tax: [],
  is_tax: false,
  is_paid: false,
  customer: "",
  shopify_draft_order_id: "",
  shippingAddress: "",
  billingAddress: "",
};

export const defaultAddressValues = {
  company: "",
  type: "",
  country: "",
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  phone: "",
  province: "",
  order_no: "",
};

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Sales Order View",
  },
];

const ViewSalesOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { reset, setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [okButtonsEnabled, setOkButtonsEnabled] = useState(true);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [discussionSection, setDiscussionSection] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [mainButton, setMainButton] = useState(false);
  const [statusName, setStatusName] = useState("");
  const [closeReason, setCloseReason] = useState("");
  const [skuSearch, setSkuSearch] = useState("");
  const [selectedReason, setSelectedReason] = useState(null);
  const [binButton, setBinButton] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [enableDownloadPicking, setEnableDownloadPicking] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  let history = useHistory();

  const processPDFDownload = (name) => {
    const orderId = getValues("id");
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTicketPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("order_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processPickingBinPDFDownload = (name) => {
    const orderId = getValues("id");
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPickingTickeBinPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("order_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processSalesOrderPDFDownload = (name) => {
    const orderId = getValues("id");
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };

    dispatch(downloadSalesOrderPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("order_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleOkToGoClick = () => {
    const newStatusId = 1;
    const orderId = getValues("id");
    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        if (!res.error) {
          setButtonsEnabled(true);
          setOkButtonsEnabled(false);
          setStatusDropdownVisible(true);
          setCurrentStatus(res.payload.id);
          // setDiscussionSection(true);
          setStatusName(res.payload.name || "");
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const handleStatusChange = (newStatusId) => {
    const orderId = getValues("id");

    dispatch(updateSalesOrderStatus({ id: orderId, status_id: newStatusId }))
      .then((res) => {
        setStatusName(res.payload.name || "");
        setDiscussionSection(res.payload.id >= 2);
        setCurrentStatus(res.payload.id);
        if (res.payload.id === 7) {
          setMainButton(false);
        }

        if (res.payload.id === 1) {
          setBinButton(true);
        }
        if (res.payload.id === 2) {
          setBinButton(false);
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const handleStatusNameUpdate = (name) => {
    setStatusName(name);
  };

  const downloadPicking = () => {
    processPDFDownload("Picking Ticket By Style");

    const orderId = getValues("id");
    if (orderId) {
      if (currentStatus == 1) {
        dispatch(updateSalesOrderStatus({ id: orderId, status_id: 2 }))
          .then((res) => {
            if (!res.error) {
              setStatusName(res.payload.name || "");
              setDiscussionSection(res.payload.id >= 2);
              setCurrentStatus(res.payload.id);
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      }
    }
  };

  const downloadBinPicking = () => {
    processPickingBinPDFDownload("Picking Ticket By Bin");

    const orderId = getValues("id");
    if (orderId) {
      if (currentStatus == 1) {
        dispatch(updateSalesOrderStatus({ id: orderId, status_id: 2 }))
          .then((res) => {
            if (!res.error) {
              setStatusName(res.payload.name || "");
              setDiscussionSection(res.payload.id >= 2);
              setCurrentStatus(res.payload.id);
            }
          })
          .catch((error) => {
            console.error("Error updating status:", error);
          });
      }
    }
  };

  const downloadSalesOrder = () => {
    processSalesOrderPDFDownload("Sales Order");
  };

  const createInvoice = () => {
    history.push("billing/invoice");
  };

  const createFulfillment = () => {
    history.push("billing/fulfillment");
  };

  const editSalesOrder = () => {
    history.push("edit");
  };

  const closeSalesOrder = () => {
    const data = {
      props: { isOpen: true },
      type: "edit",
      data: {
        id: getValues("id"),
      },
    };

    dispatch(setSalesCloseDialog(data));
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false } };
    dispatch(setSalesCloseDialog(obj));
  };

  const handleConfirm = () => {
    if (!selectedReason) {
      toast.error(`Please select a reason before proceeding.`);
      return;
    }

    const orderId = getValues("id");

    dispatch(closeCurrentSalesOrder({ id: orderId, reason_id: selectedReason }))
      .then((res) => {
        setCloseReason(res.payload?.reason || "");
        closeDialog();
      })
      .catch((error) => {
        console.error("Error closing Sales Order:", error);
      });

    handleStatusChange(7);
  };

  useEffect(() => {
    if (id) {
      dispatch(showSalesOrder({ id: id, search: skuSearch })).then((res) => {
        if (!res.error) {
          reset(res.payload);
          setLoading(false);
          setOkButtonsEnabled(res.payload.status?.id == 0);
          setButtonsEnabled(res.payload.status?.id > 0);
          setStatusDropdownVisible(res.payload.status?.id > 0);
          setDiscussionSection(res.payload.status?.id >= 2);
          setStatusName(res.payload.status?.name || "");
          setCloseReason(res.payload.closed_reason?.reason || "");
          setCanClose(!getValues("can_close"));
          setMainButton(!getValues("is_closed"));
          setCurrentStatus(res.payload.status?.id || "");
          setBinButton(res.payload.status?.id <= 1);
          setEnableDownloadPicking(res.payload.sum_back_order_quantity == 0);
        }
      });
    } else {
      setLoading(false);
      setButtonsEnabled(false);
    }
  }, [dispatch]);

  const dropdownStatusOptions = salesOrderStatus.map((status) => (
    <DropdownItem key={status.id} onClick={() => handleStatusChange(status.id)}>
      {status.name}
    </DropdownItem>
  ));

  // CALCULATIONS
  const formatCurrency = (amount) => {
    const parsedAmount = parseFloat(amount);

    if (isNaN(parsedAmount)) {
      return "N/A";
    }

    return parsedAmount.toFixed(2);
  };

  const calculateDiscount = () => {
    const discount = getValues("discount");
    const subTotal = getValues("sub_total") || 0;

    let discountValue = parseFloat(discount?.value) || 0;

    if (discount?.discount_type == 2) {
      return ((discountValue * subTotal) / 100).toFixed(2);
    } else {
      return discountValue.toFixed(2);
    }
  };

  const calculateDuty = () => {
    const duty = getValues("duty");
    const subTotal = getValues("sub_total") || 0;

    if (duty) {
      const dutyValue = parseFloat(duty.value);

      if (!isNaN(dutyValue)) {
        return (
          duty.type == 2 ? (dutyValue * subTotal) / 100 : dutyValue
        ).toFixed(2);
      }
    }
    return "0.00";
  };

  const calculateTax = () => {
    const tax = getValues("tax");
    const subTotal = getValues("sub_total") || 0;
    const subTax = getValues("sub_tax") || 0;
    const shipping = getValues("shipping")?.price || 0;
    const discount = parseFloat(calculateDiscount()) || 0;
    const isVattable = getValues("customer")?.isVattable;

    if (tax) {
      const taxableAmount = subTotal - discount;
      // const taxAmount =
      //   taxableAmount - taxableAmount / (1 + tax.percentage / 100);
      // const taxAmount = taxableAmount * (tax.percentage / 100);
      let taxAmount = subTax;

      if (isVattable === 1) {
        taxAmount += (shipping * 20) / 100;
      }
      return taxAmount.toFixed(2);
    } else {
      return "0.00";
    }
  };

  const calculateSubTotal = () => {
    const currencyLabel = getValues("currency")?.symbol || "";
    const total = getValues("sub_total") || 0;
    const discount = getValues("total_discount");
    const itemDiscount = getValues("total_item_discount");

    const sub = total - (discount + itemDiscount);
    return formatCurrency(sub);
  };

  const calculateNewTotal = () => {
    const currencyLabel = getValues("currency")?.symbol || "";
    const total = getValues("total_price") || 0;

    return formatCurrency(total);
  };

  return (
    <Fragment>
      <Breadcrumb
        title={
          <>
            Sales Order
            {getValues("order_no") ? <> - {getValues("order_no")}</> : ""}{" "}
            {getValues("shopify_order_no") ? (
              <>
                {" "}
                <br />
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Shopify Order No{" "}
                  <span>- {getValues("shopify_order_no")}</span>
                </span>
              </>
            ) : (
              ""
            )}
            {getValues("status") ? (
              <>
                <br />
                <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                  Status:{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {statusName}{" "}
                    <>{closeReason ? <i> - ({closeReason})</i> : ""}</>
                  </span>
                </span>
              </>
            ) : (
              ""
            )}
          </>
        }
        data={dataMenu}
      />

      <Container fluid={true}>
        {mainButton && (
          <div className="d-flex justify-content-between mb-2 px-2">
            <>
              {okButtonsEnabled && (
                <Button
                  color="primary"
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{ height: 38 }}
                  onClick={handleOkToGoClick}
                >
                  OK To Go
                </Button>
              )}

              {statusDropdownVisible && (
                <Dropdown
                  isOpen={dropdownStatusOpen}
                  toggle={toggleStatusDropdown}
                >
                  <DropdownToggle
                    color="info"
                    className="btn btn-sm btn-info"
                    style={{ height: 38, marginRight: 8 }}
                  >
                    Status{" "}
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </DropdownToggle>
                  <DropdownMenu>{dropdownStatusOptions}</DropdownMenu>
                </Dropdown>
              )}
            </>

            <div className="d-flex">
              {buttonsEnabled && (
                <>
                  <Button
                    color="info"
                    type="button"
                    className="btn btn-sm btn-info"
                    style={{ height: 38, marginRight: 8 }}
                    onClick={createInvoice}
                  >
                    Create Invoice
                  </Button>

                  <Button
                    color="info"
                    type="button"
                    className="btn btn-sm btn-info"
                    style={{ height: 38, marginRight: 8 }}
                    onClick={createFulfillment}
                  >
                    Create Fulfillment
                  </Button>

                  {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                      color="warning"
                      className="btn btn-sm btn-warning"
                      style={{ height: 38, marginRight: 8 }}
                    >
                      Download{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={downloadSalesOrder}>
                        Sales Order
                      </DropdownItem>
                      <DropdownItem onClick={downloadPicking}>
                        Picking Ticket
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                </>
              )}
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  color="warning"
                  className="btn btn-sm btn-warning"
                  style={{ height: 38, marginRight: 8 }}
                >
                  Download{" "}
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={downloadSalesOrder}>
                    Sales Order
                  </DropdownItem>
                  {enableDownloadPicking && (
                    <>
                      {buttonsEnabled && (
                        <>
                          <DropdownItem onClick={downloadPicking}>
                            Picking Ticket by Style
                          </DropdownItem>
                          <DropdownItem onClick={downloadBinPicking}>
                            Picking Ticket by Bin
                          </DropdownItem>
                        </>
                      )}
                    </>
                  )}
                </DropdownMenu>
              </Dropdown>

              {!getValues("is_online") ? (
                <Button
                  color="primary"
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{ height: 38, marginRight: 8 }}
                  onClick={editSalesOrder}
                >
                  Edit
                </Button>
              ) : (
                ""
              )}

              {canClose && (
                <Button
                  color="danger"
                  type="button"
                  className="btn btn-sm btn-danger"
                  style={{ height: 38 }}
                  onClick={closeSalesOrder}
                >
                  Close SO
                </Button>
              )}
            </div>
          </div>
        )}

        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <Row className="mt-3">
            <Col sm="8">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Label
                        style={{
                          fontWeight: "bolder",
                          fontSize: "16px",
                          paddingBottom: "10px",
                        }}
                      >
                        Products
                      </Label>

                      <Row>
                        <Col md={12}>
                          <Col md="5 input-with-icon pr-1">
                            <div>
                              <FormGroup className="m-0">
                                <i className="fa fa-search"></i>
                                <input
                                  name="search"
                                  type="text"
                                  placeholder="Search by SKU"
                                  onChange={(e) => setSkuSearch(e.target.value)}
                                  className={`form-control`}
                                />
                              </FormGroup>
                            </div>
                          </Col>
                          {getValues("ordered_products").length > 0 && (
                            <SaleOrderProduct
                              setValue={setValue}
                              getValues={getValues}
                              skuSearch={skuSearch}
                              trigger={trigger}
                              binButton={binButton}
                              enableDownloadPicking={enableDownloadPicking}
                              orderedProducts={getValues("ordered_products")}
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="6">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Payment
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Total amount
                                </td>
                                <td align="center">----------------</td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(getValues("sub_total") || 0)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Item wise Discount
                                </td>
                                <td align="center">----------------</td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(
                                    getValues("total_item_discount") || 0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Discount</td>
                                <td align="center">
                                  {getValues("discount")
                                    ? getValues("discount").reason
                                    : "----------------"}
                                </td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(
                                    getValues("total_discount") || 0
                                  )}
                                  {/* {getValues("currency")?.symbol} */}
                                  {/* {formatCurrency(calculateDiscount())} */}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Sub Total
                                </td>
                                <td align="center">----------------</td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(calculateSubTotal())}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Duty</td>
                                <td align="center">
                                  {getValues("duty")
                                    ? getValues("duty").reason
                                    : "----------------"}
                                </td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(calculateDuty())}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Shipment</td>
                                <td align="center">
                                  {getValues("shipping")
                                    ? getValues("shipping").rate_name
                                    : "----------------"}
                                </td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(
                                    getValues("shipping")?.price || 0
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>Tax</td>
                                <td align="center">
                                  {getValues("tax")
                                    ? getValues("tax").rate_name
                                    : "----------------"}
                                </td>
                                <td align="right">
                                  {getValues("currency")?.symbol}
                                  {/* {formatCurrency(calculateTax())} */}
                                  {getValues("sub_tax")
                                    ? formatCurrency(getValues("sub_tax"))
                                    : "0.00"}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ paddingLeft: "0px" }}>
                                  Net Total
                                </td>
                                <td align="center">----------------</td>
                                <td align="right">
                                  {" "}
                                  {/* {getValues("currency")?.symbol}
                                  {calculateNewTotal()} */}
                                  {getValues("currency")?.symbol}
                                  {formatCurrency(getValues("total_price"))}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm="4">
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Order Details
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Created Date :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {getValues("date")}
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Customer :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {getValues("customer")?.label}
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Payment :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {getValues("payment_method")}
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        {getValues("is_online") !== 1 && (
                          <>
                            <Col sm="6" className="py-1">
                              <label style={{ fontWeight: "bolder" }}>
                                Customer PO No :
                                <label
                                  style={{
                                    marginLeft: "5px",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {getValues("customer_po_no")}
                                </label>
                              </label>
                            </Col>

                            <Col sm="6" className="py-1">
                              <label style={{ fontWeight: "bolder" }}>
                                Factory PO No :
                                <label
                                  style={{
                                    marginLeft: "5px",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {getValues("factory_purchase_order_no")}
                                </label>
                              </label>
                            </Col>
                          </>
                        )}

                        <Col sm="6" className="py-1">
                          <label style={{ fontWeight: "bolder" }}>
                            Memo :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("memo")}
                            </label>
                          </label>
                        </Col>

                        <Col sm="6" className="py-1">
                          <label style={{ fontWeight: "bolder" }}>
                            Currency :
                            <label
                              style={{
                                marginLeft: "5px",
                                fontWeight: "lighter",
                              }}
                            >
                              {getValues("currency")?.code}{" "}
                              {getValues("currency").symbol && (
                                <>({getValues("currency").symbol})</>
                              )}
                            </label>
                          </label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Shipping Address
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Company Name :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {getValues("shippingAddress")?.company}
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Address :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                <p>{getValues("shippingAddress")?.address1},</p>
                                <p className="mt-0">
                                  {getValues("shippingAddress")?.city},{" "}
                                  {getValues("shippingAddress")?.country}
                                </p>
                                <p className="mt-0">
                                  {getValues("shippingAddress")?.zip}
                                </p>
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Contact No. :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {getValues("shippingAddress")?.phone}
                              </label>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="12" className="py-1">
                          <Row>
                            <Col sm="5">
                              <label style={{ fontWeight: "bolder" }}>
                                Billing Address :
                              </label>
                            </Col>
                            <Col sm="7">
                              <label
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "lighter",
                                }}
                              >
                                <p>{getValues("billingAddress")?.address1},</p>
                                <p className="mt-0">
                                  {getValues("billingAddress")?.city},{" "}
                                  {getValues("billingAddress")?.country}
                                </p>
                                <p className="mt-0">
                                  {getValues("billingAddress")?.zip}
                                </p>
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody style={{ padding: "24px" }}>
                      <Row>
                        <Col sm="12">
                          <Label
                            style={{
                              fontWeight: "bolder",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            Commodity Information
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {orderCommodities(getValues("ordered_products")).map(
                            (item, i) => (
                              <p key={i}>
                                {item.commodity} {item.quantity} Cost{" "}
                                {getValues("currency")?.symbol}
                                {item.amount}
                              </p>
                            )
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm="12">
              {discussionSection && (
                <Card>
                  <CardBody
                    style={{ paddingTop: "24px", paddingBottom: "24px" }}
                  >
                    <Row>
                      <Col sm="6">
                        <Label
                          style={{
                            fontWeight: "bolder",
                            fontSize: "16px",
                            paddingBottom: "10px",
                          }}
                        >
                          Discussion
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <SaleOrderDiscussion
                          salesOrderId={getValues("id")}
                          salesOrderComments={getValues("comments")}
                          isInputShow={true}
                          salesOrderStatus={currentStatus}
                          // salesOrderStatus={getValues("status")?.id}
                          setStatusName={handleStatusNameUpdate}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
            <CloseSalesOrderModal
              orderSetValue={setValue}
              orderTrigger={trigger}
              orderGetValues={getValues}
              handleConfirm={handleConfirm}
              setSelectedReason={setSelectedReason}
              componentName={"Sales Order"}
            ></CloseSalesOrderModal>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([
  {
    order: draftOrderReducer,
  },
])(ViewSalesOrder);
