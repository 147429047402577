import React, {useState} from 'react';
import ModalFormSubmit from "../../../../../share-components/form/modalFormSubmit";
import ProductCategoryForm from "../../../../manage-product/product-setting/product-category/productCategoryForm";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from "react-redux";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {setDutyDialog} from "../../../store/salesOrderSlice";

const defaultValues = {
    type: 1,
    value: '',
    reason: '',
};
const schema = yup.object().shape({
    type: yup.string().required('You must select typ'),
    value: yup.number().typeError('must be a number').required('this field is required').min(1,"should be grater than 0"),
    reason: yup.string().required('You must enter name').test('len', 'Must be lee than 15 characters', val => val.length < 15),

});


function SalesOrderDuty(props) {
    const dispatch = useDispatch();
    const {orderSetValue,orderTrigger,orderGetValues}=props;
    const [buttonDisable, setButtonDisable] = useState(false);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;
    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        orderSetValue('duty',data);
        orderTrigger('duty');
        setButtonDisable(false);
        closeDialog();

    }
    const closeDialog = () => {
        const obj = {props: {isOpen: false},type:'new',data:""};
        dispatch(setDutyDialog(obj));

    }
    const orderState = useSelector(({order}) => order.salesOrder);


    return (
        <ModalFormSubmit
            defaultValues={defaultValues}
            schema={schema}
            onSubmitForm={onSubmitForm}
            reset={reset}
            formState={formState}
            handleSubmit={handleSubmit}
            title='Duty'
            selector='duty'
            isUseSelector
            dialog={orderState.dutyDialogProps}
            closeDialog={closeDialog}
            buttonDisable={buttonDisable}
        >

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Type</Label>
                        <Input type="select" name="select" onChange={(e)=>{
                            setValue('type',e.target.value);
                        }}   className={`form-control ${errors.type ? 'is-invalid' : ''}`} defaultValue={getValues('type')} >
                            <option value="1">Amount</option>
                            <option value="2">Percentage</option>
                        </Input>
                        <div className="invalid-feedback">{errors.type?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Value</Label>
                        <input name="value" type="text" {...register('value')}
                               className={`form-control ${errors.value ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.value?.message}</div>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="col-form-label">Reason</Label>
                        <input name="reason" type="text" {...register('reason')}
                               className={`form-control ${errors.reason ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.reason?.message}</div>

                    </FormGroup>
                </Col>
            </Row>
            {orderGetValues('duty')&&<Row>
                <Col md={12}>
                    <button className="btn btn-danger btn-sm float-right" onClick={()=>{
                        orderSetValue('duty',"");
                        orderTrigger('duty');
                        closeDialog();
                    }
                    }>Remove <i className="fa fa-trash"></i></button>
                </Col>
            </Row>}

        </ModalFormSubmit>
    );
}

export default SalesOrderDuty;