import React, {useState} from 'react';
import {Col, Row} from "reactstrap";



function InventoryVariantOption(props) {
    const {inventoryState,setValue,getValues,onClickHandler}=props;
    const [isChange, setIsChange] = useState(false);

    return (
               <>
                   {inventoryState.variant &&
                       <Row>
                           <Col sm="12">
                               <Row className="mt-3">
                                   <Col md="11">
                                       {(inventoryState.variant.locations).map((item, i) => <a
                                           onClick={() => {
                                                onClickHandler('location_id', item.id);
                                                setIsChange(!isChange);
                                           }}
                                           className={`${item.id == getValues('location_id') ? 'btn-dark' : 'btn-outline-dark'} btn-sm btn col-md-3 mr-1`}
                                           key={i}>{item.name} ({item.quantity})</a>)}
                                   </Col>

                               </Row>
                               {!inventoryState.settings.is_stock_count_mode &&    <>
                               <Row className="mt-2">
                                   <Col md={11}>
                                       {(inventoryState.variant.colour).map((item, i) => <a
                                           className={`${item.id == getValues('colour_id') ? 'btn-success' : 'btn-outline-success'} btn-sm btn col-md-3 mr-1`}
                                           onClick={() => {
                                               onClickHandler('colour_id', item.id);
                                           }}
                                           key={'colour-' + i}>{item.name} ({item.quantity})</a>)}
                                   </Col>

                               </Row>
                               <Row className="mt-2">
                                   <Col md={11}>
                                       {(inventoryState.variant.size).map((item, i) => <a
                                           className={`${item.id == getValues('size_id') ? 'btn-info' : 'btn-outline-info'} btn-sm btn col-md-2 mr-1`}
                                           onClick={() => {
                                               onClickHandler('size_id', item.id);
                                           }}
                                           key={'size' + i}>{item.name} ({item.quantity}) </a>)}
                                   </Col>

                               </Row>
                               </>}
                           </Col>
                       </Row>
                   }
               </>
    );
}

export default InventoryVariantOption;