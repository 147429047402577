import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getSellerListApi} from "../../../services/seller";


export const getSellerList = createAsyncThunk('sellerList/getSellerList', async (data, {dispatch})=>{
    const response = await getSellerListApi();
    dispatch(setTableRowCount(response.response.pagination.total));
    return response.response.data;
});

export const sellerListAdapter = createEntityAdapter({});

export const {selectAll: getAllSellerList, selectById: getAllSellerById} = sellerListAdapter.getSelectors(
    state => state.seller.sellerListData
);

export const sellerListSlice = createSlice({
    name: 'sellerList',
    initialState: sellerListAdapter.getInitialState({
        sellerListDataTableRow:0,
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.sellerListDataTableRow = action.payload
        },
    },
    extraReducers: {
        [getSellerList.fulfilled] : sellerListAdapter.setAll
    }
});

export const {setTableRowCount} = sellerListSlice.actions;

export default sellerListSlice.reducer