import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Form } from "reactstrap";

import { Link, useHistory, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import withReducer from "../../../../../store/withReducer";
import salesOrderReturnReducer from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PreLoader from "../../../../../share-components/ui/preLoader";
import salesOrderReturn, {
  getAllReturnReasons,
  setReturnSettings,
  editPendingReturnRequest,
  updateSalesOrderReturn,
} from "../../../store/saleOrderReturnSlice";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import Breadcrumb from "../../../../../layout/breadcrumb";
import SalesOrderPendingReturnJqx from "./content/salesOrderPendingReturnJqx";

const defaultValues = {
  id: "",
  ordered_products: [],
};
const schema = yup.object().shape({
  // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
  // shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
});

const dataMenu = [
  {
    type: 0,
    text: "Pending Return",
  },
  {
    type: 1,
    text: "Form",
    link: "",
  },
];
const SalesOrderPendingReturnForm = (props) => {
  const { id, order_id, slug } = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const returnState = useSelector(({ order }) => order.salesOrderReturn);
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;

  useEffect(() => {
    dispatch(getAllReturnReasons()).then((res) => {
      if (!res.error) {
        dispatch(setReturnSettings(res.payload));
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      getPendingInvoiceOrder();
    }
  }, [id]);

  const getPendingInvoiceOrder = () => {
    setLoading(true);
    dispatch(editPendingReturnRequest(id)).then((res) => {
      if (!res.error) {
        setValue("ordered_products", res.payload);
        setLoading(false);
      }
    });
  };

  const onSubmit = (data) => {
    setIsSaving(true);
    const returnData = {
      id: id,
      product: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            approved_quantity: item.approve_quantity,
            is_restocked: item.is_restock,
            is_warehouse_declined: item.is_declined,
          };
        }),
    };

    dispatch(updateSalesOrderReturn({ ...returnData })).then((res) => {
      if (!res.error) {
        history.push("/order/pending-return");
        setIsSaving(false);
      }
    });
  };

  return (
    <>
      <Breadcrumb data={dataMenu} title="Pending Return" />
      <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                {loading ? (
                  <>
                    <PreLoader></PreLoader>
                  </>
                ) : (
                  <SalesOrderPendingReturnJqx
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    returnState={returnState}
                    orderedProducts={getValues("ordered_products")}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {getValues("ordered_products").filter(function (v, i) {
          return v.is_selected;
        }).length > 0 && (
          <Row className="py-4">
            <Col md={12}>
              <div className="float-right">
                <ButtonLoader
                  color="secondary"
                  value="1"
                  btntext="Save"
                  disabled={isSaving}
                ></ButtonLoader>
              </div>
            </Col>
          </Row>
        )}
        ;
      </Form>
    </>
  );
};

export default withReducer([{ salesOrderReturn: salesOrderReturnReducer }])(
  SalesOrderPendingReturnForm
);
