import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addServiceItemApi,
  deleteServiceItemApi,
  getServiceItemsApi,
  updateServiceItemApi,
} from "../../../services/manage-product/setting/serviceItemService";

export const getAllServiceItems = createAsyncThunk(
  "service-item/getServiceItems",
  async (filters, { dispatch }) => {
    const response = await getServiceItemsApi(filters);
    return response.response;
  }
);

export const addServiceItem = createAsyncThunk(
  "service-item/addServiceItem",
  async (serviceItem, { dispatch, getState }) => {
    try {
      const response = await addServiceItemApi(serviceItem);
      const data = await response.response;
      dispatch(getAllServiceItems());
      toast.success("Service Item added successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateServiceItem = createAsyncThunk(
  "service-item/updateServiceItem",
  async (serviceItem, { dispatch, getStatus }) => {
    try {
      const response = await updateServiceItemApi(serviceItem, serviceItem.id);
      const data = await response.response;
      dispatch(getAllServiceItems());
      toast.success("Updated Service Item successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteServiceItem = createAsyncThunk(
  "service-item/deleteServiceItem",
  async (id, { dispatch, getStatus }) => {
    try {
      const response = await deleteServiceItemApi(id);
      const data = await response.response;
      dispatch(getAllServiceItems());
      toast.success("Deleted Service Item successfully");
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const serviceItemAdapter = createEntityAdapter({});

export const {
  selectAll: selectServiceItem,
  selectById: selectServiceItemById,
} = serviceItemAdapter.getSelectors((state) => state.product.serviceItem);

console.log(serviceItemAdapter);

const serviceItemSlice = createSlice({
  name: "serviceItem",
  initialState: serviceItemAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "ServiceItem",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setServiceItemSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "ServiceItem",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAllServiceItems.fulfilled]: serviceItemAdapter.setAll,
  },
});

export const {
  setServiceItemSearchText,
  openNewDialog,
  closeNewDialog,
  openEditDialog,
  closeEditDialog,
} = serviceItemSlice.actions;

export default serviceItemSlice.reducer;
