import React from "react";
import {useDispatch} from "react-redux";
import {closeImportProductDialog, downloadSampleCsv, setProductFilter} from "../store/prodcutSlice";
import {Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import AsyncSelect from "react-select/async";
import DropZone from "../../../share-components/upload/dropzone";
import Typography from "@material-ui/core/Typography";


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    }),
    menuPortal: base => ({...base, zIndex: 1000})
};


export default function ImportProductForm(props) {

    const {
        dialog,
        size = 'lg',
        productState,
        sellerPromiseOption,
        handleSubmit,
        onSubmitForm,
        handleFileInputChange,
        submitBtnSaveRef,
        importProductTrigger,
        setIsSeller,
        isSeller,
        setValue,
        buttonDisable,
        uploadFile,
        errors



    } = props;

    const dispatch = useDispatch();

    const closeModalDialog = () => {
        dispatch(closeImportProductDialog());
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...productState.importProductDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    Import Products
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmitForm)}>
                        <Row className="ml-2 mt-3 mb-3">
                            <Col md={12}>
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    defaultOptions
                                    // defaultValue={getValues('type')}
                                    loadOptions={sellerPromiseOption}
                                    // error={errors.type}
                                    onChange={(e) => {
                                        setValue('seller', e)
                                        // importProductTrigger('seller');
                                        setIsSeller(!!e)
                                    }}
                                    className={` ${errors.seller ? 'is-invalid' : ''}`}

                                />
                                <div className="invalid-feedback">{errors.seller?.message}</div>
                            </Col>
                            <Col md={12} className="mt-4">
                            <div className="product-box">
                                <div className="product-img pt-3 pb-3">
                                    <DropZone
                                        disabled={!isSeller}
                                        extension={".csv"}
                                        onChangeFile={handleFileInputChange}></DropZone>
                                </div>
                                {uploadFile ? (

                                    <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>

                                ) : (
                                    ''
                                )}
                            </div>
                            </Col>
                            <Col md={9}></Col>
                            <Col md={3} className="mt-4">
                                <button ref={submitBtnSaveRef} onClick={() => {
                                }} className="btn btn-success btn-block">Import
                                </button>
                            </Col>
                            <Col md={3}></Col>
                        </Row>
                    </Form>
                    <ModalFooter className="mt-2">
                        <a href="#" onClick={(e)=>{
                            const outputFilename = `sample.csv`;
                            dispatch(downloadSampleCsv()).then(res=>{
                                const url = window.URL.createObjectURL(new Blob([res.payload]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', outputFilename);
                                document.body.appendChild(link);
                                link.click();
                            })
                        }} style={{color: "black", fontWeight: "normal"}}><i className="fa fa-download"
                                                                                      aria-hidden="true"></i>&nbsp;Download Sample CSV</a>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}