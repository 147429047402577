import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getSalesOrderReturnItemsApi,
  salesOrderRefundApi,
  sendReturnInvoiceItemApi,
  downloadSalesOrderCreditNoteXeroPdfApi,
  editSalesOrderReturnApi,
  showSalesOrderReturnCreditNotesApi,
  indexSalesOrderReturnCreditNotesApi,
} from "../../../services/manage-order/saleOrderReturnService";

export const getReturnItems = createAsyncThunk(
  "sales-order/getReturnItems",
  async (filters, { dispatch }) => {
    const response = await getSalesOrderReturnItemsApi(filters);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
  }
);

export const orderRefundData = createAsyncThunk(
  "sales-order/orderRefundData",
  async (data, { dispatch }) => {
    const response = await salesOrderRefundApi(data.id);
    return response.response;
  }
);

export const sendReturnItemUpdate = createAsyncThunk(
  "sales-order/sendReturnItemUpdate",
  async (data, { dispatch }) => {
    try {
      const response = await sendReturnInvoiceItemApi(data);
      toast.success(`Return Sent Successfully`);
      return response.response;
    } catch (e) {
      toast.error("Failed to process request");
      return Promise.reject(e);
    }
  }
);

export const downloadSalesOrderCreditNotePdf = createAsyncThunk(
  "sales-order/downloadCreditNotePdf",
  async (data, { dispatch }) => {
    const response = await downloadSalesOrderCreditNoteXeroPdfApi(
      data.id,
      data.params
    );
    return response.response;
  }
);

export const editSalesOrderReturn = createAsyncThunk(
  "sales-order/return-edit",
  async (id, { dispatch }) => {
    const response = await editSalesOrderReturnApi(id);
    return response.response;
  }
);

export const showSalesOrderReturnCreditNotes = createAsyncThunk(
  "sales-order/return-credits-notes",
  async (id, { dispatch }) => {
    const response = await showSalesOrderReturnCreditNotesApi(id);
    return response.response;
  }
);

export const indexSalesOrderReturnCreditNotes = createAsyncThunk(
  "sales-order/index-credits-notes",
  async (id, { dispatch }) => {
    const response = await indexSalesOrderReturnCreditNotesApi(id);
    return response.response;
  }
);

const salesOrderReturnItemAdapter = createEntityAdapter({});

export const {
  selectAll: selectAllReturnItems,
  selectById: selectReturnItemById,
} = salesOrderReturnItemAdapter.getSelectors(
  (state) => state.order.saleOrderReturnItem
);

const saleOrderReturnItemSlice = createSlice({
  name: "saleOrderReturnItem",
  initialState: salesOrderReturnItemAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      return_reasons: [],
    },
    refundModal: {
      props: {
        open: false,
      },
      data: null,
    },
    refundDetail: {
      invoice_id: "",
      type: "",
      status: "",
      status_date: "",
      return_date: "",
      seller: "",
      order_id: "",
      sku: "",
      invoice_quantity: "",
      customer_return_quantity: "",
    },
    filter: [],
  }),
  reducers: {
    setSalesOrderReturnFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setReturnSettings: (state, action) => {
      state.settings = action.payload;
    },
    setRefundModal: (state, action) => {
      state.refundModal = action.payload;
    },
    setRefundData: (state, action) => {
      state.refundDetail = action.payload;
    },
    openActionDialog: (state, action) => {
      state.actionDialog = {
        component: "Action",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
        modalType: action.payload.dat.type,
      };
    },
  },
  extraReducers: {
    [getReturnItems.fulfilled]: salesOrderReturnItemAdapter.setAll,
    // [getReturnItemRequest.fulfilled]: salesOrderReturnItemAdapter.setAll,
  },
});

export const {
  setTableRowCount,
  setOrderReturn,
  setFromSettings,
  setReturnSettings,
  setRefundModal,
  setRefundData,
} = saleOrderReturnItemSlice.actions;

export default saleOrderReturnItemSlice.reducer;
