import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Nav, Row} from "reactstrap";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import {mapCategoriesResponseToTable, mapSearchAutoSuggestion} from "../../../../utils/mapper";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import productReducer from "../../store";
import BinForm from "./binForm";
import {
    addBin,
    closeEditDialog,
    deleteBin,
    getAllBins,
    openEditDialog,
    openNewDialog,
    selectBin,
    updateBin
} from "../../store/binSlice";
import {
    typeAutoSuggestionApi,
} from "../../../../services/manage-product/setting/binService";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import {binResponseToTable} from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";
import PreLoader from "../../../../share-components/ui/preLoader";

const defaultValues = {
    id: '',
    name: '',
    capacity: '',
    box_type_id: ''
};

const dataMenu = [
    {
        type: 0,
        text: 'Products',
    },
    {
        type: 1,
        text: 'Bin Management',
        link: ''
    },
    {
        type: 2,
        text: 'Bin Location',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('You must enter a bin name'),
});

const Bin = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [capacity, setCapacity] = useState([]);
    const [boxType, setBoxType] = useState([]);
    const [loading, setLoading] = useState(true);
    const bins = useSelector(selectBin);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);

    useEffect(() => {
        const data = {
            name,
            capacity,
            boxType
        }
        dispatch(getAllBins({...data}, 'season')).then(res=>{
            if (!res.error)
                setLoading(false);
        });

    }, [dispatch, name, capacity, boxType]);

    const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
        const data = {
            value: inputValue
        }
        setTimeout(() => {
            resolve(mapSearchAutoSuggestion(typeAutoSuggestionApi(data)))
        });
    });

    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;

    const onSubmitForm = (dialog, data) => {
        // data.box_type_id=data.box_type_id.value;
        const formData = {
            id:data.id,
            name: data.name,
            capacity: data.capacity,
            box_type_id: data.box_type_id[0]?data.box_type_id[0].value:data.box_type_id.value,
        };
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(addBin({...formData})).then(res => {
                setButtonDisable(false);
                console.log(res)
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateBin({...formData})).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };

    const closeDialog = () => {
        dispatch(closeEditDialog());
    };

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteBin(row.id))
    }

    let btnText = '';
    let publishValue = 0;
    let saveValue = 0;
    if (getValues) {
        if (getValues('publish_at') == 1) {
            btnText = 'Save & Unpublish';
            publishValue = 0;
            saveValue = 1;
        } else {
            btnText = 'Save & Publish';
            publishValue = 1;
            saveValue = 0;
        }
    }

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Capacity',
            selector: row => row.capacity,
            sortable: true,
            left: true,
        },
        {
            name: 'Box Type',
            selector: row => row.box_type_id[0].label,
            sortable: true,
            left: true,
        }
    ]

    return (
        <Fragment>
            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></>:<>
                    <Breadcrumb data={dataMenu} title="Bin Location"/>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">
                                    {(currentUser.permission).includes('Create Product Types') ?
                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                       onClick={() => {
                                                           dispatch(openNewDialog())
                                                       }}
                                                    > <i className="fa fa-plus"></i> Add Bin Location</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                        :''}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">
                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                                   type="text"
                                                   className="form-control border"
                                                   placeholder="Search By Bin Name"/>
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive product-table mt-4">
                                                <CommonDataTable
                                                    headerColumns={tableColumns}
                                                    gridData={binResponseToTable(bins)}
                                                    pagination
                                                    onEdit={(currentUser.permission).includes('Update Product Types') ? onEdit:''}
                                                    onDelete={(currentUser.permission).includes('Delete Product Types') ? onDelete:''}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                         </>
                }
            </Container>
            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Bin Location'
                selector='bin'
                dialog={({product}) => product.bin.addDialog}
                closeDialog={closeDialog}
                publish
                setValue={setValue}
                getValues={getValues}
                btnText={btnText}
                publishValue={publishValue}
                saveValue={saveValue}
                buttonDisable={buttonDisable}
            >
                <BinForm control={control} errors={errors} register={register} setValue={setValue} 
                typePromiseOption={typePromiseOption} trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([
    {
        bin: productReducer
    }
])(Bin);