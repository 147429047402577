import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PreLoader from "../../../../share-components/ui/preLoader";
import {
    downloadSalesOrderInvoicePdf,
  setSalesOrderPendingPackingDialogProps,
  showSalesOrderPendingPacking,
} from "../../store/salesOrderSlice";
import { downloadSalesOrderXeroInvoicePdf } from "../../store/saleOrderInvoiceSlice";

const PendingPackingModal = () => {
  const dispatch = useDispatch();
  const [dropdownStatusOpenArray, setDropdownStatusOpenArray] = useState([]);
  const [statusName, setStatusName] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [loading, isSetLoading] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const salesDialogProps = useSelector(
    (state) => state.order.salesOrder.salesOrderPendingPackingDialogProps
  );
  const order_id = salesDialogProps.data.id;

  const processPDFDownload = (name, isCommercial, id, invoice_no) => {
    const data = {
      id: id,
      params: {
        is_commercial: isCommercial,
      },
    };
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processXeroPDFDownload = (name, order_id, id, invoice_no) => {
    const orderId = order_id;
    console.log("Processing ", id);
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderXeroInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${invoice_no}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const toggleStatusDropdown = (index) => {
    const newDropdownStatusOpenArray = [...dropdownStatusOpenArray];
    newDropdownStatusOpenArray[index] = !newDropdownStatusOpenArray[index];
    setDropdownStatusOpenArray(newDropdownStatusOpenArray);
  };

  const downloadInvoice = (name, isCommercial, id, invoice_no) => {
    processPDFDownload(name, isCommercial, id, invoice_no);
  };

  const downloadXeroInvoice = (order_id, id, invoice_no) => {
    processXeroPDFDownload("Xero Invoice", order_id, id, invoice_no);
  };

  const toggleModal = () => {
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false }, type: "new", data: "" };
    dispatch(setSalesOrderPendingPackingDialogProps(obj));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (order_id) {
        isSetLoading(true);

        try {
          const indexRes = await dispatch(
            showSalesOrderPendingPacking(order_id)
          );

          if (!indexRes.error) {
            console.log("Success Data:", indexRes);
            setStatusName(indexRes.payload.status || "");
            setInvoices(indexRes.payload.invoices || "");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          isSetLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, order_id]);

  return (
    <Modal
      isOpen={salesDialogProps.props.isOpen}
      toggle={toggleModal}
      size="xl"
    >
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggleModal}>
            <p
              style={{ fontSize: "20px" }}
            >{`Sales Order #${salesDialogProps.data.order_no}`}</p>
            <span
              style={{
                fontWeight: "lighter",
                color: "rgb(115, 115, 115)",
                fontSize: "16px",
              }}
            >
              Status :{" "}
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {statusName}
              </span>
            </span>
          </ModalHeader>
          <ModalBody>
            {salesDialogProps.data && (
              <>
                <Container fluid={true}>
                  <div className="pb-2">
                    <span style={{ fontSize: "18px", fontWeight: "600" }}>
                      Download Invoices
                    </span>
                  </div>
                  <div className="d-flex mb-2 px-2">
                    {invoices && (
                      <>
                        {invoices.map(
                          (invoice, index) => (
                            <Dropdown
                              key={invoice.id}
                              isOpen={dropdownStatusOpenArray[index] || false}
                              toggle={() => toggleStatusDropdown(index)}
                            >
                              <DropdownToggle
                                color="success"
                                className="btn btn-sm btn-success"
                                style={{ height: 38, marginRight: 8 }}
                              >
                                {invoice.invoice_no + "  "}
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                ></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    downloadInvoice(
                                      "Invoice",
                                      0,
                                      invoice.id,
                                      invoice.invoice_no
                                    );
                                  }}
                                >
                                  Local Invoice
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    downloadInvoice(
                                      "Commercial Invoice",
                                      1,
                                      invoice.id,
                                      invoice.invoice_no
                                    );
                                  }}
                                >
                                  Commercial Invoice
                                </DropdownItem>
                                {invoice.xero_id && (
                                  <DropdownItem
                                    onClick={() => {
                                      downloadXeroInvoice(
                                        salesDialogProps.data.id,
                                        invoice.id,
                                        invoice.invoice_no
                                      );
                                    }}
                                  >
                                    Xero Invoice
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          )
                        )}
                      </>
                    )}
                  </div>
                </Container>
              </>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default PendingPackingModal;
