import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from "reactstrap";
import React, {Fragment} from "react";
import {useDispatch} from "react-redux";


export const ReturnItem = (props) => {
    const {orderState,orderId}=props;
    const dispatch = useDispatch();
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h6 ribbon-dark">Return Items from Order {orderState.data?orderState.data.order_name:"-"}</div>
                        <div className="table-responsive">
                            <Table style={{width:'100%'}}>
                                <thead>
                                    <tr className="table-dark">
                                        <th  style={{ whiteSpace: 'nowrap',width:'30%' }}>Product</th>
                                        <th  style={{ whiteSpace: 'nowrap' ,width:'15%'}}>Admin Reason</th>
                                        <th  style={{ whiteSpace: 'nowrap',width:'15%'  }}>Customer Reason</th>
                                        <th  style={{ whiteSpace: 'nowrap' ,width:'10%' }} >Status</th>
                                        <th  style={{ whiteSpace: 'nowrap' ,width:'10%', textAlign: 'right' }} >Quantity</th>
                                        <th  style={{ whiteSpace: 'nowrap' ,width:'10%', textAlign: 'right' }}>Price (£)</th>
                                        <th  style={{ whiteSpace: 'nowrap',width:'10%', textAlign: 'right' }}>Total (£)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {orderState.list?
                                <tr>
                                    <th>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img className="img-fluid" src={orderState.list.product} width="40" alt=""/>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <p className={'font-primary'}>{orderState.list.name}</p>
                                                </div>
                                                <div className="row">
                                                    <p>{orderState.list.colour}/{orderState.list.size}</p>
                                                </div>
                                                <div className="row">
                                                    <p>{orderState.list.sku}</p>
                                                </div>

                                            </div>

                                        </div>
                                    </th>
                                    <td><div className="mt-3">{orderState.list.reason}</div></td>
                                    <td><div className="mt-3">{orderState.list.customer_reason}</div></td>
                                    <td><div className="mt-3">{orderState.list.status}</div></td>
                                    <td><div className="mt-3" style={{float: 'right'}}>{orderState.list.qty}</div></td>
                                    <td><div className="mt-3" style={{float: 'right'}}>{orderState.list.price}</div></td>
                                    <td><div className="mt-3" style={{float: 'right'}}>{orderState.list.total_price}</div></td>
                                </tr>:null
                                }
                                <tr></tr>
                                <tr>
                                    <td ></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="font-weight-bold">Sub Total:</td>
                                    <td><div style={{float: 'right'}}>{orderState.list?orderState.list.total_price:""}</div></td>
                                </tr>
                                <tr>

                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td className="font-weight-bold">Shipping Charge:</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td style={{borderStyle:"none"}}></td>
                                    <td className="font-weight-bold">Total:</td>
                                    <td><div style={{float: 'right'}}>{orderState.list?orderState.list.total_price:""}</div></td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};