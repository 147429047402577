import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Row, Form, Input} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {DateRangePicker} from "rsuite";
import moment from "moment";
import orderReducer from "../store";
import {getGroupReturn, selectGroupReturn} from "../store/groupReturnSlice";
import {useHistory, Link} from "react-router-dom";
import Select from "react-select";

const {afterToday} = DateRangePicker;


function GroupReturn() {

    const dataMenu = [
        {
            type: 0,
            text: 'Group Return',
        }
    ];

    const dispatch = useDispatch();
    const groupReturnState = useSelector(({order}) => order.groupReturn);
    const backInRequestReport = useSelector(selectGroupReturn);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [name, setName] = useState(null);
    const [platform, searchPlatform] = useState(null);
    const [timer, setTimer] = useState(null);
    const [page, setPage] = useState(1);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [loading, isSetLoading] = useState(false);
    const [startDate, endDate] = dateRange;
    const [pageSize, setPageSize] = useState(10);
    const [payment_method, setPaymentMethod] = useState(null);
    let history = useHistory()


    let tableColumns = [
       
        {
            name: 'Order Id',
            selector: row => row.name,
            cell: (row) =><Link className="text-primary" target="_blank" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/order/${row.id}/view`}>{row.name}</Link>,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Order date',
            selector: row => row.date,
            sortable: true,
            center: false,
        },
        {
            name: 'No of items',
            selector: row => row.count,
            sortable: true,
            center: false,
        },
        {
            name: 'Platform',
            selector: row => row.plat_form,
            sortable: true,
            center: false,
        },
        {
            name: 'Payment Method',
            selector: row => row.payment_method,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: (row) => <><a onClick={() => {
                history.push({
                    pathname: '/order/order-return-details/'+row.id,
                })
            }}
                                    className={`btn btn-sm p`}
                                    title="group return"> <i className="fa fa-mail-reply"/>
            </a>
               </>,
            sortable: true,
            center: false,
        }
        
    ];

    useEffect(()=> {
        getData();
    },[dispatch, startDate, endDate,page, pageSize, name,platform, payment_method]);

    const getData = () =>{
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                name,
                page:page,
                pageSize:pageSize,
                plat_form:platform,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                payment_method:payment_method
            }
            console.log("new data load",data);
            dispatch(getGroupReturn(data)).then(res=>{
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

    const paymentMethodOptions = [
        {value: 'shopify_payments ', label: 'Shopify Payments'},
        {value: 'manual', label: 'Manual'},
    ];

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

  return (
    <Fragment>

        <Breadcrumb title="Group Return" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
                <div className="feature-products">
                    <Row>
                        <Col sm="3">
                            <div className="dashboard-datepicker">
                                <DatePicker
                                    dateRange={dateRange}
                                    dateRangeChange={(date) => {
                                        setDateRange(date)
                                    }}
                                    defaultValue={[TodayDate, sevenDays]}
                                    disabledDate={afterToday()}
                                />
                            </div>
                        </Col>

                        <Col sm={3}>
                            <Form>
                                <FormGroup className="m-0">
                                    <Input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                searchPlatform(e.target.value);

                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search Plat form"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col md={3}>
                                <FormGroup>
                                    <Select
                                        placeholder={<div>Payment Method</div>}
                                        className="select2-filter"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles}
                                        options={paymentMethodOptions}
                                        onChange={(e) => {
                                            setPaymentMethod(e ? e.value : null)
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={3}>
                            <Form>
                                <FormGroup className="m-0">
                                    <Input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setName(e.target.value);

                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" className="mt-3">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={backInRequestReport}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={groupReturnState.groupReturnTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>

    </Fragment>
  )
}

export default withReducer([{order: orderReducer}])(GroupReturn);
