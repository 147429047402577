import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, FormGroup, Label, Button} from 'reactstrap';
import Breadcrumb from "../../../../layout/breadcrumb";
import {mapSkuSearchAutoSuggestion} from "../../../../utils/mapper";
import {inventorySkuApi,} from "../../../../services/manage-product/productService";
import withReducer from "../../../../store/withReducer";
import {useDispatch, useSelector} from "react-redux";
import inventoryReducer from "../../store";
import {getSettings, getSkuDetails, setInventorySettings, setVariantData} from "../../store/inventorySlice";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import InventoryVariantOption from "./content/inventoryVariantOption";
import InventoryVariantDetail from "./content/inventoryVariantDetail";
import AdjustmentForm from "./content/adjustmentForm";
import {findDuplicates, findIndexesByProperty} from "../../../../utils/common";
import {useHistory, useLocation} from "react-router-dom";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

yup.addMethod(yup.mixed, "minimumQty", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError,parent} = this;
        let isValid = true;

        const binAvailable=parent.bin?parent.bin.available:0;
        if((binAvailable+value) <0 ){
            isValid=false;
        }
        return (
            (isValid) ||
            createError({path, message: 'min qty is '+binAvailable})
        );
    });
});
yup.addMethod(yup.mixed, "committedQty", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError,parent} = this;
        let isValid = true;
        let errors=[];
        console.log(value);
        value.map((i,ind)=>{
            if(i.bin && (i.quantity != null)) {
                const foundObject = (parent.committed_inventories).find(obj => obj.bin_number ===i.bin?.label);
                if(foundObject){
                    if(parent.type.id == 2){
                        if(Number(foundObject.quantity)+Number(i.quantity)<0){
                            const errorMessage = `${foundObject.quantity} is committed`;
                            errors.push(new yup.ValidationError(errorMessage, value, `inventories.[${ind}].quantity`));
                        }
                    }
                    // if(parent.type.id == 3){
                    //     console.log(Number(foundObject.quantity),Number(i.quantity),"Test")
                    //     if(Number(foundObject.quantity)>Number(i.quantity)) {
                    //         const errorMessage = `${foundObject.quantity} is committed`;
                    //         errors.push(new yup.ValidationError(errorMessage, value, `inventories.[${ind}].quantity`));
                    //     }
                    // }

                }
            }

        });

        if(errors.length>0) {
            throw new yup.ValidationError(errors);
        }

        return true;
    });
});

const dataMenu = [
    {
        type: 1,
        text: 'Inventory Adjustments',
        link: '/product/adjustment-Inventory'
    },
    {
        type: 0,
        text: 'Add Inventory Adjustments',
    }
];
const defaultValues = {
    variant: '',
    location_id: '',
    colour_id: '',
    size_id: '',
    product_id: '',
    type: '',
    reason:'',
    inventories: [{bin: '', quantity: ''}],
    committed_inventories:[],
    no_of_single_pack:0,
    pack_quantity:0,
    pack_inventory:[],
    is_bin_update:true

};

const schema = yup.object().shape({
    reason: yup.object().when('type', {
        is:(value) => value?.id==2 ||  value?.id==3, // Change this to the specific value triggering the condition
        then: yup.object().shape({
            id: yup.string(),
        }).nullable().required('You must select a reason.'),
        otherwise: yup.object().shape({
            id: yup.string(),
        }).nullable(),
    }),
    variant: yup.object().shape({
        id: yup.string(),
    }).nullable().required('You must select a variant'),
    inventories: yup.array().when('is_bin_update',{
        is:true,
        then:yup.array().of(yup.object().shape({
                quantity: yup.number().when('old_quantity', {
                    is: (value) => value==0,
                    then: (rule) => rule.integer('should be integer').typeError('this field is required').required('this field is required').max(999,"max value is 999").minimumQty("exceed min qty"),
                    otherwise: yup.number().integer('should be integer').nullable() // Allow null values
                        .transform((value, originalValue) => {
                            // Convert empty string to null
                            return originalValue === '' ? null : value;
                        }).typeError('Quantity must be a number or an empty string') .lessThan(yup.ref('old_quantity'), 'should be less then enter qty')
                }),
                bin: yup.object().shape({id: yup.string()}).nullable().required('this field is required')
            })
        ).test(
            'is-unique',
            'Name must be unique',
            (values,context) => {
                const bins = context.parent.inventories.map(item => (item.bin?item.bin.label:"")); // Get an array of all bin IDs
                const duplicateBins = findDuplicates(bins);
                let errors=[];
                if (duplicateBins.length > 0) {
                    duplicateBins.map((item, index) => {
                        const findIndexes=findIndexesByProperty(bins,item);
                        findIndexes.map((i,ind)=>{
                            const errorMessage = `Duplicate bin "${item}" found`;
                            errors.push(new yup.ValidationError(errorMessage, values, `inventories.[${i}].bin`));

                        });
                    });
                    if(errors.length>0) {
                        throw new yup.ValidationError(errors);
                    }

                    // Construct error message with details of duplicate bin IDs

                }
                return true; // Validation passed
            }
        ).committedQty("dadadad"),
        otherwise:yup.array().nullable()
    })
});



const InventoryAdjustmentForm = (props) => {
    const dispatch = useDispatch();
    const inventoryState = useSelector(({product}) => product.inventory);
    const history = useHistory()
    const [lastFilterValue, setLastFilterValue] = useState('');
    const inputRef = useRef(null);
    const ref = React.createRef();

    const [variant, setVariant] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const location = useLocation();

    useEffect(()=>{

        dispatch(setVariantData(null));
    },[window.location.href])
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {fields, append, remove} = useFieldArray({name: 'inventories', control})
    const {errors, isDirty} = formState;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param = searchParams.get('is_stock_count');;
        dispatch(getSettings({is_stock_count:param?param:1})).then(res => {
            dispatch(setInventorySettings(res.payload));
        });
    }, [dispatch]);

    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(mapSkuSearchAutoSuggestion(inventorySkuApi(inputValue)))
            });
        });

    const handleSearch = async (query) => {
        setIsLoading(true);
        try {
            // Simulate an asynchronous API call
            const response = await inventorySkuApi(query);
            const data =  response;
            setOptions(data.response); // Assuming the response contains an array of results
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getVariantData = (data,defaultData) => {
        dispatch(getSkuDetails(data)).then(res => {
            const payload=res.payload;
            const packQuantity= res.payload.pack?res.payload.pack.quantity:0;
            const valuesObj={
                variant: {id: res.payload.variant_id, sku: res.payload.sku},
                location_id: payload.location_id,
                colour_id: payload.colour_id,
                size_id: payload.size_id,
                product_id: payload.product_id,
                inventory_variant_id:payload.inventory_variant_id,
                type:inventoryState.settings.is_stock_count_mode? inventoryState.settings.types[1]:"",
                reason:inventoryState.settings.is_stock_count_mode? inventoryState.settings.reasons[0]:"",
                inventories: inventoryState.settings.is_stock_count_mode?[{bin:null,quantity:'',old_quantity: 0}]:[],
                pack_inventory:[],
                pack_quantity:packQuantity,
                total_pack:defaultData?defaultData.total_pack:0,
                no_of_single_pack:defaultData?defaultData.no_of_single_pack:0,
                is_bin_update:defaultData?defaultData.is_bin_update:true,
                is_default_data:defaultData?true:false,
                committed_inventories:payload.committed_bins,
                condition:[]
            }
            const sku={id: res.payload.variant_id, sku: res.payload.sku};
            resetData(valuesObj,payload,sku);
        });
    }
    const onClickHandler = (name, value) => {
        setValue(name, value);
        variantData();
    }

    const variantData=(defaultData=null)=>{

        getVariantData({
            location_id: getValues('location_id'),
            product_id: getValues('product_id'),
            colour_id: getValues('colour_id'),
            size_id: getValues('size_id')
        },defaultData)
    }

    const resetData=(values,data,sku)=>{
        setVariant(sku);
        reset(values);
        dispatch(setVariantData(data));
    }



    return (
        <Fragment>
            <div className='d-none d-sm-block'>
                <Breadcrumb  title="Inventory Adjustment" data={dataMenu}/>
            </div>

            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="mt-5">
                            <Col md="12">
                                <FormGroup>
                                    <AsyncTypeahead
                                        minLength={1}
                                        id="async-typeahead"
                                        filterBy={() => true}
                                        isLoading={isLoading}
                                        labelKey="sku" // Assuming your data has a 'name' key
                                        onSearch={handleSearch}
                                        onChange={(selected) => {
                                            getVariantData({id:selected[0]?.id});
                                        }}
                                        options={options}
                                        placeholder="Search..."
                                        key={variant ? variant.id : 0}
                                        defaultSelected={variant ? [variant] : []}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <p style={{color:"red"}}>{inventoryState.variant?.last_updated}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                { inventoryState.variant &&     <Row>
                    <Col  order={{ xs: 2, md: 1 }} md={8} className="order-sm-1 order-2">
                        <Row>
                            <Col sm="12">
                                { (!inventoryState.settings.is_stock_count_mode ||(inventoryState.variant.locations).length > 1) &&  <Card>
                                    <CardBody>
                                        {/*<div className="ribbon ribbon-clip ribbon-info ribbon-left mb-5">Product SKU*/}
                                        {/*</div>*/}

                                        <InventoryVariantOption
                                            inventoryState={inventoryState}
                                            setValue={setValue}
                                            getValues={getValues}
                                            onClickHandler={onClickHandler}
                                        ></InventoryVariantOption>

                                    </CardBody>
                                </Card>}
                                {inventoryState.variant &&
                                    <AdjustmentForm
                                        inventoryState={inventoryState}
                                        setValue={setValue}
                                        getValues={getValues}
                                        errors={errors}
                                        register={register}
                                        append={append}
                                        fields={fields}
                                        handleSubmit={handleSubmit}
                                        trigger={trigger}
                                        remove={remove}
                                        resetData={resetData}
                                        variantData={variantData}
                                        defaultValues={defaultValues}>
                                    </AdjustmentForm>
                                }
                            </Col>
                        </Row>

                    </Col>
                    {inventoryState.variant && <Col className="order-sm-2 order-1" order={{ xs: 1, md: 2 }} md={4}>
                        <InventoryVariantDetail
                            inventoryState={inventoryState}
                        ></InventoryVariantDetail>

                    </Col>}

                </Row>}

            </Container>
        </Fragment>
    );
}

export default withReducer([{inventory: inventoryReducer}])(InventoryAdjustmentForm);