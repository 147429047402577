import API from "../../utils/api";


export const getSalesOrderInvoicesApi = async (filters) => {
    const response = await API.get('order/invoice', { params: filters });
    return { response };
};
export const getPendingOrderInvoiceAPi = async (orderId) => {
  const response = await API.get(`order/invoice/${orderId}/pending-invoice`);
  return { response };
};

export const getInvoiceByOrderApi = async (data) => {
  const response = await API.get(`order/invoice/${data.id}/order`,{ params: data.params });
  return { response };
};
export const getInvoicePendingReturnApi = async (id) => {
    const response = await API.get(`order/sales-order/return/${id}/pending-return`);
    return { response };
};

export const addSalesOrderInvoiceApi = async (data) => {
    const response = await API.post('order/invoice', data);
    return { response };
};

export const editSaleOrderInvoiceApi = async (id) => {
    const response = await API.get(`order/invoice/${id}/edit`);
    return { response };
};

export const invoiceAutoSuggestionApi = async (text) => {
    const response = await API.get('order/invoice/auto-suggestion', {
        params: text,
    });
    return { response };
};

export const updateSalesOrderInvoiceApi = async (data, id) => {
    const response = await API.put(`order/invoice/${id}`, data);
    return { response };
};

export const getInvoiceCreateDataApi = async (id) => {
    const response = await API.get(`order/invoice/get-create-data`);
    return { response };
};

export const downloadSalesOrderInvoicePdfApi = async (id, data) => {
    const response = await API.get(`${id}/download/sales-order-invoice`, {
        "content-type": "application/pdf",
        responseType: "blob",
        params: data,
    });
    return { response };
};

export const downloadSalesOrderInvoiceXeroPdfApi = async (id, data) => {
    const response = await API.get(`order/invoice/${id}/xero-download`, {
        "content-type": "application/pdf",
        responseType: "blob",
        params: data,
    });
    return { response };
  };

export const downloadSalesOrderInvoiceRMAPdfApi = async (id, data) => {
    const response = await API.get(`order/sales-order/return/${id}/pdf`, {
        "content-type": "application/pdf",
        responseType: "blob",
        params: data,
    });
    return { response };
  };