import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addProductTypeApi,
  deleteProductTypeApi,
  getProductTypesApi,
  updateProductTypeApi,
} from "../../../services/manage-product/setting/productTypeServie";
import { toast } from "react-toastify";

export const getAllProductTypes = createAsyncThunk(
  "productType/getProductType",
  async (filters, { dispatch }) => {
    const response = await getProductTypesApi(filters);
    return response.response;
  }
);

export const addProductType = createAsyncThunk(
  "productType/addProductType",
  async (productType, { dispatch, getState }) => {
    try {
      const response = await addProductTypeApi(productType);
      const data = await response.response;
      dispatch(getAllProductTypes());
      toast.success("Add product type successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateProductType = createAsyncThunk(
  "productType/updateProductType",
  async (productType, { dispatch, getStatus }) => {
    try {
      const response = await updateProductTypeApi(productType, productType.id);
      const data = await response.response;
      dispatch(getAllProductTypes());
      toast.success("Update product type successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteProductType = createAsyncThunk(
  "productType/deleteProductType",
  async (id, { dispatch, getStatus }) => {
    try {
      const response = await deleteProductTypeApi(id);
      const data = await response.response;
      dispatch(getAllProductTypes());
      toast.success("Deleted product type successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

const productTypeAdapter = createEntityAdapter({});

export const {
  selectAll: selectProductType,
  selectById: selectProductTypeById,
} = productTypeAdapter.getSelectors((state) => state.product.productType);

console.log(productTypeAdapter);

const productTypeSlice = createSlice({
  name: "productType",
  initialState: productTypeAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "ProductType",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setProductTypeSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "ProductType",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    openEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDialog: (state, action) => {
      state.addDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAllProductTypes.fulfilled]: productTypeAdapter.setAll,
  },
});

export const {
  setProductTypeSearchText,
  openNewDialog,
  closeNewDialog,
  openEditDialog,
  closeEditDialog,
} = productTypeSlice.actions;

export default productTypeSlice.reducer;
