import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import PreLoader from "../../../share-components/ui/preLoader";
import Breadcrumb from "../../../layout/breadcrumb";
import {useDispatch} from "react-redux";
import {updateDispatchOrderData} from "../store/orderSlice";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

const defaultValues = {
    id: '',
};

const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Item Dispatch',
        link: ''
    },
];

export const schema = yup.object().shape({});

const DispatchOrder = () => {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(false);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const submitBtnSaveRef = useRef();

    const {errors} = formState;

    const onSubmitForm = (data) => {
        const finalData ={
            id:data.id
        }
       dispatch(updateDispatchOrderData({...finalData})).then(res=>{
           if (!res.error) {
               setValue('id','');
               trigger('id');
               submitBtnSaveRef.current.focus();
           }
       });
    }

    const handleChange = (event) => {
        setValue('id',event.target.value);
        trigger('id')
    };


    return (
        <Fragment>
            <Container fluid className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Item Dispatch"/>
                    <div className="product-grid">
                        <Card>



                            <CardBody>
                                <Form onSubmit={handleSubmit(onSubmitForm)}>
                                    <Row>
                                        <Col md={3} sm={4} xs={12}>
                                            {/*<Label className="form-label d-none d-md-block">Order Number / Invoice ID</Label>*/}
                                        </Col>
                                        <Col md={6} sm={4} xs={12} className="mb-3 mb-sm-0">
                                            <FormGroup className="m-checkbox-inline mb-0">
                                                <input
                                                    ref={(ref)=>{
                                                        submitBtnSaveRef.current=ref;
                                                    }}
                                                    name="id"
                                                    type="text"
                                                    value={getValues('id')}
                                                    autoFocus
                                                    className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                                                    placeholder="Order Name / Invoice ID"
                                                    onChange={handleChange}
                                                />
                                                <div className="invalid-feedback">{errors.id?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} sm={4} xs={12} className="mb-3 mb-sm-0">
                                            <button
                                                style={{}}

                                                onClick={() => {
                                                    // setActionBtn(1)
                                                }}
                                                className="btn btn-success btn-block"
                                            >
                                                Dispatch
                                            </button>
                                        </Col>
                                        <Col md={1} xs={12}></Col>
                                    </Row>
                                </Form>
                            </CardBody>



                        </Card>
                    </div>
                </>}
            </Container>
        </Fragment>
    );
}

export default (DispatchOrder)