import React, {Fragment, useEffect, useState} from "react";


import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {numberWithCommasWithoutPounds} from "../../../../../utils/common";


const SalesOrderFulfillmentView = (props) => {
    const {fulFillmentData} = props;
    const dispatch = useDispatch();


    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <span className="f-w-600">Fulfillment# : </span>
                                        <span className="f-w-100">{fulFillmentData('fulfillment_no')}</span>
                                    </Col>
                                    <Col md={6}>
                                        <span className="f-w-600">Date : </span>
                                        <span className="f-w-100">{fulFillmentData('date')}</span>
                                    </Col>
                                </Row>
                                {/*<Row className="mt-3">*/}
                                {/*    <Col md={4}>*/}
                                {/*        <span className="f-w-600">Total Boxes</span>*/}
                                {/*        <span  className="f-w-100 pull-right">11</span>*/}
                                {/*    </Col>*/}
                                {/*    <Col md={4}>*/}
                                {/*        <span className="f-w-600">Total Gross Weight</span>*/}
                                {/*        <span className="f-w-100 pull-right">11</span>*/}
                                {/*    </Col>*/}
                                {/*    <Col md={4}>*/}
                                {/*        <span className="f-w-600">Total Net Weight</span>*/}
                                {/*        <span className="f-w-100 pull-right">11</span>*/}
                                {/*    </Col>*/}

                                {/*</Row>*/}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="table-responsive w-100">
                                            <Table>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th style={{width: '40%'}}>Product</th>
                                                    <th style={{textAlign: "right", width: '20%'}}>Order Qty</th>
                                                    <th style={{textAlign: "right", width: '20%'}}>Fulfilment Qty</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(fulFillmentData('ordered_products')).filter(function (value, index) {
                                                    return value.is_selected;
                                                }).map((item, i) =>
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <img className="img-fluid" src={item.product.image}
                                                                         width="40" alt=""/>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="row">
                                                                        <p className={'font-primary'}>{item.product.name}</p>
                                                                    </div>
                                                                    <div className="row">
                                                                        <p>{item.product.option}</p>
                                                                    </div>
                                                                    <div className="row">
                                                                        <p>{item.product.sku}</p>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td style={{textAlign: "right"}}>
                                                            <div style={{marginTop: "1rem"}}>{item.order_quantity}</div>
                                                        </td>
                                                        <td style={{textAlign: "right"}}>
                                                            <div
                                                                style={{marginTop: "1rem"}}>{item.fulfillment_quantity}</div>
                                                        </td>

                                                    </tr>
                                                )}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                <Col md="12">
                                    <h6>Pickup Details</h6>
                                </Col>
                </Row>
                                <Row className="mt-1">
                                    <Col md={12}>
                                        <span className="f-w-600">Total No Of Boxes</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('total_no_of_boxes')}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={12}>
                                        <span className="f-w-600">Total Net Weight</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('total_net_weight')}</span>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col md={12}>
                                        <span className="f-w-600">Total Gross Weight</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('total_gross_weight')}</span>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                  <Col md="12">
                                      <h6>Shipping Details</h6>
                                  </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col md={12}>
                                        <span className="f-w-600">Delivery Partner</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('delivery_partner')?.name}</span>
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col md={12}>
                                        <span className="f-w-600">Tracking Url</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('tracking_url')}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={12}>
                                        <span className="f-w-600">Tracking Id</span>
                                        <span
                                            className="f-w-100 pull-right">{fulFillmentData('tracking_id')}</span>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
        ;
}

export default SalesOrderFulfillmentView;