import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../layout/breadcrumb";
import PreLoader from "../../../../share-components/ui/preLoader";
import withReducer from "../../../../store/withReducer";
import salesOrderStatusReducer from "../../store";
import { getSalesOrderStatus } from "../../store/salesOrderStatusSlice";
import { Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import StatusContainer from "./content/column";
import LogActivity from "../../../log";
import SalesOrderModal from "./content/salesOrderModal";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SalesInvoiceModal from "./content/salesInvoiceModal";
import SalesFulfillmentModal from "./content/salesFulfillmentModal";
import SalesOrderBillingModal from "./content/salesOrderBillingModal";

const dataMenu = [
  {
    type: 1,
    text: "Sales Order",
    link: "/order/sale",
  },
  {
    type: 0,
    text: "Order Status",
  },
];

const defaultValues = {
  id: "",
};

const SalesOrderStatus = () => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const [loading, isSetLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const statuses = useSelector((state) => state.order.saleOrderStatus);
  const { setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    getData();
  }, [search]);

  const getData = () => {
    // isSetLoading(true);
    setTaskLoading(true);
    const data = {
      search: search,
    };
    dispatch(getSalesOrderStatus(data)).then((res) => {
      if (!res.error) {
        isSetLoading(false);
        setTaskLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Order Status" />

            <Row>
              <Col sm="12" className="container">
                <div className="feature-products">
                  <Row>
                    <Col md={3}>
                      <Form>
                        <FormGroup className="m-0">
                          <Input
                            type="text"
                            className="form-control"
                            // onChange={(e) => {
                            //   setSearch(e.target.value);
                            // }}
                            onChange={(e) => {
                              clearTimeout(timer);

                              const newTimer = setTimeout(() => {
                                setSearch(e.target.value);
                              }, 1000);

                              setTimer(newTimer);
                            }}
                            placeholder="Search by Order No."
                          />
                          <i className="fa fa-search"></i>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    zIndex: 10,
                    right: 0,
                  }}
                >
                  <LogActivity log="Sales Order Status" isSearch></LogActivity>
                </div>
                {taskLoading ? (
                  <>
                    <PreLoader></PreLoader>
                  </>
                ) : (
                  <>
                    {statuses && statuses.ids && (
                      <StatusContainer statuses={statuses}></StatusContainer>
                    )}
                  </>
                )}
              </Col>
            </Row>

            <SalesOrderModal
              orderSetValue={setValue}
              orderTrigger={trigger}
              orderGetValues={getValues}
              getData={getData}
            ></SalesOrderModal>

            <SalesInvoiceModal
              orderSetValue={setValue}
              orderTrigger={trigger}
              orderGetValues={getValues}
            ></SalesInvoiceModal>

            <SalesFulfillmentModal
              orderSetValue={setValue}
              orderTrigger={trigger}
              orderGetValues={getValues}
            ></SalesFulfillmentModal>

            <SalesOrderBillingModal
              orderSetValue={setValue}
              orderTrigger={trigger}
              orderGetValues={getValues}
            ></SalesOrderBillingModal>
          </>
        )}
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    SalesOrderStatus: salesOrderStatusReducer,
  },
])(SalesOrderStatus);
