import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Breadcrumb from "../../../layout/breadcrumb";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import PreLoader from "../../../share-components/ui/preLoader";
import { Clock } from "react-feather";
import LogActivity from "../../log";
import {
  Modal,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Label,
} from "reactstrap";
import salesOrderInvoiceReducer from "../store";
import {
  getAllSaleOrderInvoices,
  selectAllSaleInvoice,
  selectDraftOrder,
  selectInvoices,
} from "../store/saleOrderInvoiceSlice";
import {
  invoiceResponseToTable,
  mapSearchCustomerAutoSuggestion,
} from "../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../services/order";
import withReducer from "../../../store/withReducer";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { setSettings, getSalesOrderFilters } from "../store/salesOrderSlice";

const dataMenu = [
  {
    type: 0,
    text: "Order",
  },
  {
    type: 1,
    text: "Invoices",
    link: "",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const { afterToday } = DateRangePicker;

const SalesOrderInvoice = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [invoiceXeroNumber, setInvoiceXeroNumber] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState([]);
  const [orderNumber, setOrderNumber] = useState([]);
  const [xeroNumber, setXeroNumber] = useState([]);
  const [status, setStatus] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [modal, setModal] = useState(false);
  let history = useHistory();
  const [loading, isSetLoading] = useState(true);
  const saleOrderInvoiceState = useSelector(
    ({ order }) => order.salesOrderInvoice
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const invoices = useSelector(selectAllSaleInvoice);
  const [tableLoading, isSetTableLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const toggle = () => setModal(!modal);
  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const futureSevenDays = new Date().setDate(TodayDate.getDate() + 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;

  const [createdDate, setCreatedDate] = useState([sevenDays, TodayDate]);
  // const [dueDate, setDueDate] = useState([sevenDays, futureSevenDays]);
  const [dueDate, setDueDate] = useState(["", ""]);
  const [createdStartDate, createdEndDate] = createdDate;
  const [dueStartDate, dueEndDate] = dueDate;

  const tableColumns = [
    {
      name: "Invoice ID",
      selector: (row) => row.invoice_no,
      sortable: true,
      center: false,
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
      center: false,
    },
    {
      name: "Xero ID",
      selector: (row) => row.xero_id,
      sortable: true,
      center: false,
    },
    {
      name: "Due Date",
      selector: (row) => row.due_date,
      sortable: true,
      center: false,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      center: false,
    },
    {
      name: "Net Total",
      selector: (row) => (
        <>
          {row.total_amount}
        </>
      ),
      sortable: true,
      center: false,
    },
    {
      name: "Country",
      selector: (row) => row.buyer_lead_country,
      sortable: true,
      center: false,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   center: false,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {userState.user &&
            (userState.user.permission.indexOf("View Seller") !== -1 ? (
                        <Link to={`${process.env.PUBLIC_URL}/order/sale/${row.sales_order_id }/billing/invoice/${row.id}`} className="mr-1">
                          <i
                              className="fa fa-eye"
                              style={{
                                fontSize: 16,
                                color: "rgb(51, 153, 255)",
                              }}
                          ></i>
                        </Link>

            ) : (
              ""
            ))}
          {row.xero_id ? (
            <>
              {userState.user &&
                (userState.user.permission.indexOf("Update Seller") !== -1 ? (
                            <Link to={`${process.env.PUBLIC_URL}/order/sale/${row.sales_order_id}/return/${row.id}`} className="ml-1">
                              <i style={{
                                fontSize: 16,
                                color: "rgb(255, 187, 51)",
                              }} className="fa fa-undo"></i>
                            </Link>
                ) : (
                  ""
                ))}
            </>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
  }, [
    page,
    brand,
    search,
    invoiceNumber,
    orderNumber,
    xeroNumber,
    invoiceXeroNumber,
    dateRange,
    createdStartDate,
    createdEndDate,
    dueStartDate,
    dueEndDate,
    status,
    customer,
  ]);

  const getData = () => {
    const data = {
      page: page,
      pageSize: 10,
      brand: brand,
      search: search,
      invoice_number: invoiceNumber,
      order_number: orderNumber,
      xero_number: xeroNumber,
      status: status,
      customer: customer,
      invoice_xero_number: invoiceXeroNumber,
      dateRange: dateRange,
      due_start_date: dueStartDate
        ? moment(dueStartDate).format("YYYY-MM-DD")
        : "",
      due_end_date: dueEndDate ? moment(dueEndDate).format("YYYY-MM-DD") : "",
      created_start_date: moment(createdStartDate).format("YYYY-MM-DD"),
      created_end_date: moment(createdEndDate).format("YYYY-MM-DD"),
      // due_start_date: moment(dueStartDate).format("YYYY-MM-DD"),
      // due_end_date: moment(dueEndDate).format("YYYY-MM-DD"),
    };
    dispatch(getAllSaleOrderInvoices(data)).then((res) => {
      if (!res.error) isSetLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getSalesOrderFilters()).then((res) => {
      if (!res.error) {
        dispatch(setSettings(res.payload));
      }
    });
  }, []);

  const onEdit = (row) => {
    history.push("sale/" + row.sales_order_id + "/return/" + row.id);
  };
  const onView = (row) => {
    history.push("sale/" + row.sales_order_id + "/billing/invoice/" + row.id);
  };

  const logHistory = () => {
    document.querySelector(".customizer-contain").classList.add("open");
  };

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(customerAutoSuggestionApi(data))
        );
      });
    });

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption ? selectedOption.value : null);
  };

  return (
    <Fragment>
      <Container fluid={true} className="product-wrapper">
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Invoices" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <Label>Created Date</Label>
                          <DatePicker
                            selectsRange
                            dateRange={dateRange}
                            dateRangeChange={(update) => {
                              setCreatedDate(update);
                            }}
                            disabledDate={afterToday()}
                            defaultValue={[TodayDate, sevenDays]}
                            placeholder="Select Created Date"
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="row">
                        <Col sm="12">
                          <Label>Due Date</Label>
                          <DatePicker
                            selectsRange
                            dateRange={dueDate}
                            dateRangeChange={(update) => {
                              setDueDate(update);
                            }}
                            // disabledDate={afterToday()}
                            defaultValue={[futureSevenDays, sevenDays]}
                            placeholder="Select Created Date"
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3} className="mt-2">
                    <Label></Label>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Status"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setStatus(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.invoice_status
                          : null
                      }
                    />
                  </Col>

                  {/* <Col md={3} className="mt-2">
                    <Label></Label>
                    <Select
                      className="select2-filter border"
                      isClearable
                      cacheOptions
                      styles={customStyles}
                      placeholder="Select Customer"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => {
                        setCustomer(e ? e.id : null);
                        setPage(1);
                      }}
                      options={
                        salesOrderState.settings
                          ? salesOrderState.settings.customers
                          : null
                      }
                    />
                  </Col> */}

                  <Col md={3} className="mt-2">
                    <Label></Label>
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      styles={customStyles}
                      loadOptions={typePromiseOption}
                      onChange={handleCustomerChange}
                      placeholder="Select Customer"
                    />
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setInvoiceXeroNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Invoice No. or Xero ID"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Order No."
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                  {/* <Col md={3}>
                    <Form>
                      <FormGroup className="m-0">
                        <input
                          onChange={(e) => {
                            setXeroNumber(e.target.value);
                          }}
                          type="text"
                          className="form-control border"
                          placeholder="Search By Xero ID"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col> */}
                </Row>
              </div>
            </div>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table mt-4">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={invoiceResponseToTable(invoices)}
                        pagination
                        progressPending={tableLoading}
                        paginationServer
                        paginationTotalRows={
                          saleOrderInvoiceState.noOfTableRows
                        }
                        paginationPerPage={10}
                        noAction
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                        onChangePage={(page) => setPage(page)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                <div className="customizer-contain">
                  <div className="customizer-header">
                    <i
                      className="icon-close"
                      onClick={() => {
                        document
                          .querySelector(".customizer-contain")
                          .classList.remove("open");
                      }}
                    ></i>
                    <h5 className="filter-header">Log History</h5>
                  </div>
                </div>
              </Row>
            </Row>
          </>
        )}
      </Container>
    </Fragment>
  );
};
export default withReducer([
  {
    salesOrderInvoice: salesOrderInvoiceReducer,
  },
])(SalesOrderInvoice);
