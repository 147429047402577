import React, { Fragment, useEffect, useRef, useState } from "react";
import { ButtonGroup, Col, Form, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { defaultValues } from "../settings";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addSellerLead } from "../store/sellerLeadSlice";
import { mapSellerLeadResponseToAdditionalDetailsForm } from "../../../utils/mapper";
import { useHistory } from "react-router-dom";
import { addBuyerLead, setSaveState } from "../store/buyerLeadSlice";
import { toast } from "react-toastify";

export const schema = yup.object().shape({
  // other_brands: yup.string().required("Other Brands required").typeError('Other Brands is required'),
});

const otherMarketPlaceOptions = [
  { id: "1", name: "Yes" },
  { id: "2", name: "No" },
];

export const AdditionalDetails = (props) => {
  const { sellerLeadState, id, VerticleTab, setVerticleTab, formData, buyer } =
    props;
  const dispatch = useDispatch();
  const [actionBtn, setActionBtn] = useState(0);

  const {
    handleSubmit,
    formState,
    reset,
    register,
    trigger,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const submitBtnRef = useRef();
  const targetAudience = [];
  const businessModel = [];
  const pattern = [];
  let history = useHistory();

  const onSubmitForm = (data) => {
    data.sell_duration = data.sell_duration ? data.sell_duration.id : "";
    data.new_style_duration = data.new_style_duration
      ? data.new_style_duration.id
      : "";
    data.other_marketplace = data.other_marketplace
      ? data.other_marketplace.id
      : "";
    const finalData = {
      id: id,
      formData: data,
      type: 5,
    };
    if (buyer) {
      dispatch(addBuyerLead({ ...finalData })).then((res) => {
        if (!res.errors)
          if (!res.payload.error) {
            if (actionBtn == 0) {
              toast.success("Customize Details Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setVerticleTab((parseInt(VerticleTab) + 1).toString());
              dispatch(setSaveState(true));
            } else {
              history.push({
                pathname: "/leads/buyer-lead",
                state: { from: "submit" },
              });
            }
          } else {
            toast.error(res.payload.data, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
      });
    } else {
      dispatch(addSellerLead({ ...finalData })).then((res) => {
        if (!res.errors)
          if (!res.payload.error) {
            if (actionBtn == 0) {
              toast.success("Business Details Updated successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setVerticleTab((parseInt(VerticleTab) + 1).toString());
            } else {
              history.push("/leads/seller-lead");
            }
          } else {
            toast.error(res.payload.data, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
      });
    }
  };

  const setTargetAudience = (e, id) => {
    if (e.target.checked) {
      targetAudience.push(id);
    } else {
      const index = targetAudience.indexOf(id);
      if (index > -1) {
        targetAudience.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setValue("targetAudience", targetAudience);
  };

  const setBusinessModel = (e, id) => {
    if (e.target.checked) {
      businessModel.push(id);
    } else {
      const index = pattern.indexOf(id);
      if (index > -1) {
        businessModel.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setValue("business_model", businessModel);
  };

  const setPattern = (e, id) => {
    if (e.target.checked) {
      pattern.push(id);
    } else {
      const index = pattern.indexOf(id);
      if (index > -1) {
        pattern.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    setValue("manufacturingPatterns", pattern);
  };

  useEffect(() => {
    if (id) {
      if (formData.length != 0) {
        reset(mapSellerLeadResponseToAdditionalDetailsForm(formData));
      }
    }
  }, [formData]);

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmitForm)}>
        <Row>
          {buyer ? (
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">Other Brands</Label>
                <input
                  name="other_brands"
                  type="text"
                  className={`form-control ${
                    errors.other_brands ? "is-invalid" : ""
                  }`}
                  placeholder="John Doe"
                  {...register("other_brands")}
                />
                <div className="invalid-feedback">
                  {errors.other_brands?.message}
                </div>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {!buyer ? (
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">
                  Please list all your brands
                </Label>
                <textarea
                  name="seller_brands"
                  className={`form-control ${
                    errors.seller_brands ? "is-invalid" : ""
                  }`}
                  rows="5"
                  cols="5"
                  placeholder="Default textarea"
                  {...register("seller_brands")}
                ></textarea>
                <div className="invalid-feedback">
                  {errors.seller_brands?.message}
                </div>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {!buyer ? (
            <Col md={6}>
              <FormGroup>
                <Label className="form-label">
                  What brands do you plan to sell with us?
                </Label>
                <textarea
                  name="sell_brands"
                  className={`form-control ${
                    errors.sell_brands ? "is-invalid" : ""
                  }`}
                  rows="5"
                  cols="5"
                  {...register("sell_brands")}
                  placeholder="Default textarea"
                ></textarea>
                <div className="invalid-feedback">
                  {errors.sell_brands?.message}
                </div>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">
                {buyer
                  ? "How often do you purchase?"
                  : "How often do you bring new styles"}
              </Label>
              <Select
                placeholder="Once a Week"
                getOptionLabel={(option) => option.time_period}
                value={getValues("new_style_duration")}
                getOptionValue={(option) => option.id}
                {...register("new_style_duration")}
                onChange={(e) => {
                  setValue("new_style_duration", e);
                  trigger("new_style_duration");
                }}
                className={` ${errors.new_style_duration ? "is-invalid" : ""}`}
                options={sellerLeadState.settings.styleTimeGaps}
              />
              <div className="invalid-feedback">
                {errors.new_style_duration?.message}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">
                {buyer
                  ? "How Soon do you plan to order?"
                  : "How Soon do you plan to sell with us?"}
              </Label>
              <Select
                placeholder="ASAP"
                getOptionLabel={(option) => option.name}
                value={getValues("sell_duration")}
                getOptionValue={(option) => option.id}
                {...register("sell_duration")}
                onChange={(e) => {
                  setValue("sell_duration", e);
                  trigger("sell_duration");
                }}
                className={` ${errors.sell_duration ? "is-invalid" : ""}`}
                options={sellerLeadState.settings.sellingPlan}
              />
              <div className="invalid-feedback">
                {errors.sell_duration?.message}
              </div>
            </FormGroup>
          </Col>
          
          <Col md={6}>
            <Label className="form-label">Your Target Audience</Label>
            <FormGroup className="m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
              {sellerLeadState.settings.targetAudience?.map((item, index) => (
                <div key={index} className="checkbox checkbox-dark">
                  <input
                    defaultChecked={getValues("targetAudience")?.includes(
                      item.id
                    )}
                    onChange={(e) => {
                      setTargetAudience(e, item.id);
                    }}
                    id={item.value}
                    type="checkbox"
                  />
                  <Label for={item.value}>
                    <span className="digits"> {item.range}</span>
                  </Label>
                </div>
              ))}
            </FormGroup>
          </Col>
          
          <Col md={6}>
            <Label className="form-label">Your Business Model</Label>
            <FormGroup className="m-checkbox-inline mb-0 custom-radio-ml">
              {sellerLeadState.settings.businessModels?.map((item, index) => (
                <div key={index} className="checkbox checkbox-dark">
                  <input
                    defaultChecked={getValues("businessModels")?.includes(
                      item.id
                    )}
                    onChange={(e) => {
                      setBusinessModel(e, item.id);
                    }}
                    id={item.value}
                    type="checkbox"
                  />
                  <Label for={item.value}>
                    <span className="digits"> {item.name}</span>
                  </Label>
                </div>
              ))}
            </FormGroup>
          </Col>
          
          <Col md={6}>
            <Label className="form-label">
              {buyer ? "Buying Pattern" : "Manufacturing Pattern"}
            </Label>
            <FormGroup className="m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
              {sellerLeadState.settings.manufacturingPatterns?.map(
                (item, index) => (
                  <div key={index} className="checkbox checkbox-dark">
                    <input
                      defaultChecked={getValues(
                        "manufacturingPatterns"
                      )?.includes(item.id)}
                      onChange={(e) => {
                        setPattern(e, item.id);
                      }}
                      id={item.value}
                      type="checkbox"
                    />
                    <Label for={item.value}>
                      <span className="digits">{item.name}</span>
                    </Label>
                  </div>
                )
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="form-label">
                Do you sell on any marketplace
              </Label>
              <Select
                placeholder="select..."
                getOptionLabel={(option) => option.name}
                value={getValues("other_marketplace")}
                getOptionValue={(option) => option.id}
                {...register("other_marketplace")}
                onChange={(e) => {
                  setValue("other_marketplace", e);
                  trigger("other_marketplace");
                }}
                className={` ${errors.other_marketplace ? "is-invalid" : ""}`}
                options={otherMarketPlaceOptions}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="mt-2 d-flex justify-content-end">
          <div className="p-2">
            <button
              ref={submitBtnRef}
              onClick={() => {
                setActionBtn(1);
              }}
              className="btn btn-danger btn-block"
            >
              Save & Exit
            </button>
          </div>
          <div className="p-2">
            <button
              ref={submitBtnRef}
              onClick={() => {
                setActionBtn(0);
              }}
              className="btn btn-primary btn-block"
            >
              Save & Next
            </button>
          </div>
        </div>
      </Form>
    </Fragment>
  );
};
