export const returnPaymentCalculation = (getValues, selectedRowsData) => {
  let subTotalPrice = 0;
  let discountAmount = 0;
  let discountAmountTax = 0;
  let shippingAmount = 0;
  let taxAmount = 0;
  let dutyAmount = 0;
  let shippingAmountTax = 0;
  let totalCreditAmount = 0;
  const discount = getValues("discount");
  // const orders = selectedRowsData;
  const orders = getValues("ordered_products");
  const shipping = getValues("shipping");
  const duty = getValues("duty");
  let is_vat = 0;
  let is_declined = 0;
  let is_selected = 0;
  let vatTotalAmount = 0;

  if (orders.length > 0) {
    // selectedRowsData.forEach((item) => {
    orders.forEach((item) => {

      const returnQty = item.return_quantity || 1;
      is_vat = item.is_vat || 0;
      is_declined = item.is_declined || 0;
      is_selected = item.is_selected || 0;

      if (is_selected && (!is_declined)) {
        totalCreditAmount += returnQty * item.total_price;
        subTotalPrice += item.total_price ? item.total_price : 0;

        taxAmount += item.tax_amount ? item.tax_amount : 0;
        vatTotalAmount+=item.tax_amount? item.total_price:0;
      }
    });
  }
  if (discount) {
    if (discount.discount_type == 1) {
      discountAmount = Number(discount.value);
      const discountPercentage=discountAmount/(subTotalPrice);
      discountAmountTax=vatTotalAmount * discountPercentage
    } else {
      discountAmount = subTotalPrice * (Number(discount.value) / 100);
      discountAmountTax=vatTotalAmount * (Number(discount.value)/100);
    }
    discountAmountTax = discountAmountTax * 0.2;
    taxAmount=taxAmount-discountAmountTax;

  }

  if (duty) {
    if (duty.type == 1) {
      dutyAmount = Number(duty.value);
    } else {
      dutyAmount = subTotalPrice * (Number(duty.value) / 100);
    }
  }

  if (shipping) {
    if (shipping.shipping_type == 2) {
      shippingAmount = Number(shipping.price);
    }
  }

  if (shipping) {
    if (shipping.shipping_type == 2) {
      shippingAmount = Number(shipping.price);
    }

    if (is_vat == 1) {
      shippingAmountTax = shippingAmount * 0.2;
      taxAmount = taxAmount + shippingAmountTax;
    }
  }

  let total =
    subTotalPrice + shippingAmount + dutyAmount + taxAmount - discountAmount;
  let subTotalWithDiscount = subTotalPrice - discountAmount;
  return {
    subTotal: subTotalPrice.toFixed(2),
    total: total.toFixed(2),
    totalCreditAmount: totalCreditAmount.toFixed(2),
    discountPrice: discountAmount.toFixed(2),
    shippingPrice: shippingAmount.toFixed(2),
    taxPrice: taxAmount.toFixed(2),
    dutyPrice: dutyAmount.toFixed(2),
    subTotalWithDiscount: subTotalWithDiscount.toFixed(2),
  };
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const orderCommodities = function (orders) {
  const data = [];
  const commodities = groupBy(orders, "commodity");
  let totalQuantity = 0;
  let totalPrice = 0;
  for (const [key, value] of Object.entries(commodities)) {
    if (key) {
      let price = value
        .map((o) => o.total_price)
        .reduce((a, c) => {
          return a + c;
        });

      let quantity = value
        .map((o) => o.quantity)
        .reduce((a, c) => {
          return a + c;
        });
      totalPrice += price;
      totalQuantity += quantity;

      data.push({
        commodity: key,
        amount: price.toFixed(2),
        quantity: quantity,
      });
    }
  }
  data.push({
    commodity: "Total",
    amount: totalPrice.toFixed(2),
    quantity: totalQuantity,
  });
  return data;
};

export const productTaxAmount = function (getValues) {
  let totalTaxAmount = 0;
  getValues("ordered_products").map(function (item, index) {
    totalTaxAmount += item.tax_amount ? item.tax_amount : 0;
  });
  return totalTaxAmount;
};

export const shippingTaxAmount = function (getValues) {
  const shipping = getValues("shipping");
  let shippingtaxAmount = 0;
  if (shipping) {
    shippingtaxAmount = Number(shipping.price) * 0.2;
  }
  return shippingtaxAmount;
};
