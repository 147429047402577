import API from "../../../utils/api";


export const getBinsApi = async (filters) => {
    const response = await API.get('settings/product/bin-location/list', { params: filters });
    return { response };
};

export const addBinApi = async location => {
    const response = await API.post('settings/product/bin-location', location);
    return { response };
}

export const updateBinApi = async (location, id) => {
    const response = await API.put(`settings/product/bin-location/update/${id}`, location);
    return { response };
};

export const deleteBinApi = async (id) => {
    const response = await API.delete(`settings/product/bin-location/delete/${id}`);
    return { response };
};

export const typeAutoSuggestionApi = async (text) => {
    const response = await API.get('settings/product/bin-location/type-auto-search', text);
    return { response };
};
