import React, {Fragment} from "react";
import {Row, Col, FormGroup, Label} from "reactstrap";
import AsyncSelect from "react-select/async";
import {FormCheck} from "react-bootstrap";

const BinForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions,
        typePromiseOption
    } = props;

    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Box Type</Label>
                        <AsyncSelect
                            isClearable
                            cacheOptions
                            defaultOptions
                            defaultValue={getValues('box_type_id')}
                            loadOptions={typePromiseOption}
                            error={errors.type}
                            onChange={(e) => {
                                setValue('box_type_id', e)
                                trigger('box_type_id');
                            }}
                            className={` ${errors.box_type_id ? 'is-invalid' : ''}`}

                        />
                        <div className="invalid-feedback">{errors.box_type_id?.message}</div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                   <FormGroup>
                       <Label className="col-form-label">Bin Location Name</Label>
                       <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                       <div className="invalid-feedback">{errors.name?.message}</div>
                   </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                   <FormGroup>
                       <Label className="col-form-label">Capacity</Label>
                       <input name="capacity" type="text" {...register('capacity')} className={`form-control ${errors.capacity ? 'is-invalid' : ''}`}/>
                       <div className="invalid-feedback">{errors.capacity?.message}</div>
                   </FormGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default BinForm;