import React, {Fragment, useEffect, useRef, useState} from 'react';
import Breadcrumb from "../../../layout/breadcrumb";
import {
    ButtonDropdown,Card,
    CardBody, CardHeader,Col,
    Container,DropdownItem,DropdownMenu,DropdownToggle, Form,
    FormGroup,Input,Label,Nav,NavItem,NavLink,
    Row,Popover,PopoverBody,PopoverHeader, Button
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import Select from "react-select";
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { Link } from 'react-router-dom';
import * as ReactDOMServer from "react-dom/server";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    selectinventoryAdjustment,
    getInventoryAdjust,
    exportInventoryAdjustmentDetails,
    exportGainLoss,
    exportInventoryGainLoss, exportCondition
} from "../store/inventoryAdjustmentsSlice";
import moment from "moment";
import adjustmentInventoryReducer from "../store";
import {  getInventorySettings, setSettings, inventorySku, inventoryBin, } from "../store/prodcutSlice";
import JqxTable from '../../../share-components/table/JqxTable';
import { mapInventoryAdjustmentResponseToTable } from '../../../utils/mapper';
import JqxGrid, {IGridProps, jqx} from '../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid';
import * as ReactDOM from "react-dom";

const { afterToday } = DateRangePicker;

const dataMenu = [
    {
        type: 0,
        text: 'Inventory Adjustment',
    }
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

function AdjustmentInventory() {

    const inventory = useSelector(selectinventoryAdjustment);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [btnLoader,setBtnLoader]=useState(false);
    const inventoryState = useSelector(({product}) => product.inventoryAdjustment);

    const productState = useSelector(({product}) => product.product);
    const dispatch = useDispatch();
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);

    const [conditionBtnLoader,setConditionBtnLoader]=useState(false);
    const [conditionDropdownOpen, setConditionDropdownOpen] = useState(false);
    const conditionToggle = () => setConditionDropdownOpen(prevState => !prevState);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const authData =  useSelector(({authReducer}) => authReducer);
    const [searchSeller, setSearchSeller] = useState('');
    const [isLoadingSku, setIsLoadingSku] = useState(false);
    const [selectedSkuData, setSelectedSkuData] = useState(null);
    const [skus, setSkus] = useState([]);
    const handleSearchForSku = (query) => {
        setIsLoadingSku(true);
        dispatch(inventorySku(query)).then((res) => {
          if (!res.error) {
            setSkus(res.payload);
            setIsLoadingSku(false);
          }
        });
      };
    let gridRef = useRef(null);
    let subGrid = useRef([]);
    let currentIndex="";
    let currentEditedCell="";
    const [subGridHeight, setSubGridHeight] = useState(200);

   useEffect(()=>{
          setSearchSeller(authData.defaultSeller)
    },[authData.defaultSeller]);

    console.log(searchSeller);
    const handleOnSkuChange = (skuData) => {
        setSelectedSkuData(skuData[0]);
    }
    const filterBy = () => true;

    const [selectedBinId, setSelectedBinId] = useState(null);
    const [isLoadingBin, setIsLoadingBin] = useState(false);
    const [bins, setBins] = useState([]);
    const handleSearchForBin = (query) => {
        setIsLoadingBin(true);
        const data = {
            value: query
        }
        dispatch(inventoryBin(data)).then((res) => {
          if (!res.error) {
            setBins(res.payload);
            setIsLoadingBin(false);
          }
        });
      };

    const handleOnBinChange = (binData) => {
        const selectedId = binData.length > 0 ? binData[0].name : null;
        setSelectedBinId(selectedId);
    }

    const [stock, setStock] = useState(null);
    const [reason, setReason] = useState(null);
    const [skuFilter, setSkuFilter] = useState(null);
    const [condition, setCondition] = useState(null);
    const [user, setUser] = useState(null);
    const [binFilter, setBinFilter] = useState(null);

    useEffect(()=> {
        getData();
    },[dispatch, currentPage, pageSize, startDate, reason, endDate, stock, skuFilter, condition, 
        user, binFilter, selectedSkuData, selectedBinId, searchSeller]);


        const findBinDifferences = (oldData, newData) => {
            const differences = [];
        
            // Find modified bins and calculate changes
            oldData.forEach(oldItem => {
                const newItem = newData.find(newItem => newItem.bin_number === oldItem.bin_number);
        
                if (newItem) {
                    const change = newItem.available - oldItem.available;
                    if (change !== 0) {
                        differences.push({
                            bin_number: oldItem.bin_number,
                            old_available: oldItem.available,
                            new_available: newItem.available,
                            change: change
                        });
                    }else{
                        differences.push({
                            bin_number: oldItem.bin_number,
                            old_available: oldItem.available,
                            new_available: newItem.available,
                            change: 'No Change'
                        });
                    }
                } else {
                    differences.push({
                        bin_number: oldItem.bin_number,
                        old_available: oldItem.available,
                        new_available: 0,
                        change: 'Deleted'
                    });
                }
            });
        
            // Find added bins
            newData.forEach(newItem => {
                if (!oldData.find(oldItem => oldItem.bin_number === newItem.bin_number)) {
                    differences.push({
                        bin_number: newItem.bin_number,
                        old_available: 0,
                        new_available: newItem.available,
                        change: 'Added'
                    });
                }
            });
        
            return differences;
        }

    const getData = () =>{
        if (tableLoading) return;
        
        isSetTableLoading(true);

        const data = {
            page:currentPage,
            pageSize:pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock:stock,
            reason:reason,
            skuFilter:selectedSkuData ? selectedSkuData.sku : '',
            condition:condition,
            user:user,
            binFilter:selectedBinId?selectedBinId:'',
            seller:searchSeller?.value,
        }
        
        dispatch(getInventoryAdjust(data)).then(res=>{
            isSetTableLoading(false);
        });    
    }

    useEffect(() => {
        dispatch(getInventorySettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    const exportInventoryAdjustment = () => {

        setBtnLoader(true);
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            stock: stock ? stock : '',
            user: user ? user : '',
            selectedSkuData: selectedSkuData ? selectedSkuData.sku :'',
            selectedBinId : selectedBinId ? selectedBinId :'',
            reason : reason ? reason : '',
        }
        const outputFilename = `inventory_adjustment_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportInventoryAdjustmentDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnLoader(false);
        });

    }

    const exportGainLoss=()=>{
        const outputFilename = `gain_loss_${(new Date().toJSON().slice(0, 10))}.csv`;
        setBtnLoader(true);
        dispatch(exportInventoryGainLoss()).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnLoader(false);
        });
    }
    const exportConditionHandel=(id,name)=>{
        const outputFilename = `condition_${name}_${(new Date().toJSON().slice(0, 10))}.csv`;
        setConditionBtnLoader(true);
        dispatch(exportCondition({condition_id:id})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setConditionBtnLoader(false);
        });
    }

    const tableColumns = [
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: false,
            center: false,
        },
        {
            name: 'Stock Type',
            selector: row => row.stock_type,
            sortable: false,
            center: false,
        },
        {
            name: 'Condition',
            selector: row => row.condition,
            sortable: false,
            center: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason_id,
            sortable: false,
            center: false,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: false,
            center: false,
        },
        {
            name: 'Created By',
            selector: row => row.created_by,
            sortable: false,
            center: false,
        },
    ];

    const onPageChange = (pageNum) => {
        setCurrentPage(pageNum); // Adjust for zero-based index
    };

    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
    };

    // data provides access to your row data
    const ExpandedComponent = ({ data }) => {
        const childTableColumns = [
            {
                name: 'Bin Number',
                selector: row => row.bin_number,
                sortable: false,
                center: false,
            },
            {
                name: 'Old Available',
                selector: row => row.old_available,
                sortable: false,
                center: false,
            },
            {
                name: 'New Available',
                selector: row => row.new_available,
                sortable: false,
                center: false,
            },
            {
                name: 'Change',
                selector: row => {
                    let value = row.change;
                    let html = "";
                    if (value == 'Deleted') {
                        html = (
                            <div className="text-danger font-weight-bold text-center pt-2">{value}</div>
                        );
                    } else if (value == 'Added') {
                        html = (
                            <div className="text-success font-weight-bold text-center pt-2">{value}</div>
                        );
                    } else if (value == 'No Change') {
                        html = (
                            <div className="text-primary font-weight-bold text-center pt-2">{value}</div>
                        );
                    } else {
                        html = (
                            <div className="text-secondary font-weight-bold text-center pt-2">{value}</div>
                        );
                    }
                    return html;
                },
                sortable: false,
                center: false,
            }
        ];

        const childData = findBinDifferences(data.inventory_changes.old, data.inventory_changes.new);

        return <div className='px-5 pt-3 pb-5 border-bottom'>
            <CommonDataTable
                headerColumns={childTableColumns}
                data={childData}
                noAction
                pagination={false}
            />
        </div>
    };


  return (
    <Fragment>
    <Breadcrumb title="Inventory Adjustment" data={dataMenu}/>

    <Container fluid={true} className="product-wrapper">
       <div className="product-grid">
           <div className="feature-products">
            <Row>
                <Col sm="12">
                    <Card>
                        <Row>
                            <Col md={3}>
                                <div style={{marginTop: 7}} className="dashboard-datepicker ml-2">
                                    <DatePicker 
                                        dateRange={dateRange} 
                                        dateRangeChange={(date)=>{
                                            setDateRange(date)
                                        }}
                                        defaultValue={[TodayDate, sevenDays]}
                                        disabledDate={afterToday()}
                                    />
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={8}>
                                <>
                                    <div className='float-sm-right p-3'>
                                        <Link to="/product/add-adjustment-Inventory" className="btn btn-primary">
                                            <i className="fa fa-plus"></i> New Inventory Adjustment
                                        </Link>
                                    </div>
                                    <div className='float-sm-right p-3'>
                                        <ButtonDropdown color="warning" isOpen={conditionDropdownOpen}
                                                        className={`btn-warning ${conditionBtnLoader ? ' disabled' : ''}`}
                                                        toggle={conditionToggle}>
                                            <DropdownToggle caret color="success"
                                                            className="btn btn-warning btn-square">
                                                {btnLoader ? ( // Show the spinner if loading state is true
                                                    <i className="fa fa-spinner fa-spin fa-lg spinner mr-1"/>
                                                ) : (
                                                    'Export Condition' // Show the regular text if loading state is false
                                                )}
                                            </DropdownToggle>
                                            {!conditionBtnLoader && <DropdownMenu>
                                                <DropdownItem onClick={() => {
                                                    exportConditionHandel("","All")
                                                }
                                                }>All</DropdownItem>
                                                {productState.settings.productConditionType.map((item, i) => ( <DropdownItem
                                                    key={i}
                                                    onClick={() => {
                                                        exportConditionHandel(item.id,item.name)
                                                }
                                                }>{item.name}</DropdownItem>))}
                                            </DropdownMenu>
                                            }
                                        </ButtonDropdown>
                                    </div>
                                    <div className='float-sm-right p-3'>
                                        <ButtonDropdown color="success" isOpen={actionDropdownOpen}
                                                        className={`btn-success ${btnLoader ? ' disabled' : ''}`}
                                                        toggle={actionToggle}>
                                            <DropdownToggle caret color="success"
                                                            className="btn btn-success btn-square">
                                                {btnLoader ? ( // Show the spinner if loading state is true
                                                    <i className="fa fa-spinner fa-spin fa-lg spinner mr-1"/>
                                                ) : (
                                                    'Export Inventory' // Show the regular text if loading state is false
                                                )}
                                            </DropdownToggle>
                                            {!btnLoader && <DropdownMenu>
                                                <DropdownItem onClick={() => {
                                                    exportInventoryAdjustment();
                                                }
                                                }>Inventory Adjustment Report</DropdownItem>
                                                <DropdownItem onClick={() => {
                                                    exportGainLoss();
                                                }
                                                }>Gain & Loss Report</DropdownItem>
                                            </DropdownMenu>
                                            }
                                        </ButtonDropdown>
                                    </div>

                                </>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

               <Row>
                   <Col md='2' style={{zIndex: '3',}}>
                       <FormGroup>
                           <Select
                               name='stock'
                               placeholder='Select Stock Type'
                               className="select2-filter"
                               defaultValue=''
                               getOptionLabel={(option) => option.name}
                               getOptionValue={(option) => option.id}
                               isClearable
                               cacheOptions
                               styles={customStyles}
                               options={productState.settings.stockType}
                               onChange={(e) => {
                                   setStock(e ? e.id : null)
                               }}
                               components={{
                                   IndicatorSeparator: () => null
                               }}

                           />
                       </FormGroup>
                   </Col>

                   <Col md='2' style={{zIndex: '2',}}>
                       <FormGroup>
                           <Select
                               name='reason'
                               placeholder='Select Reason'
                               getOptionLabel={(option) => option.name}
                               getOptionValue={(option) => option.id}
                               className="select2-filter"
                               isClearable
                               cacheOptions
                               styles={customStyles}
                            options={productState.settings.reasonType}
                            onChange={(e) => {
                                setReason(e ? e.id : null)
                            }}
                            components={{
                                IndicatorSeparator: () => null
                            }}

                        />
                    </FormGroup>
                    </Col>
                <Col md='2' style={{ zIndex: '2',}}>
                    <FormGroup>
                        <Select
                            name='user'
                            placeholder='Select User'
                            className="select2-filter"
                            isClearable
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            cacheOptions
                            styles={customStyles}
                            // options={productState.settings.user}
                            options={productState.settings.mainUser.map(option => ({
                                ...option,
                                name: option.name.charAt(0).toUpperCase() + option.name.slice(1)
                            }))}
                            onChange={(e) => {
                                setUser(e ? e.id : null)
                            }}
                            components={{
                                IndicatorSeparator: () => null
                            }}

                        />
                    </FormGroup>
                </Col>
               
                <Col md="3">
                <AsyncTypeahead
                        name="binNumber"
                        // className={`${errors.binNumber ? 'is-invalid' : ''}`}
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoadingBin}
                        minLength={1}
                        labelKey="name"
                        onChange={(selected) => {
                            handleOnBinChange(selected);
                        }}
                        onSearch={handleSearchForBin}
                        options={bins}
                        placeholder="Search for Bin..."
                        renderMenuItemChildren={(option) => (
                        <>
                            <span>{option.name}</span>
                        </>
                        )}
                        inputProps={{
                            style: { height: '50px' }
                        }}
                    />
                </Col>

                <Col md="3">
                    <AsyncTypeahead
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoadingSku}
                        minLength={1}
                        labelKey="sku"
                        onChange={(selected) => {
                            handleOnSkuChange(selected)
                        }}
                        onSearch={handleSearchForSku}
                        options={skus}
                        placeholder="Search for SKU..."
                        renderMenuItemChildren={(option) => (
                        <>
                            <span>{option.sku}</span>
                        </>
                        )}
                        inputProps={{
                            style: { height: '50px' }
                        }}
                    />
                </Col>
                
            </Row>
 
            <Row>
                <Col sm="12" className="mt-3">
                    <Card>
                        <CardBody>
                            <CommonDataTable
                                headerColumns={tableColumns}
                                data={inventory ? mapInventoryAdjustmentResponseToTable(inventory) : null}
                                noAction
                                paginationServer
                                paginationTotalRows={inventoryState.inventoryAdjustmentTableRow}
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                onChangeRowsPerPage={onPageSizeChange}
                                onChangePage={onPageChange}                                
                                paginationPerPage={pageSize}
                                paginationDefaultPage={currentPage}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
           </div>
       </div>
   </Container>
</Fragment>
  )
}

// export default AdjustmentInventory
export default withReducer([{product: adjustmentInventoryReducer}])(AdjustmentInventory);